import { Component, Injector } from '@angular/core';
import { NotificationMessageTypeEnum } from '../../../lib/notification-message/notification-message-type.enum';
import { NotificationService } from '../../../services/notification/notification.service';

@Component({
  selector: 'app-notification-message-simulator',
  templateUrl: './notification-message-simulator.component.html',
  styleUrls: ['./notification-message-simulator.component.scss']
})
export class NotificationMessageSimulatorComponent {
  protected notificationService: NotificationService;
  selectedType: NotificationMessageTypeEnum = NotificationMessageTypeEnum.Info;
  customMessage: string = 'Simulation message';
  customTimeout: number = 10000;

  keys = Object.keys;
  type = NotificationMessageTypeEnum;

  private visible: boolean = false;

  constructor(
    protected injector: Injector,
  ){
    this.notificationService = this.injector.get(NotificationService);
  }

  showNotification() {
    this.notificationService.show(this.customMessage, this.selectedType, this.customTimeout);
  }

  hideNotification() {
    this.notificationService.hide();
  }
}
