export class ShopState {
  canPayCash = true;
  canPayCard = false;
  orderUid: string = null;
  isOrderSaving = false;
  isTicketPrinting = false;
  isReceiptPrinting = false;

  static fromOther(other: ShopState): ShopState {
    return Object.assign(new ShopState(), other);
  }

  public toString(): string {
    const canPayCash = `canPayCash: '${this.canPayCash}'.`;
    const canPayCard = `canPayCard: '${this.canPayCard}'.`;
    const orderUid = `orderUid: '${this.orderUid}'.`;
    const isOrderSaving = `isOrderSaving: '${this.isOrderSaving}'.`;
    const isTicketPrinting = `isOrderSaving: '${this.isTicketPrinting}'.`;
    const isReceiptPrinting = `isReceiptPrinting: '${this.isReceiptPrinting}'.`;
    return `${canPayCash} ${canPayCard} ${orderUid} ${isOrderSaving} ${isTicketPrinting} ${isReceiptPrinting}`;
  }
}
