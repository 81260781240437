import { Component, OnInit, OnDestroy } from '@angular/core';
import { DisplayConfiguration } from '../../../lib/display/configuration/display-configuration';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { MessageService, Message, MessageType } from '../../../services/message.service';
import { Subscription } from '../../../../../node_modules/rxjs';
import { StoreHistoryService } from '../../../services/store-history.service';
import { DispatcherService } from '../../../services/dispatcher.service';
import { ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { ThemeService } from '../../../services/theme.service';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { LanguageService } from '../../../services/language.service';
import { DisplayConfigurationPageCaptionEnum } from '../../../lib/display/configuration/display-configuration-page-caption.enum';
import { GridTreeState } from '../../../lib/display/grid/grid-tree-state';
import { DisplayItem } from '../../../lib/display/display-item/display-item';
import { DisplayGridWorkflowService } from '../../../services/display/grid/display-grid-workflow.service';
import { DisplayConfigurationExtendedDisplayEnum } from '../../../lib/display/configuration/display-configuration-extended-display.enum';

@Component({
  selector: 'app-grid-visual-items',
  templateUrl: './grid-visual-items.component.html',
  styleUrls: ['./grid-visual-items.component.css']
})
export class GridVisualItemsComponent implements OnInit, OnDestroy {

  items: DisplayItem[];
  private gridTreeState: GridTreeState;
  private activatedRouteSub: any;
  private messageSubscription: Subscription;
  private internalRebuildOfTreeRequestedSubscription: Subscription;
  private origianlStateisFooterVisible: boolean;

  constructor(
    private vuCommunicationService: VuCommunicationService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private dispatcherService: DispatcherService,
    private themeService: ThemeService,
    protected configurationService: ConfigurationService,
    private storeHistoryService: StoreHistoryService,
    private languageService: LanguageService,
    private displayGridWorkflowService: DisplayGridWorkflowService,
  ) {
    this.messageSubscription = this.messageService.getSubscription()
      .filter((x: Message) => [MessageType.ButtonBackClicked, MessageType.LanguageChanged].indexOf(x.messageType) > -1)
      .subscribe(message => this.onMessage(message));
    this.gridTreeState = new GridTreeState();
  }

  ngOnInit(): void {
    this.setPageHeaderTitle(null);
    this.origianlStateisFooterVisible = this.dispatcherService.isFooterVisible;
    this.activatedRouteSub = this.activatedRoute.params.subscribe(params => {
      const lastState = this.storeHistoryService.getLastGridTreeState();
      if (lastState === null || lastState === undefined || lastState.items == null || lastState.items.length === 0) {
        this.buildTree();
      } else {
        this.restoreState();
        if (this.gridTreeState && this.gridTreeState.displayConfiguration) {
          this.dispatcherService.isFooterVisible =
            this.gridTreeState.displayConfiguration.extendedDisplay !== DisplayConfigurationExtendedDisplayEnum.Footer;
        }
      }
    });
    this.dispatcherService.isBackButtonEnabled = true;
    this.displayGridWorkflowService.initalizeGrid(this);
    this.internalRebuildOfTreeRequestedSubscription =
      this.storeHistoryService.rebuildOfTreeRequested.subscribe(() => this.onRebuildOfTreeRequested());
  }

  onRebuildOfTreeRequested() {
    this.buildTree();
    this.displayGridWorkflowService.reset();
  }

  ngOnDestroy() {
    this.dispatcherService.isFooterVisible = this.origianlStateisFooterVisible;
    this.displayGridWorkflowService.initalizeGrid(null);
    this.displayGridWorkflowService.showDisplayItems(null, null);
    this.dispatcherService.pageHeaderSet();
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    if (this.activatedRouteSub) {
      this.activatedRouteSub.unsubscribe();
    }
    if (this.internalRebuildOfTreeRequestedSubscription) {
      this.internalRebuildOfTreeRequestedSubscription.unsubscribe();
    }
  }

  onClick(displayItem: DisplayItem) {
    this.dispatcherService.onUserActivity();

    if (displayItem.isDisabled) {
      return;
    }

    if (
      this.displayGridWorkflowService.isSupportDisplayItem(displayItem)
      &&
      !this.displayGridWorkflowService.clickDisplayItems(displayItem)) {
        this.gridTreeState.nextStep(displayItem);
        if (this.gridTreeState.isStackEmpty) {
          this.displayGridWorkflowService.reset();
        }
        this.showCurrentStep();
    }
  }

  get displayConfiguration(): DisplayConfiguration {
    return this.gridTreeState.displayConfiguration;
  }

  get pageCaption(): DisplayConfigurationPageCaptionEnum {
    if (this.displayConfiguration) {
      return this.displayConfiguration.pageCaptionType;
    }

    return DisplayConfigurationPageCaptionEnum.Automatic;
  }

  private setPageHeaderTitle(displayItem: DisplayItem) {
    const pageCaption = this.pageCaption;
    const titleMessage = [];
    if (pageCaption === DisplayConfigurationPageCaptionEnum.None) {
      this.dispatcherService.pageHeaderSet();
      return;
    }

    if (displayItem == null && titleMessage.length === 0) {
      this.dispatcherService.pageHeaderSet(this.getDefaultTitle());
      return;
    }

    if (pageCaption === DisplayConfigurationPageCaptionEnum.LastPage && displayItem) {
      this.dispatcherService.pageHeaderSet(displayItem.getFormattedName(this.language));
      return;
    }

    for (const item of this.gridTreeState.rootStack) {
      if (item == null)  {
        continue;
      }

      titleMessage.push(item.getFormattedName(this.language));
    }

    if (displayItem == null && titleMessage.length === 0) {
      this.dispatcherService.pageHeaderSet(this.getDefaultTitle());
      return;
    }

    if (displayItem != null) {
      titleMessage.push(displayItem.getFormattedName(this.language));
    }

    // if(pageCaption == DisplayConfigurationPageCaptionEnum.LastPage){
    //   this.dispatcherService.pageHeaderSet(title_message.pop());
    //   return;
    // }

    this.dispatcherService.pageHeaderLinesSet(titleMessage);
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }

  private getDefaultTitle() {
    const title = this.configurationService.defaultShopTitle;
    if (title) {
      return title;
    }

    if (this.displayConfiguration) {
      return this.displayConfiguration.translateName(this.language);
    }

    return 'Select an item';
  }

  private setBackgroundImage(displayItem: DisplayItem) {
    if (
      this.gridTreeState != null
      &&
      this.gridTreeState.isStackEmpty
      &&
      this.gridTreeState.displayConfiguration && this.gridTreeState.displayConfiguration.backgroundId) {
        this.themeService.setBackgroundImageById(this.gridTreeState.displayConfiguration.backgroundId);
        return;
    }

    if (displayItem && displayItem.backgroundId) {
      this.themeService.setBackgroundImageById(displayItem.backgroundId);
    } else {
      if (this.configurationService.configuration.backgroundId) {
        this.themeService.setBackgroundImageById(this.configurationService.configuration.backgroundId);
        return;
      }
      const categoryId = this.getCurrentCategoryId(displayItem);
      this.themeService.setBackgroundCategoryImage(categoryId);
    }
  }

  private getCurrentCategoryId(displayItem: DisplayItem): string {
    return displayItem != null ? displayItem.id.toString() : this.themeService.all_category_id;
  }

  private buildTree(): void {
    if (!this.configurationService.configuration || !this.configurationService.configuration.displayConfigurationId) {
      return;
    }

    this.vuCommunicationService.vuHttp.getDisplayConfiguration(this.configurationService.configuration.displayConfigurationId).
      then(
        displayConfiguration => {
          this.initialize(displayConfiguration);
        }
      );
  }

  private initialize(displayConfiguration: DisplayConfiguration) {
    if (!displayConfiguration) {
      return;
    }

    this.dispatcherService.isFooterVisible = displayConfiguration.extendedDisplay !== DisplayConfigurationExtendedDisplayEnum.Footer;
    displayConfiguration.loadProducts(this.vuCommunicationService).then(() => {
      this.initializeGrid(displayConfiguration);
    });
  }

  private initializeGrid(displayConfiguration) {
    this.gridTreeState.initialize(displayConfiguration);
    this.showCurrentStep();
  }

  private onMessage(message: Message) {
    if (message.messageType === MessageType.LanguageChanged) {
      this.showCurrentStep();
      return;
    }
    if (this.gridTreeState.isStackEmpty) {
      this.storeHistoryService.reset();
      this.messageService.sendMessage(new Message(MessageType.Back));
    }
    this.gridTreeState.backStep();
    this.showCurrentStep();
  }

  private restoreState() {
    this.gridTreeState = this.storeHistoryService.getLastGridTreeState();
    if (this.gridTreeState == null) {
      this.gridTreeState = new GridTreeState();
    }
    this.showCurrentStep();
  }

  private showCurrentStep() {
    this.items = this.gridTreeState.items;
    const displayItem = this.gridTreeState.parentItem;
    this.displayGridWorkflowService.showDisplayItems(displayItem, this.items);
    this.setPageHeaderTitle(displayItem);
    this.setBackgroundImage(displayItem);
    if (this.configurationService.showArticlesOnMainPage) {
      this.dispatcherService.isBackButtonVisible = displayItem != null && !displayItem.hideBackButton;
    }
    this.storeHistoryService.updateCurrentGridTreeState(this.gridTreeState);
    this.messageService.sendMessage(new Message(MessageType.DisplayModeScreenUpdate));
  }

  setStyleGrid(item: DisplayItem) {
    if (this.displayConfiguration != null && this.displayConfiguration != undefined) {
      if (item != null && item.positionInGrid(this.displayConfiguration.columnsCount, this.displayConfiguration.rowCount)) {
        const styles = {
          'grid-column': `${item.startPosX} / ${item.endPosX}`,
          'grid-row': `${item.startPosY} / ${item.endPosY}`
        };

        if (item.zIndex) {
          styles['z-index'] = item.zIndex;
        }

        if (item.isDisabled) {
          styles['opacity'] = '0.5';
        }

        return styles;
      }
      return null;
    }
  }

  wrapperStyleCss() {
    if (!this.displayConfiguration) {
      return null;
    }

    const style = {
      'grid-template-columns': `repeat(${this.displayConfiguration.columnsCount}, 1fr)`,
      'grid-template-rows': `repeat(${this.displayConfiguration.rowCount}, 1fr)`,
    };

    return style;
  }

  wrapperExtendedDisplayCss() {
    if (!this.displayConfiguration) {
      return null;
    }
    let style = '';
    switch (this.displayConfiguration.extendedDisplay) {
      case DisplayConfigurationExtendedDisplayEnum.Footer:
        style = 'extended-wrapper';
        break;

      default:
        break;
    }
    return style;
  }

  extendedDisplayCss() {
    if (!this.displayConfiguration) {
      return null;
    }
    let style = '';
    switch (this.displayConfiguration.extendedDisplay) {
      case DisplayConfigurationExtendedDisplayEnum.Footer:
        style = 'extended-footer';
        break;
      default:
        break;
    }
    return style;
  }

  private _updateVisualItems() {

    if (!this.items) {
      return;
    }

    this._updateVisualItemsPositions(this.items);
  }

  private _updateVisualItemsPositions(displayItems: DisplayItem[]) {
    const itemsDictionary = {};
    const sortedItems = [];
    for (let i = 0; i < displayItems.length; i++) {
      if (displayItems[i].posX && displayItems[i].posY) {
        continue;
      }
      let items = itemsDictionary[displayItems[i].sequence];
      if (!items) {
        items = [];
        itemsDictionary[displayItems[i].sequence] = items;
        sortedItems.push(displayItems[i].sequence);
      }
      items.push(displayItems[i]);
    }

    sortedItems.sort((first, second) => {
      return second[1] - first[1];
    });

    let posX = 1;
    let posY = 1;
    let maxSizeVertical = 1;

    for (const key in sortedItems) {
      if (!sortedItems.hasOwnProperty(key)) {
        continue;
      }

      const items = itemsDictionary[sortedItems[key]];
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        element.posX = posX;
        element.posY = posY;
        if (element.sizeVertical > maxSizeVertical) {
          maxSizeVertical = element.sizeVertical;
        }

        const nextPosX = posX + (element.sizeHorizontal || 1);
        if (nextPosX > this.displayConfiguration.columnsCount) {
          posX = 1;
          posY += maxSizeVertical;
          maxSizeVertical = 1;
        } else {
          posX = nextPosX;
        }
      }
    }
  }

  get itemsForGrid(): DisplayItem[] {
    this._updateVisualItems();
    if (this.displayConfiguration == null || this.items == null) {
      return null;
    }

    const items: DisplayItem[] = [];
    this.items.forEach(element => {
      if (
        element != null
        &&
        element.isVisibleInGrid
        &&
        element.positionInGrid(this.displayConfiguration.columnsCount, this.displayConfiguration.rowCount)) {
          items.push(element);
        }
    });
    return items;
  }

  showAdditionDisplayItem(displayItem: DisplayItem) {
    this.gridTreeState.nextStep(displayItem);
    this.showCurrentStep();
  }

  renderHtmlTemplate(displayItem: DisplayItem): string {
    const redenderData = this.displayGridWorkflowService.getDisplayItemRenderData(displayItem);
    return displayItem.getformattedHtmlTemplate(this.language, redenderData);
  }

  navigateToRootPage(): void {
    this.gridTreeState.positionToMainRoot();
    this.showCurrentStep();
  }

  backPage(): void {
    if (!this.gridTreeState.backStep()) {
      this.gridTreeState.positionToMainRoot();
    }
    this.showCurrentStep();
  }
}

