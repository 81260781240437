import { KeyboardType, OnScreenKeyboardComponent } from "../../components/general/on-screen-keyboard/on-screen-keyboard.component";

export class KeyboardHelper {
  private _caretPos: number;
  private _screenKeyboard: OnScreenKeyboardComponent;

  constructor(screenKeyboard: OnScreenKeyboardComponent) {
    this._screenKeyboard = screenKeyboard;
  }

  get caretPos(): number {
    return this._caretPos;
  }

  keyPress(value: string, key: any, maxLength?: number): string {
    const keyValue = key.value;
    const keyAction = key.action;

    if (keyAction == 'del') {
      if (value) {
        if (this.caretPos && this.caretPos > 0) {
          const result = [value.slice(0, this.caretPos - 1), value.slice(this.caretPos)].join('');
          this._caretPos -= 1;
          return result;
        } else if (this.caretPos == 0) {
          return value;
        } else {
          return value.substring(0, value.length - 1);
        }
      }

      return value;
    }

    if (value) {
      if (maxLength && value.length >= maxLength) {
        return value;
      }

      if (this.caretPos || this.caretPos == 0) {
        const result = [value.slice(0, this.caretPos), keyValue, value.slice(this.caretPos)].join('');
        this._caretPos += 1;
        return result;
      }
      return value + keyValue;
    } else {
      return keyValue;
    }
  }

  setKeyboardType(
    value: string,
    emptyFieldKeyboardType: KeyboardType,
    keyboardType: KeyboardType,
    maxLength: number,
  ) {
    if (value && value.length > 0 && this.caretPos != 0) {
      if (maxLength && value.length >= maxLength) {
        this._screenKeyboard.keyboardType = KeyboardType.None;
      } else {
        this._screenKeyboard.keyboardType = keyboardType;
      }
    } else {
      this._screenKeyboard.keyboardType = emptyFieldKeyboardType;
    }
  }

  updateCaretPos(oField) {
    if (oField.value.length == oField.selectionStart) {
      this._caretPos = null;
    } else {
      this._caretPos = oField.selectionStart;
    }
  }
}
