import { BaseButton } from './base-button';

export class AbortButton extends BaseButton {

  constructor(
    ) {
      super();
      this.visible = false;
    }


  static fromOther(other: AbortButton): AbortButton {
    return Object.assign(new AbortButton(), other);
  }

  resetText() {
    this.text = 'Abort';
  }
}
