import { Injectable, Injector } from "@angular/core";
import { ConfigurationService } from "../configuration/configuration.service";
import { VuCommunicationService } from "../vu/vu-communication.service";
import { SaleService } from "../sale.service";
import { AdditionalPropertiesConfigurationService } from "../configuration/additional-properties-configuration.service";

@Injectable()
export class DemoPaymentService {
  private configurationService: ConfigurationService;
  private vuCommunicationService: VuCommunicationService;
  private saleService: SaleService;
  private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService;

  constructor(
    protected injector: Injector,
  ) {
    this.configurationService = this.injector.get(ConfigurationService);
    this.vuCommunicationService = injector.get(VuCommunicationService);
    this.saleService = injector.get(SaleService);
    this.additionalPropertiesConfigurationService = injector.get(AdditionalPropertiesConfigurationService);
  }

  onDenominationClick() {
    if (!this.additionalPropertiesConfigurationService.isDemoMode)
      return;

    this.vuCommunicationService.vuHttp.demoPayment().subscribe(
      result => {
        const amountToPay = this.saleService.paymentSession.amountToPay.clone();
        this.saleService.onMoneyChanged(amountToPay);
      }
    );
  }
}
