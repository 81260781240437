export class Asset {
  id: string;
  fileName: string;
  supportedLanguages: string[];

  static fromAny(x: any) {
    return Object.assign(new Asset(), x);
  }

  get isSupportedLanguagesExists(): boolean {
    return this.supportedLanguages != null && this.supportedLanguages.length !== 0;
  }

  isLanguageSupported(odooLanguageId): boolean {
    return this.isSupportedLanguagesExists && this.supportedLanguages.indexOf(odooLanguageId) !== -1;
  }

  get tryGetFirstSupportedLanguage(): string {
    return this.isSupportedLanguagesExists && this.supportedLanguages[0] || '';
  }
}
