import { Action } from '@ngrx/store';
import { TicketUseInfo } from '../lib/lib';
import { PayloadAction } from '../lib/payload-action';

const initialState = new TicketUseInfo();

export class TicketUseInfoActionType {
  static readonly TICKET_USE_INFO_TICKET = 'TicketUseInfoTicket';
  static readonly TICKET_USE_INFO_USE = 'TicketUseInfoUse';
}

export function ticketUseInfoReducer(state = initialState, action: PayloadAction) {
  let isChanged = true;
  switch (action.type) {
    case TicketUseInfoActionType.TICKET_USE_INFO_TICKET:
      state.ticket = action.payload;
      break;
    case TicketUseInfoActionType.TICKET_USE_INFO_USE:
      state.ticketUse = action.payload;
      break;
    default:
      isChanged = false;
      break;
  }
  return isChanged ? TicketUseInfo.fromOther(state) : state;
}
