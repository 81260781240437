import { Injectable } from '@angular/core';
import { ExternalApiBaseService } from './external-api-base.service';
import { LogisticsRequestSimulatorService } from '../../logistics-request/logistics-request-simulator.service';

@Injectable()
export class ExternalApiSimulatorService extends ExternalApiBaseService {

  private logisticsRequestSimulatorService: LogisticsRequestSimulatorService;

  protected init() {
    super.init();
    this.logisticsRequestSimulatorService = this.injector.get(LogisticsRequestSimulatorService);
  }

  sendGetRequest(url: string, requestData: Map<string, any>): Promise<any> {
    if (url.includes('http://') || url.includes('https://'))
      return super.sendGetRequest(url, requestData);

    return this.processRequest(url, requestData);
  }

  sendPostRequest(url: string, requestData: Map<string, any>): Promise<any> {
    if (url.includes('http://') || url.includes('https://'))
      return super.sendPostRequest(url, requestData);

    return this.processRequest(url, requestData);
  }

  sendPostRequestOdooJson(url: string, requestData: Map<string, any>): Promise<any> {
    if (url.includes('http://') || url.includes('https://'))
      return super.sendPostRequestOdooJson(url, requestData);

    return this.processRequest(url, requestData);
  }

  processRequest(url: string, requestData: Map<string, any>): Promise<any> {
    this.loadingSpinnerService.show();
    return new Promise((resolve, reject) => {
      if (url.includes('/logistics/request/')) {
        setTimeout(()=> {
          this.loadingSpinnerService.hide();
          resolve(this.logisticsRequestSimulatorService.getDisplayItem(requestData['barcode']));
        },
        5000);
      }
    });
  }
}
