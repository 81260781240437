import { Injectable, Injector } from '@angular/core';
import { ConfigurationService } from '../../configuration/configuration.service';
import { TimeoutModalComponent } from '../../../components/general/timeout-modal/timeout-modal.component';
import { ModalService } from '../../gui/modal/modal-service';
import { LoggingService } from '../../logging/logging.service';
import { DisplayGridWorkflowService } from './display-grid-workflow.service';
import { DisplayItem } from '../../../lib/display/display-item/display-item';
import { StoreHistoryService } from '../../store-history.service';
import { BsModalRef } from 'ngx-bootstrap';

@Injectable()
export class DisplayGridInactivityService {

  private timer: any;
  private internalDisplayGridWorkflowService: DisplayGridWorkflowService;

  private modalRef: BsModalRef;

  constructor(
    private configurationService: ConfigurationService,
    private modalService: ModalService,
    private loggingService: LoggingService,
    private storeHistoryService: StoreHistoryService,
    private injector: Injector,
  ) {
  }

  get displayGridWorkflowService(): DisplayGridWorkflowService {
    if (this.internalDisplayGridWorkflowService == null) {
      this.internalDisplayGridWorkflowService = this.injector.get(DisplayGridWorkflowService);
    }

    return this.internalDisplayGridWorkflowService;
  }

  get parentDisplayItem(): DisplayItem {
    return this.displayGridWorkflowService.parentDisplayItem;
  }

  restartTimer() {
    this.stopTimer();

    if (this.storeHistoryService.isEmpty) {
      return;
    }

    const timeout = this.inactivityTimeoutWithoutModal || this.configurationService.configuration.timeIntervalBeforeTimeoutModal;
    this.startInactivityTimer(timeout);
  }

  get inactivityTimeoutWithoutModal(): number {
    if (this.parentDisplayItem && this.parentDisplayItem.timeoutToShowNextItem > 0 && this.parentDisplayItem.navigationItem) {
      return this.parentDisplayItem.timeoutToShowNextItem;
    }
    return 0;
  }

  private startInactivityTimer(timeout: number) {
    this.loggingService.info(`DisplayGridInactiveService. startInactivityTimer, ${timeout} ms`);
    this.timer = setTimeout(() => {
      this.inactivityTimeout();
    }, timeout);
  }

  stopTimer() {
    if (this.timer) {
      this.loggingService.info('DisplayGridInactiveService. stopInactivityTimer');
      clearTimeout(this.timer);
    }
    this.timer = null;
    if (this.modalRef) {
      this.modalService.close(this.modalRef);
      this.modalRef = null;
    }
  }

  private inactivityTimeout() {
    this.showInactivityDialog(
      (cancel) => {
        if (cancel) {
          this.restartTimer();
        } else {
          this.tryStopAllWorkflow();
        }
      }
    );
  }

  private showInactivityDialog(action: (cancel: boolean) => void) {
    if (this.inactivityTimeoutWithoutModal) {
      if (action) {
        action(false);
      }
    } else {
      this.modalRef = this.modalService.show(
        TimeoutModalComponent,
        null,
        (isContinue: boolean) => {
          if (!this.modalRef) {
            return;
          }

          if (isContinue) {
            this.restartTimer();
          } else {
            if (action) {
              action(false);
            }
          }
        }
      );

      this.modalRef.content.startTimer(15);
    }
  }

  private tryStopAllWorkflow() {
    if (!this.displayGridWorkflowService.canStop) {
      this.restartTimer();
      return;
    }

    this.displayGridWorkflowService.doHardReset(() => {
      if (this.parentDisplayItem && this.inactivityTimeoutWithoutModal) {
        this.displayGridWorkflowService.gridVisualItemsComponent.showAdditionDisplayItem(this.parentDisplayItem.navigationItem);
      } else {
        this.displayGridWorkflowService.gridVisualItemsComponent.navigateToRootPage();
      }
    });
  }
}
