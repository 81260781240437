import { Injectable, Injector } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { DictionaryHelper } from '../../lib/dictionary-helper';

@Injectable()
export class AdditionalPropertiesConfigurationService {
  private isProduction = false;
  private configurationService: ConfigurationService;

  constructor(
    private injector: Injector,
  ) {
    this.configurationService = injector.get(ConfigurationService);
    this.isProduction = this.configurationService.configuration.isProduction;
  }

  get additionalProperties(): DictionaryHelper {
    return this.configurationService.configuration.additionalPropertiesHelper;
  }

  get isLiteMode(): boolean {
    return this.additionalProperties.getBoolean("lite_mode");
  }

  get isDemoMode(): boolean {
    return this.additionalProperties.getBoolean("demo");
  }

  get turnstileIsLeftSide(): boolean {
    return this.additionalProperties.getBoolean("turnstile_on_left_side");
  }

  get timeoutPaymentSec(): number {
    return this.additionalProperties.getNumber("timeout_payment", 30);
  }

  get timeoutEntranceSec(): number {
    return this.additionalProperties.getNumber("timeout_entrance", 60);
  }

  get timeoutReturnMoneySec(): number {
    return this.additionalProperties.getNumber("timeout_return_money", 10);
  }

  get timeoutPrinterSec(): number {
    return this.additionalProperties.getNumber("timeout_printer", 60);
  }

  get colorDefault(): string {
    return this.additionalProperties.getString("color_default", '#ffffff');
  }

  get colorActive(): string {
    return this.additionalProperties.getString("color_active", '#2c8ccd');
  }

  get colorDisabled(): string {
    return this.additionalProperties.getString("color_disabled", 'gray');
  }

  get colorSuccess(): string {
    return this.additionalProperties.getString("color_success", '#00ff00');
  }

  get colorError(): string {
    return this.additionalProperties.getString("color_error", '#ff0000');
  }

  get waitForTicketRemoved(): boolean {
    return this.additionalProperties.getBoolean("wait_for_ticket_removed");
  }

  get timeoutInvalidRfidCardInserted(): number {
    return this.additionalProperties.getNumber("timeout_invalid_rfid_card_inserted", 3);
  }

  get isCreditCardPaymentAvailable(): boolean {
    return this.additionalProperties.getBoolean("is_credit_card_payment_available", this.isProduction ? false : true);
  }

  get isTurnstileFastOpenEnabled(): boolean {
    return this.additionalProperties.getBoolean("is_turnstile_fast_open_enabled");
  }

  get isCashPaymentAvailable(): boolean {
    return this.additionalProperties.getBoolean("is_cash_payment_available");
  }

  get ignoreBarcodeReaderScanOnMainPage(): boolean {
    return this.additionalProperties.getBoolean("ignore_barcode_reader_scan_on_main_page");
  }

  get ticketLength(): number {
    return this.additionalProperties.getNumber("ticket_length", 19);
  }

  get separatorPosition(): number {
    return this.additionalProperties.getNumber("separator_position", 5);
  }

  get isContactlessCardAccepted (): boolean {
    return this.additionalProperties.getBoolean("is_contactless_card_accepted");
  }

  get isReadBarcodeAtGateAfterPayment (): boolean {
    return this.additionalProperties.getBoolean("is_read_barcode_at_the_gate_after_payment");
  }

  get isGateOnTheLeftSide (): boolean {
    return this.additionalProperties.getBoolean("is_gate_on_the_left_side");
  }

  get supportLanguages(): string[] {
    const support_languages =  this.additionalProperties.getString("support_languages");
    if(!support_languages)
      return [];
    return support_languages.split(";");
  }
}
