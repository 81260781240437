import { Injectable } from '@angular/core';

import { DisplayItemTypeEnum } from '../../../../lib/display/display-item/display-item-type.enum';
import { BaseGridWorkflowService } from '../base-grid-workflow.service';

@Injectable()
export class PdfViewerGridWorkflowService extends BaseGridWorkflowService {

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.Pdf;
  }
}
