import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/Subscription';
import { BackButton } from '../lib/lib';
import { LoggingService } from './logging/logging.service';
import { Header } from '../lib/header';
import { Footer } from '../lib/footer';
import { AbortButton } from '../lib/buttons/abort-button';

@Injectable()
export class UiService {
  private isDialogOpen: boolean;
  backButtonStateSubscription: Subscription;
  backButtonState: BackButton;
  headerStateSubscription: Subscription;
  headerState: Header;
  footerState: Footer;
  footerStateSubscription: Subscription;
  _log: LoggingService;
  abortButtonStateSubscription: Subscription;
  abortButtonState: AbortButton;

  constructor(
    private injector: Injector,
    private store: Store<string>,
  ) {
    this.headerState = new Header();
    this.footerState = new Footer();
    this.backButtonStateSubscription = this.store.select<BackButton>('backButtonState')
      .subscribe(x => this.onBackButtonStateChanged(x));
    this.headerStateSubscription = this.store.select<Header>('pageHeader')
      .subscribe(x => this.onHeaderStateChanged(x));
    this.footerStateSubscription = this.store.select<Footer>('pageFooter')
      .subscribe(x => this.onFooterStateChanged(x));
    this.abortButtonStateSubscription = this.store.select<AbortButton>('abortButtonState')
      .subscribe(x => this.onAbortButtonStateChanged(x));
  }

  get log() {
    if (this._log == null) {
      this._log = this.injector.get(LoggingService);
    }
    return this._log;
  }

  onBackButtonStateChanged(state: BackButton) {
    this.backButtonState = state;
  }

  onHeaderStateChanged(state: Header) {
    this.headerState = state;
  }

  onFooterStateChanged(state: Footer) {
    this.footerState = state;
  }

  onAbortButtonStateChanged(state: AbortButton) {
    this.abortButtonState = state;
  }
}
