import { Injectable, Injector } from '@angular/core';
import { DisplayItem } from '../../../lib/display/display-item/display-item';
import { IDisplayGridWorkflowService } from './display-grid-workflow.interface';
import { PrintGridWorkflowService } from './print/print-grid-workflow.service';
import { ProductGridWorkflowService } from './product/product-grid-workflow.service';
import { CategoryGridWorkflowService } from './category/category-grid-workflow.service';
import { StaticGridWorkflowService } from './static/static-grid-workflow.service';
import { TicketGridWorkflowService } from './ticket/ticket-grid-workflow.service';
import { PinGridWorkflowService } from './pin/pin-grid-workflow.service';
import { OpenFmcuGridWorkflowService } from './open-fmcu/open-fmcu-grid-workflow.service';
import { DebugGridWorkflowService } from './debug/debug-grid-workflow.service';
import { GridVisualItemsComponent } from '../../../components/visual-item/grid-visual-items/grid-visual-items.component';
import { CashPaymentGridWorkflowService } from './cash-payment/cash-payment-grid-workflow.service';
import { StepsListWorkflowService } from './steps-list/steps-list-workflow.service';
import { CashPaymentConfirmationGridWorkflowService } from './cash-payment/cash-payment-confirmation/cash-payment-confirmation-workflow.service';
import { CashPaymentCancellationGridWorkflowService } from './cash-payment/cash-payment-cancellation/cash-payment-cancellation-workflow.service';
import { CashRefundGridWorkflowService } from './cash-refund/cash-refund-grid-workflow.service';
import { CashRefundConfirmationGridWorkflowService } from './cash-refund/cash-refund-confirmation/cash-refund-confirmation-workflow.service';
import { RfidCardGridWorkflowService } from './rfid-card/rfid-card-grid-workflow.service';
import { DisplayGridInactivityService } from './display-grid-inactivity.service';
import { PdfViewerGridWorkflowService } from './pdf-viewer/pdf-viewer-grid-workflow.service';

@Injectable()
export class DisplayGridWorkflowService {

  private _context = {};
  private _parentDisplayItem: DisplayItem;
  private _prevouisDisplayItems: DisplayItem[];
  private readonly _displayGridWorkflowServices: Map<string, IDisplayGridWorkflowService> = new Map<string, IDisplayGridWorkflowService>();

  private _displayGridInactiveService: DisplayGridInactivityService;

  private _gridVisualItemsComponent: GridVisualItemsComponent;

  constructor(
    private injector: Injector,
    private productGridWorkflowService: ProductGridWorkflowService,
    private debugGridWorkflowService: DebugGridWorkflowService,
    private categoryGridWorkflowService: CategoryGridWorkflowService,
    private staticGridWorkflowService: StaticGridWorkflowService,
    private printGridWorkflowService: PrintGridWorkflowService,
    private ticketGridWorkflowService: TicketGridWorkflowService,
    private rfidCardGridWorkflowService: RfidCardGridWorkflowService,
    private pinGridWorkflowService: PinGridWorkflowService,
    private openFmcuGridWorkflowService: OpenFmcuGridWorkflowService,
    private cashPaymentGridWorkflowService: CashPaymentGridWorkflowService,
    private stepsListWorkflowService: StepsListWorkflowService,
    private cashPaymentConfirmationGridWorkflowService: CashPaymentConfirmationGridWorkflowService,
    private cashPaymentCancelationGridWorkflowService: CashPaymentCancellationGridWorkflowService,
    private cashRefundGridWorkflowService: CashRefundGridWorkflowService,
    private cashRefundConfirmationGridWorkflowService: CashRefundConfirmationGridWorkflowService,
    private pdfViewerGridWorkflowService: PdfViewerGridWorkflowService,
  ) {
    this._registerService(productGridWorkflowService);
    this._registerService(debugGridWorkflowService);
    this._registerService(categoryGridWorkflowService);
    this._registerService(staticGridWorkflowService);
    this._registerService(printGridWorkflowService);
    this._registerService(ticketGridWorkflowService);
    this._registerService(rfidCardGridWorkflowService);
    this._registerService(pinGridWorkflowService);
    this._registerService(openFmcuGridWorkflowService);
    this._registerService(cashPaymentGridWorkflowService);
    this._registerService(stepsListWorkflowService);
    this._registerService(cashPaymentConfirmationGridWorkflowService);
    this._registerService(cashPaymentCancelationGridWorkflowService);
    this._registerService(cashRefundGridWorkflowService);
    this._registerService(cashRefundConfirmationGridWorkflowService);
    this._registerService(pdfViewerGridWorkflowService);
  }

  _registerService(displayGridWorkflowService: IDisplayGridWorkflowService) {
    if (!displayGridWorkflowService) {
      return;
    }
    displayGridWorkflowService.initialize(this);
    this._displayGridWorkflowServices.set(displayGridWorkflowService.displayItemType, displayGridWorkflowService);
  }

  initalizeGrid(gridVisualItemsComponent: GridVisualItemsComponent) {
    this._gridVisualItemsComponent = gridVisualItemsComponent;
  }

  reset(): any {
    this._context = {};
    this._displayGridWorkflowServices.forEach((displayGridWorkflowService) => {
      if (displayGridWorkflowService) {
        displayGridWorkflowService.reset();
      }
    });
  }

  get displayGridWorkflowService(): DisplayGridInactivityService {
    if (this._displayGridInactiveService == null) {
      this._displayGridInactiveService = this.injector.get(DisplayGridInactivityService);
    }

    return this._displayGridInactiveService;
  }

  get gridVisualItemsComponent(): GridVisualItemsComponent {
    return this._gridVisualItemsComponent;
  }

  get parentDisplayItem(): DisplayItem {
    return this._parentDisplayItem;
  }

  restartInactivityTimer() {
    this.displayGridWorkflowService.restartTimer();
  }

  stopInactivityTimer() {
    this.displayGridWorkflowService.stopTimer();
  }

  get canStop(): boolean {
    for (const key in this._displayGridWorkflowServices.keys()) {
      if (!this._displayGridWorkflowServices.hasOwnProperty(key)) {
        continue;
      }
      const displayGridWorkflowService = this._displayGridWorkflowServices.get(key);
      if (displayGridWorkflowService && !displayGridWorkflowService.canDoHardReset) {
        return false;
      }
    }

    return true;
  }

  doHardReset(action: () => void) {
    const keys = Array.from(this._displayGridWorkflowServices.keys());
    if (keys.length === 0) {
      return;
    }

    let keyIndex = 0;
    const checkResult = () => {
      keyIndex++;
      if (keys.length <= keyIndex) {
        if (typeof (action) === 'function') {
          action();
        }
      } else {
        this._displayGridWorkflowServices.get(keys[keyIndex]).hardReset(checkResult);
      }
    };

    this._displayGridWorkflowServices.get(keys[keyIndex]).hardReset(checkResult);
  }

  showDisplayItems(parentDisplayItem: DisplayItem, displayItems: DisplayItem[]) {
    this.stopInactivityTimer();
    this._displayGridWorkflowServices.forEach((displayGridWorkflowService) => {
      if (displayGridWorkflowService) {
        displayGridWorkflowService.showDisplayItems(this._prevouisDisplayItems, false, this._context);
      }
    });

    this._parentDisplayItem = parentDisplayItem;
    this._prevouisDisplayItems = displayItems;
    if (displayItems == null) {
      return;
    }

    this._displayGridWorkflowServices.forEach((displayGridWorkflowService) => {
      if (displayGridWorkflowService) {
        displayGridWorkflowService.showDisplayItems(displayItems, true, this._context);
      }
    });

    this.restartInactivityTimer();
  }

  clickDisplayItems(displayItem: DisplayItem) {
    if (displayItem == null) {
      return false;
    }

    let result = false;
    this._displayGridWorkflowServices.forEach((displayGridWorkflowService) => {
      if (displayGridWorkflowService != null) {
        if (displayGridWorkflowService.processAction(displayItem, this._context)) {
          result = true;
        }
      }
    });
    return result;
  }

  isSupportDisplayItem(displayItem: DisplayItem): boolean {
    if (displayItem == null) {
      return false;
    }
    return this._displayGridWorkflowServices.has(displayItem.type);
  }

  updateContext(context: any) {
    this._displayGridWorkflowServices.forEach((displayGridWorkflowService) => {
      if (displayGridWorkflowService) {
        displayGridWorkflowService.updateContext(context);
      }
    });

    this._context = Object.assign(this._context, context);
  }

  buildExternalApiRequestData(): Map<string, any> {
    const requestData = new Map<string, any>();
    this._displayGridWorkflowServices.forEach((displayGridWorkflowService) => {
      if (displayGridWorkflowService) {
        displayGridWorkflowService.fillExternalApiRequestData(requestData, this._context);
      }
    });

    return requestData;
  }

  getDisplayItemRenderData(displayItem: DisplayItem): Map<string, any> {
    const data: Map<string, any> = new Map<string, any>();
    this._displayGridWorkflowServices.forEach((displayGridWorkflowService) => {
      if (displayGridWorkflowService) {
        displayGridWorkflowService.fillDisplayItemRenderData(displayItem, data, this._context);
      }
    });

    return data;
  }

  changeIsDisabledState(uniqueName: string, isDisabled: boolean) {
    if (uniqueName == null || uniqueName.trim().length === 0) {
      return;
    }

    const prevouisDisplayItem = this._prevouisDisplayItems.find((p) => p.uniqueName === uniqueName);
    if (prevouisDisplayItem != null) {
      prevouisDisplayItem.isDisabled = isDisabled;
    }
  }

  getContextParameter(parameter: string): any {
    if (parameter && this._context && this._context[parameter]) {
      return this._context[parameter];
    }
    return false;
  }
}
