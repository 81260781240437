import { Component, OnInit, OnDestroy, LOCALE_ID, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { TicketUseInfo, Ticket, TicketUseByDate } from '../../../lib/lib';
import { LanguageService, getLanguageFactory } from '../../../services/language.service';
import { DispatcherService } from '../../../services/dispatcher.service';

@Component({
  moduleId: module.id,
  selector: 'ticket-use',
  templateUrl: 'ticket-use.component.html',
  styleUrls: ['ticket-use.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: getLanguageFactory,
    }
  ]
})
export class TicketUseComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  ticketUseInfo = new TicketUseInfo();
  today = Date.now();
  constructor(
    private dispatcherService: DispatcherService,
    private languageService: LanguageService,
  ) {
  }

  ngOnInit(): void {
    this.subscription = this.dispatcherService.onTicketUseInfoSubscribe((x: TicketUseInfo) => {
      this.ticketUseInfo = x;
    });
    this.dispatcherService.pageHeaderSet('Ticket History');
  }

  get ticket(): Ticket {
    return this.ticketUseInfo == null ? null : this.ticketUseInfo.ticket;
  }

  get ticketUseByDay(): TicketUseByDate[] {
    if (this.ticketUseInfo == null ||
      this.ticketUseInfo.ticketUse == null) { return []; }

    return TicketUseByDate.fromTicketUseArray(this.ticketUseInfo.ticketUse, false);
  }

  ngOnDestroy() {
    this.dispatcherService.pageHeaderSet();
    this.subscription.unsubscribe();
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }

  get productName(): string {
    return this.ticket.productFormattedName(this.language)
  }
}
