import { Component, OnInit } from '@angular/core';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { SaleService } from '../../../services/sale.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { LanguageService } from '../../../services/language.service';
import { MachinesSupervisorService } from '../../../services/machines/machines-supervisor.service';
import { CreditCardTerminalSimulatorService } from '../../../services/credit-card-terminal-simulator.service';

/* Initializes some services  */

@Component({
  moduleId: module.id,
  selector: 'dummy',
  templateUrl: 'dummy.component.html',
  styleUrls: ['dummy.component.scss']
})
export class DummyComponent implements OnInit {
  constructor(private saleService: SaleService,
    private configService: ConfigurationService,
    private languageService: LanguageService,
    private machineSupervisorService: MachinesSupervisorService,
    private vuCommunicationService: VuCommunicationService,
    private creditCardTerminalSimulatorService: CreditCardTerminalSimulatorService,
  ) {
  }

  ngOnInit(): void {
    document.getElementById('PopupLoading').style.display = 'none';
    this.machineSupervisorService.init();
  }
}
