export class BaseButton {
  enabled = true;
  visible = true;
  text: string;

  constructor(
  ) {
    this.resetText();
  }

  resetText() {
    this.text = '';
  }
}
