import { PrintTask } from './print-task';

export class PrintTaskResult {
  printTask: PrintTask;
  isSuccess: boolean;
  error: string;

  static fromOther(other: PrintTaskResult): PrintTaskResult {
    return new PrintTaskResult(PrintTask.fromOther(other.printTask),
      other.isSuccess,
      other.error);
  }

  constructor(
    task: PrintTask,
    isSuccess: boolean,
    error = '',
  ) {
    this.printTask = task;
    this.isSuccess = isSuccess;
    this.error = error;
  }

  toString(): string {
    return `${this.printTask}. isSuccess: '${this.isSuccess}'. error: '${this.error}'`;
  }
}
