import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieModule } from 'ngx-cookie';

import { ButtonsModule, ModalModule, BsDatepickerModule } from 'ngx-bootstrap';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { CarouselModule } from 'ngx-carousel-lib';
import { InlineSVGModule } from 'ng-inline-svg';

import { MatTabsModule, MatSlideToggleModule } from '@angular/material';

import { AppComponent } from './app.component';

import { SignalRModule } from 'ng2-signalr';
import { SignalRConfiguration } from 'ng2-signalr';
import { Routes, RouterModule } from '@angular/router';
import { RootComponent } from './components/root/root.component';
import { VisualItemsComponent } from './components/visual-item/visual-items/visual-items.component';
import { MessageComponent } from './components/general/message/message.component';
import { TicketWaitForComponent } from './components/ticket/ticket-wait-for/ticket-wait-for.component';
import { TicketLoadingComponent } from './components/ticket/ticket-loading/ticket-loading.component';
import { TicketInfoComponent } from './components/ticket/ticket-info/ticket-info.component';
import { TicketUseComponent } from './components/ticket/ticket-use/ticket-use.component';
import { BasketComponent } from './components/shop/basket/basket.component';
import { VisualItemInfoComponent } from './components/visual-item/visual-item-info/visual-item-info.component';
import { PaymentCashComponent } from './components/shop/payment-cash/payment-cash.component';
import { PaymentCardComponent } from './components/shop/payment-card/payment-card.component';
import { WorkflowComponent } from './components/workflow/workflow/workflow.component';
import { ConfigurationService } from './services/configuration/configuration.service';

import reducers from './reducers/reducers';
import { TimeoutModalComponent } from './components/general/timeout-modal/timeout-modal.component';
import { TicketInfoTwoSidedComponent } from './components/ticket/ticket-info-two-sided/ticket-info-two-sided.component';
import { InMemoryDataService } from './services/in-memory-data.service';
import { VuHttpService } from './services/vu/http/vu-http.service';
import { VuHttpSimulatorService } from './services/vu/http/vu-http-simulator.service';
import { MessageService } from './services/message.service';
import { LoggingService } from './services/logging/logging.service';
import { TestingService } from './services/testing.service';
import { MachineNonOperationalService } from './services/machines/machine-non-operational.service';
import { MachineRootService } from './services/machines/machine-root.service';
import { MachineSaleShopService } from './services/machines/machine-sale.service';
import { MachinePostSaleService } from './services/machines/machine-post-sale.service';
import { MachineTicketService } from './services/machines/machine-ticket.service';
import { MachinePayoutService } from './services/machines/machine-payout.service';
import { MachinesSupervisorService } from './services/machines/machines-supervisor.service';
import { DispatcherService } from './services/dispatcher.service';
import { NonOperationalService } from './services/non-operational.service';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { GlobalSettings } from './lib/lib';
import { VuCommunicationService } from './services/vu/vu-communication.service';
import { SignalRService } from './services/vu/connection/vu-connection-signalr.service';
import { SignalRSimulatorService } from './services/vu/connection/vu-connection-simulator.service';
import { VuLoggingService } from './services/vu/vu-logging.service';
import { SaleService } from './services/sale.service';
import { LanguageService } from './services/language.service';
import { UiService } from './services/ui.service';
import { MachinesInactivityService } from './services/machines/machines-inactivity.service';
import { ThemeService } from './services/theme.service';
import { TeaserComponent } from './components/general/teaser/teaser.component';
import { SimpleButtonComponent } from './components/general/simple-button/simple-button.component';
import { BackButtonComponent } from './components/general/back-button/back-button.component';
import { CountButtonComponent } from './components/general/count-button/count-button.component';
import { DummyComponent } from './components/general/dummy/dummy.component';
import { LanguagesButtonComponent } from './components/general/languages/languages-button.component';
import { LanguagesComponent } from './components/general/languages/languages.component';
import { OnScreenKeyboardComponent } from './components/general/on-screen-keyboard/on-screen-keyboard.component';
import { VirtualKeyboardComponent } from './components/general/virtual-keyboard/virtual-keyboard.component';
import { PageHeaderComponent } from './components/general/page-header/page-header.component';
import { PageHeaderTitleComponent } from './components/general/page-header-title/page-header-title.component';
import { AcceptedCashComponent } from './components/shop/accepted-cash/accepted-cash.component';
import { AcceptedCreditCardsComponent } from './components/shop/accepted-credit-cards/accepted-credit-cards.component';
import { DenominationComponent } from './components/shop/denomination/denomination.component';
import { OrderLineComponent } from './components/shop/order-line/order-line.component';
import { CashSimulatorComponent } from './components/simulation/cash-simulator/cash-simulator.component';
import { SimulatorComponent } from './components/simulation/simulator/simulator.component';
import { VuRoleSimulatorComponent } from './components/simulation/vu-role-simulator/vu-role-simulator.component';
import { WorkflowStepStateComponent } from './components/workflow/workflow-step-state/workflow-step-state.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { StoreHistoryService } from './services/store-history.service';
import { VisualItemComponent } from './components/visual-item/visual-item/visual-item.component';
import { ScreenSaverService } from './services/screen-saver.service';
import { ScreenSaverComponent } from './components/screen-saver/screen-saver.component';
import { MachineScreenSaverService } from './services/machines/machine-screen-saver.service';
import { ScreenSaverSimulatorComponent } from './components/simulation/screen-saver-simulator/screen-saver-simulator.component';
import { FloatTimeComponent } from './components/general/float-time/float-time.component';
import { TicketParametersComponent } from './components/general/ticket-parameters/ticket-parameters.component';
import { BannerComponent } from './components/lite-mode/banner/banner.component';
import { MainPageComponent } from './components/lite-mode/main-page/main-page.component';
import { NavigationComponent } from './components/lite-mode/navigation/navigation.component';
import { CarouselComponent } from './components/lite-mode/carousel/carousel.component';
import { CoinsComponent } from './components/lite-mode/coins/coins.component';
import { CardsComponent } from './components/lite-mode/cards/cards.component';
import { MachineLiteSaleService } from './services/machines/lite/machine-lite-sale.service';
import { MachineLitePostSaleService } from './services/machines/lite/machine-lite-post-sale.service';
import { LiteDisplayService } from './services/lite/lite-display.service';
import { CarouselItemComponent } from './components/lite-mode/carousel-item/carousel-item.component';
import { LiteDisplayStepsService } from './services/lite/lite-display-steps.service';
import { TurnstileSimulatorComponent } from './components/simulation/turnstile-simulator/turnstile-simulator.component';
import { TurnstileSimulatorService } from './services/turnstile/turnstile-simulator.service';
import { AdditionalPropertiesConfigurationService } from './services/configuration/additional-properties-configuration.service';
import { PageLiteMessageComponent } from './components/lite-mode/page-lite-message/page-lite-message.component';
import { PageMessageComponent } from './components/general/page-message/page-message.component';
import { PrinterSimulatorComponent } from './components/simulation/printer-simulator/printer-simulator.component';
import { TouchTileSimulatorComponent } from './components/simulation/touch-tile-simulator/touch-tile-simulator.component';
import { TouchTileSimulatorService } from './services/touch-tile/touch-tile-simulator.service';
import { TouchTileService } from './services/touch-tile/touch-tile.service';
import { LiteTouchTileService } from './services/lite/lite-touch-tile.service';
import { RfidSimulatorComponent } from './components/simulation/rfid-simulator/rfid-simulator.component';
import { DemoPaymentService } from './services/payment/demo-payment.service';
import { TurnstileService } from './services/turnstile/turnstile.service';
import { NumberSpinnerComponent } from './components/general/number-spinner/number-spinner.component';
import { MachineSaleCreditCardService } from './services/machines/machine-sale-credit-card.service';
import { CreditCardTerminalSimulatorService } from './services/credit-card-terminal-simulator.service';
import { CreditCardTerminalSimulatorComponent } from './components/simulation/credit-card-terminal-simulator/credit-card-terminal-simulator.component';
import { BarcodeReaderSimulatorComponent } from './components/simulation/barcode-reader-simulator/barcode-reader-simulator.component';
import { BarcodeReaderService } from './services/barcode/barcode-reader.service';
import { TicketSimulatorComponent } from './components/simulation/ticket-simulator/ticket-simulator.component';
import { BarcodeReaderSimulatorService } from './services/barcode/barckode-reader-simulator.service';
import { TicketService } from './services/ticket/ticket.service';
import { TicketSimulatorService } from './services/ticket/ticket-simulator.service';
import { MachineTicketActivationService } from './services/machines/machine-ticket-activation.service';
import { TicketActivationWaitForComponent } from './components/ticket/ticket-activation-wait-for/ticket-activation-wait-for.component';
import { DemoWanzlRootComponent } from './components/demo-wanzl/demo-wanzl-root/demo-wanzl-root.component';
import { DatapickerRangeComponent } from './components/datapicker-range/datapicker-range.component';
import { SelectComponent } from './components/select/select.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { DisplayConfigurationSimulatorComponent } from './components/simulation/display-configuration-simulator/display-configuration-simulator.component';
import { DisplayConfigurationSimulatorService } from './services/display/configuration/display-configuration-simulator.service';
import { GridVisualItemsComponent } from './components/visual-item/grid-visual-items/grid-visual-items.component';
import { GridVisualItemComponent } from './components/visual-item/grid-visual-item/grid-visual-item.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { DatapickerComponent } from './components/datepicker/datepicker.component';
import { GateSimulatorComponent } from './components/simulation/gate-simulator/gate-simulator.component';
import { NotificationForEntranceComponent } from './components/general/notification-for-entrance/notification-for-entrance.component';
import { LanguageSelectionPopUpComponent } from './components/general/languages/language-selection-popup/language-selection-popup.component';
import { CustomCssSimulatorComponent } from './components/simulation/custom-css-simulator/custom-css-simulator.component';
import { LogoUpdateSimulatorComponent } from './components/simulation/logo-update-simulator/logo-update-simulator.component';
import { PrintModalComponent } from './components/print-modal/print-modal.component';
import { DisplayGridWorkflowService } from './services/display/grid/display-grid-workflow.service';
import { PrintGridWorkflowService } from './services/display/grid/print/print-grid-workflow.service';
import { ProductGridWorkflowService } from './services/display/grid/product/product-grid-workflow.service';
import { CategoryGridWorkflowService } from './services/display/grid/category/category-grid-workflow.service';
import { StaticGridWorkflowService } from './services/display/grid/static/static-grid-workflow.service';
import { TicketGridWorkflowService } from './services/display/grid/ticket/ticket-grid-workflow.service';
import { PinGridWorkflowService } from './services/display/grid/pin/pin-grid-workflow.service';
import { OpenFmcuGridWorkflowService } from './services/display/grid/open-fmcu/open-fmcu-grid-workflow.service';
import { LogisticsRequestSimulatorComponent } from './components/simulation/logistics-request-simulator/logistics-request-simulator.component';
import { LogisticsRequestSimulatorService } from './services/logistics-request/logistics-request-simulator.service';
import { DebugGridWorkflowService } from './services/display/grid/debug/debug-grid-workflow.service';
import { ExternalApiSimulatorService } from './services/vu/external-api/external-api-simulator.service';
import { ExternalApiService } from './services/vu/external-api/external-api.service';
import { MachineGateService } from './services/machines/machine-gate.service';
import { ModalService } from './services/gui/modal/modal-service';
import { GridVisualItemPinpadComponent } from './components/visual-item/grid-visual-item-pinpad/grid-visual-item-pinpad.component';
import { PinKeyboardComponent } from './components/general/pin-keyboard/pin-keyboard.component';
import { BaseScanCardModalComponent } from './components/modal/base-scan-card-modal/base-scan-card-modal.component';
import { InfoModalComponent } from './components/modal/info-modal/info-modal.component';
import { GridVisualItemCashPaymentComponent } from './components/visual-item/grid-visual-item-cash-payment/grid-visual-item-cash-payment.component';
import { CashPaymentGridWorkflowService } from './services/display/grid/cash-payment/cash-payment-grid-workflow.service';
import { LoadingSpinnerService } from './services/loading-spinner/loading-spinner.service';
import { StepsListWorkflowService } from './services/display/grid/steps-list/steps-list-workflow.service';
import { GridVisualItemWorkflowComponent } from './components/visual-item/grid-visual-item-workflow/grid-visual-item-workflow.component';
import { WorkflowSimulatorComponent } from './components/simulation/workflow-simulator/workflow-simulator.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CashPaymentCancellationGridWorkflowService } from './services/display/grid/cash-payment/cash-payment-cancellation/cash-payment-cancellation-workflow.service';
import { CashPaymentConfirmationGridWorkflowService } from './services/display/grid/cash-payment/cash-payment-confirmation/cash-payment-confirmation-workflow.service';
import { GridVisualItemCashRefundComponent } from './components/visual-item/grid-visual-item-cash-refund/grid-visual-item-cash-refund.component';
import { NumberValidator } from './directives/number-validator.directive';
import { CashKeyboardComponent } from './components/general/cash-keyboard/cash-keyboard.component';
import { CashRefundGridWorkflowService } from './services/display/grid/cash-refund/cash-refund-grid-workflow.service';
import { CashRefundConfirmationGridWorkflowService } from './services/display/grid/cash-refund/cash-refund-confirmation/cash-refund-confirmation-workflow.service';
import { RfidCardReaderService } from './services/rfid-card/rfid-card-reader.service';
import { RfidCardReaderSimulatorService } from './services/rfid-card/rfid-card-reader-simulator.service';
import { RfidCardGridWorkflowService } from './services/display/grid/rfid-card/rfid-card-grid-workflow.service';
import { DisplayGridInactivityService } from './services/display/grid/display-grid-inactivity.service';
import { ReceiptSwitcherComponent } from './components/shop/receipt-switcher/receipt-switcher.component';
import { GridVisualItemReceiptSwitcherComponent } from './components/visual-item/grid-visual-item-receipt-switcher/grid-visual-item-receipt-switcher.component';
import { MinMaxMoneyComponent } from './components/shop/min-max-money/min-max-money.component';
import { PdfViewerGridWorkflowService } from './services/display/grid/pdf-viewer/pdf-viewer-grid-workflow.service';
import { GridVisualItemPdfViewerComponent } from './components/visual-item/grid-visual-item-pdf-viewer/grid-visual-item-pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { GridVisualItemImageViewerComponent } from './components/visual-item/grid-visual-item-image-viewer/grid-visual-item-image-viewer.component';
import { AssetsService } from './services/assets.service';
import { NotificationMessageComponent } from './components/general/notification-message/notification-message.component';
import { NotificationMessageSimulatorComponent } from './components/simulation/notification-message-simulator/notification-message-simulator.component';
import { NotificationService } from './services/notification/notification.service';
import { AbortButtonComponent } from './components/general/abort-button/abort-button.component';

export function createConfig(): SignalRConfiguration {
  const c = new SignalRConfiguration();
  c.hubName = 'indexHub';
  //  c.qs = { user: 'donald' };
  c.url = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
  //  c.logging = true;
  return c;
}

export function init(configurationService: ConfigurationService) {
  return () => configurationService.load();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const routes: Routes = [
  { path: 'root', component: RootComponent },
  { path: 'root-demo-wanzl', component: DemoWanzlRootComponent },
  { path: '', redirectTo: '/root', pathMatch: 'full' },
  { path: 'visual-items/:type', component: VisualItemsComponent },
  { path: 'message', component: PageMessageComponent },
  { path: 'ticket-wait-for', component: TicketWaitForComponent },
  { path: 'ticket-activation-wait-for', component: TicketActivationWaitForComponent },
  { path: 'ticket-loading', component: TicketLoadingComponent },
  { path: 'ticket-info', component: TicketInfoComponent },
  { path: 'ticket-use', component: TicketUseComponent },
  { path: 'basket', component: BasketComponent },
  { path: 'visual-item-info', component: VisualItemInfoComponent },
  { path: 'payment-cash', component: PaymentCashComponent },
  { path: 'payment-card', component: PaymentCardComponent },
  { path: 'workflow', component: WorkflowComponent },
  { path: 'screen-saver', component: ScreenSaverComponent },
  { path: 'lite-mode', component: MainPageComponent },
  { path: 'lite-mode-message', component: PageLiteMessageComponent },
  { path: 'grid-visual-items/:type', component: GridVisualItemsComponent },
];


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTabsModule,
    CarouselModule,
    StoreModule.forRoot(reducers),
    ButtonsModule.forRoot(),
    SignalRModule.forRoot(createConfig),
    HttpClientModule,
    InlineSVGModule.forRoot({ baseUrl: '' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CookieModule.forRoot(),
    ModalModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    RouterModule.forRoot(routes),
    BsDatepickerModule.forRoot(),
    NgxSelectModule,
    NgxSpinnerModule,
    MatSlideToggleModule,
    PdfViewerModule,
  ],
  exports: [
    RouterModule
  ],
  declarations: [
    SimpleButtonComponent,
    AppComponent,
    TeaserComponent,
    BackButtonComponent,
    CountButtonComponent,
    DummyComponent,
    LanguagesButtonComponent,
    LanguagesComponent,
    OnScreenKeyboardComponent,
    TicketParametersComponent,
    VirtualKeyboardComponent,
    PageHeaderComponent,
    PageHeaderTitleComponent,
    AcceptedCashComponent,
    AcceptedCreditCardsComponent,
    DenominationComponent,
    OrderLineComponent,
    CashSimulatorComponent,
    SimulatorComponent,
    VuRoleSimulatorComponent,
    WorkflowStepStateComponent,
    RootComponent,
    VisualItemsComponent,
    MessageComponent,
    TicketWaitForComponent,
    TicketLoadingComponent,
    TicketUseComponent,
    BasketComponent,
    VisualItemInfoComponent,
    PaymentCashComponent,
    PaymentCardComponent,
    WorkflowComponent,
    TimeoutModalComponent,
    TicketInfoComponent,
    TicketInfoTwoSidedComponent,
    AutofocusDirective,
    VisualItemComponent,
    ScreenSaverComponent,
    ScreenSaverSimulatorComponent,
    FloatTimeComponent,
    BannerComponent,
    MainPageComponent,
    NavigationComponent,
    CarouselComponent,
    CoinsComponent,
    CardsComponent,
    CarouselItemComponent,
    TurnstileSimulatorComponent,
    PageLiteMessageComponent,
    PageMessageComponent,
    PrinterSimulatorComponent,
    TouchTileSimulatorComponent,
    RfidSimulatorComponent,
    NumberSpinnerComponent,
    CreditCardTerminalSimulatorComponent,
    BarcodeReaderSimulatorComponent,
    TicketSimulatorComponent,
    TicketActivationWaitForComponent,
    DemoWanzlRootComponent,
    DatapickerRangeComponent,
    SelectComponent,
    DisplayConfigurationSimulatorComponent,
    GridVisualItemsComponent,
    GridVisualItemComponent,
    SafeHtmlPipe,
    DatapickerComponent,
    GateSimulatorComponent,
    NotificationForEntranceComponent,
    LanguageSelectionPopUpComponent,
    PrintModalComponent,
    CustomCssSimulatorComponent,
    LogoUpdateSimulatorComponent,
    LogisticsRequestSimulatorComponent,
    GridVisualItemPinpadComponent,
    PinKeyboardComponent,
    BaseScanCardModalComponent,
    InfoModalComponent,
    GridVisualItemCashPaymentComponent,
    GridVisualItemWorkflowComponent,
    WorkflowSimulatorComponent,
    GridVisualItemCashRefundComponent,
    NumberValidator,
    CashKeyboardComponent,
    ReceiptSwitcherComponent,
    GridVisualItemReceiptSwitcherComponent,
    MinMaxMoneyComponent,
    GridVisualItemPdfViewerComponent,
    GridVisualItemImageViewerComponent,
    AbortButtonComponent,
    NotificationMessageComponent,
    NotificationMessageSimulatorComponent,
  ],
  entryComponents: [
    TimeoutModalComponent,
    TicketInfoTwoSidedComponent,
    TicketParametersComponent,
    NotificationForEntranceComponent,
    LanguageSelectionPopUpComponent,
    PrintModalComponent,
    BaseScanCardModalComponent,
    InfoModalComponent,
  ],
  bootstrap: [
    AppComponent,
  ],
  providers: [
    LoadingSpinnerService,
    InMemoryDataService,
    VuHttpService,
    VuHttpSimulatorService,
    ConfigurationService,
    MessageService,
    LoggingService,
    TestingService,
    DispatcherService,
    {
      useValue: '/store',
      provide: APP_BASE_HREF,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      deps: [ConfigurationService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: GlobalSettings.getCultureName(),
    },
    SignalRService,
    SignalRSimulatorService,
    VuLoggingService,
    UiService,
    AssetsService,
    ThemeService,
    MachinesSupervisorService,
    MachinesInactivityService,
    LanguageService,
    StoreHistoryService,
    MachinePostSaleService,
    MachineTicketService,
    MachinePayoutService,
    MachineSaleShopService,
    MachineSaleCreditCardService,
    MachineRootService,
    MachineNonOperationalService,
    NonOperationalService,
    SaleService,
    VuCommunicationService,
    ScreenSaverService,
    MachineScreenSaverService,
    MachineLiteSaleService,
    MachineLitePostSaleService,
    LiteDisplayService,
    LiteDisplayStepsService,
    AdditionalPropertiesConfigurationService,
    TurnstileSimulatorService,
    TouchTileSimulatorService,
    TouchTileService,
    LiteTouchTileService,
    DemoPaymentService,
    TurnstileService,
    BarcodeReaderService,
    RfidCardReaderService,
    BarcodeReaderSimulatorService,
    RfidCardReaderSimulatorService,
    TicketService,
    TicketSimulatorService,
    CreditCardTerminalSimulatorService,
    MachineTicketActivationService,
    DisplayConfigurationSimulatorService,
    DisplayGridWorkflowService,
    ProductGridWorkflowService,
    CategoryGridWorkflowService,
    StaticGridWorkflowService,
    DebugGridWorkflowService,
    PrintGridWorkflowService,
    TicketGridWorkflowService,
    RfidCardGridWorkflowService,
    PinGridWorkflowService,
    ExternalApiSimulatorService,
    ExternalApiService,
    OpenFmcuGridWorkflowService,
    LogisticsRequestSimulatorService,
    MachineGateService,
    ModalService,
    CashPaymentGridWorkflowService,
    StepsListWorkflowService,
    CashPaymentConfirmationGridWorkflowService,
    CashPaymentCancellationGridWorkflowService,
    CashRefundGridWorkflowService,
    CashRefundConfirmationGridWorkflowService,
    DisplayGridInactivityService,
    PdfViewerGridWorkflowService,
    NotificationService,
  ],
})
export class AppModule { }
