import { LocaleData } from 'ngx-bootstrap';

export const uk: LocaleData = {
  abbr: 'uk',
  months: 'Січень_Лютий_Березень_Квітня_Травень_Червень_Липень_Серпень_Вересень_Жовтень_Листопад_Грудень'.split(
    '_'
  ),
  monthsShort: 'січ_лют_бер_квіт_трав_черв_лип_серп_вер_жовт_лист_груд'.split('_'),
  weekdays: 'неділя_понеділок_вівторок_середа_четвер_п’ятниця_субота'.split(
    '_'
  ),
  weekdaysShort: 'нд_пн_вт_ср_чт_пт_сб'.split('_'),
  weekdaysMin: 'нд_пн_вт_ср_чт_пт_сб'.split('_'),
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D MMMM YYYY р.',
    LLL: 'D MMMM YYYY р., HH:mm',
    LLLL: 'dddd, D MMMM YYYY р., HH:mm'
  },
  calendar: {
    sameDay: '[Сьогодні] LT',
    nextDay: '[Завтра] LT',
    nextWeek: '[Наступного тижня] dddd [коли] LT',
    lastDay: '[Вчора] LT',
    lastWeek: '[Минулого тижня] dddd [коли] LT',
    sameElse: 'L'
  },
  relativeTime: {
    future : '%s майбутнього',
    past : '%s минулого',
    s : 'кілька секунд',
    m : 'одна хвилина',
    mm : '%d хвилин',
    h : 'одна година',
    hh : '%d годин',
    d : 'один день',
    dd : '%d день',
    M : 'один місяць',
    MM : '%d місяць',
    y : 'один рік',
    yy : '%d років'
  },
  dayOfMonthOrdinalParse: /\d{1,2}'(inci|nci|üncü|ncı|uncu|ıncı)/,
  ordinal(num: number, token?: string): string {
    return `${num}.`;
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  }
};
