import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { DispatcherService } from '../../../services/dispatcher.service';
import { WorkflowStepType, WorkflowStepState } from '../../../lib/lib';

@Component({
  selector: 'app-workflow-simulator',
  templateUrl: './workflow-simulator.component.html',
  styleUrls: ['./workflow-simulator.component.scss']
})
export class WorkflowSimulatorComponent implements OnInit, OnDestroy {
  protected dispatcherService: DispatcherService;
  selectedWorkflowType: WorkflowStepType;
  selectedWorkflowState: WorkflowStepState;

  keys = Object.keys;
  workflowStepType = WorkflowStepType;
  workflowStepState = WorkflowStepState;

  constructor(
    protected injector: Injector,
  ){
    this.dispatcherService = this.injector.get(DispatcherService);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }


  onWorkflowStepChange(event: any) {
    this.dispatcherService.workflowAddStep(this.selectedWorkflowType);
  }

  onWorkflowLastStepStateSet(event: any) {
    this.dispatcherService.workflowLastStepStateSet(this.selectedWorkflowState);
  }

  onWorkflowDeleteLastStep() {
    this.dispatcherService.workflowDeleteLastStep();
  }

  onWorkflowReset() {
    this.dispatcherService.workflowReset('', WorkflowStepType.None);
  }

  onWorkflowLastStepUpdate() {
    this.dispatcherService.workflowLastStepUpdate(... ['TEST_VALUE', 'TEST_VALUE']);
  }
}
