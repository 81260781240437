import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DatapickerBaseControl } from '../datepicker-base/datepicker-base-control';
import { LanguageService } from '../../services/language.service';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-datapicker-range',
  templateUrl: './datapicker-range.component.html',
  styleUrls: ['./datapicker-range.component.css']
})
export class DatapickerRangeComponent extends DatapickerBaseControl {
  constructor(
    protected languageService: LanguageService,
    protected messageService: MessageService,
  ) {
    super(languageService, messageService);
  };
}
