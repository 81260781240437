import { Ticket } from '../lib';
import { TicketUse } from './ticket-use';

export class TicketUseInfo {
  ticket: Ticket;
  ticketUse: TicketUse[];

  static fromOther(other: TicketUseInfo): TicketUseInfo {
    return Object.assign(new TicketUseInfo(), other);
  }
}
