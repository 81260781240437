export class MessageView {
  h1 = '';
  h2 = '';
  text = '';
  showText = false;

  static fromOther(other: MessageView): MessageView {
    return Object.assign(new MessageView(), other);
  }

  constructor(h1 = '', h2 = '', showText = false, text = '') {
    this.h1 = h1;
    this.h2 = h2;
    this.text = text;
    this.showText = showText;
  }
}
