export enum TicketStatus {
  Unknown = 'Unknown',
  Valid = 'Valid',
  Invalid = 'Invalid',
  InvalidOvertime = 'InvalidOvertime',
  WrongSide = 'WrongSide',
  TodayUsed = 'TodayUsed',
  Used = 'Used',
  Staff = 'Staff',
  Expired = 'Expired',
  OutOfSchedule = 'OutOfSchedule',
  VirtualTicket = 'VirtualTicket',
  InvalidVirtualTicket = 'InvalidVirtualTicket',
  OnlyExitAllowed = 'OnlyExitAllowed'
}
