import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  @Input()
  showLeft: boolean;

  @Input()
  showRight: boolean;

  @Output()
  leftClick: EventEmitter<any> = new EventEmitter();

  @Output()
  rightClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onLeftClick() {
    this.leftClick.emit();
  }

  onRightClick() {
    this.rightClick.emit();
  }
}
