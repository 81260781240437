export enum DisplayItemTypeEnum {
  Static = 'static',
  Debug = 'debug',
  Category = 'category',
  Product = 'product',
  Print = 'print',
  Ticket = 'ticket',
  Pin = 'pin',
  FmcuAction = 'fmcu_action',
  StepsListWorkflow = 'steps_list_workflow',
  CashPayment = 'cash_payment',
  CashPaymentConfirmation = 'cash_payment_confirmation',
  CashPaymentCancellation = 'cash_payment_cancellation',
  CashRefund = 'cash_refund',
  CashRefundConfirmation = 'cash_refund_confirmation',
  RfidCard = 'rfid_card',
  ReceiptSwitcher = 'receipt_switcher',
  Pdf = 'pdf',
  Image = 'image',
}
