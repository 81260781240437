import { ILog } from '../services/logging/log.interface';

export class ExtendedLog implements ILog {
  private log: ILog;
  name: string;

  constructor(name: string, log: ILog) {
    this.log = log;
    this.name = name;
  }

  private extendMessage(message: any): string {
    return `${this.name}. ${message}`;
  }

  info(message: any = ''): void {
    this.log.info(this.extendMessage(message));
  }

  warning(message: any = ''): void {
    this.log.warning(this.extendMessage(message));
  }

  error(message: any = ''): void {
    this.log.error(this.extendMessage(message));
  }

  debug(message: any = ''): void {
    this.log.debug(this.extendMessage(message));
  }
}
