import { Component, OnInit, OnDestroy } from '@angular/core';
import { DispatcherService } from '../../../services/dispatcher.service';
import { LiteDisplayService } from '../../../services/lite/lite-display.service';
import { Money, CreditCard } from '../../../lib/lib';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { AdditionalPropertiesConfigurationService } from '../../../services/configuration/additional-properties-configuration.service';
import { Subscription } from 'rxjs';
import { StepConfiguration } from '../../../lib/lite/step-configuration';
import { StepConfigurationType } from '../../../lib/lite/step-configuration-type';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent implements OnInit, OnDestroy {

  private backButtonVisible: boolean;
  private headerVisible: boolean;
  private subcription: Subscription;


  constructor(
    private dispatcherService: DispatcherService,
    private liteDisplayService: LiteDisplayService,
    private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService,
    private vuCommunicationService: VuCommunicationService,
  ) {
  }

  ngOnInit() {
    this.backButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = false;

    this.headerVisible = this.dispatcherService.isHeaderVisible;
    this.dispatcherService.isHeaderVisible = false;

    this.subcription = this.vuCommunicationService.vuConnection.eventVuConfigurationChanged.subscribe(() => { this.reloadColors() });

    this.reloadColors();
  }

  ngOnDestroy() {
    this.dispatcherService.isBackButtonVisible = this.backButtonVisible;
    this.dispatcherService.isHeaderVisible = this.headerVisible;
    this.subcription.unsubscribe();
  }

  get coins(): Money[] {
    return this.liteDisplayService.acceptedCoins;
  }

  get cards(): CreditCard[] {
    return this.liteDisplayService.acceptedCards;
  }

  onCoinsClick() {
    this.liteDisplayService.onCoinsClick();
  }

  onCardsClick() {
    this.liteDisplayService.onCardsClick();
  }

  onLeftClick() {
    this.liteDisplayService.leftButtonClick();
  }

  onRightClick() {
    this.liteDisplayService.rightButtonClick();
  }

  get showLeftButton(): boolean {
    return this.liteDisplayService.showLeftButton();
  }

  get showRightButton(): boolean {
    return this.liteDisplayService.showRightButton();
  }

  reloadColors() {
    document.documentElement.style.setProperty('--color_default', this.additionalPropertiesConfigurationService.colorDefault);
    document.documentElement.style.setProperty('--color_active', this.additionalPropertiesConfigurationService.colorActive);
    document.documentElement.style.setProperty('--color_disabled', this.additionalPropertiesConfigurationService.colorDisabled);
    document.documentElement.style.setProperty('--color_success', this.additionalPropertiesConfigurationService.colorSuccess);
    document.documentElement.style.setProperty('--color_error', this.additionalPropertiesConfigurationService.colorError);
  }

  get isCoinsVisible(): boolean {
    return this.liteDisplayService.isCoinsVisible;
  }

  get isCardsVisible(): boolean {
    return this.liteDisplayService.isCardsVisible;
  }

  onSwipeLeft() {
    if (this.showLeftButton)
      this.onLeftClick();
  }

  onSwipeRight() {
    if (this.showRightButton)
      this.onRightClick();
  }

  onTileClick(step: StepConfiguration) {
    if (!step || step.disabled)
      return;

    if (step.type === StepConfigurationType.Coins)
      this.liteDisplayService.onCoinsClick();

    if (step.type === StepConfigurationType.Card)
      this.liteDisplayService.onCardsClick();
  }
}
