import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { VisualItem } from '../../../lib/lib';
import { DispatcherService } from '../../../services/dispatcher.service';

@Component({
  moduleId: module.id,
  selector: 'visual-item-info',
  templateUrl: 'visual-item-info.component.html',
  styleUrls: ['visual-item-info.component.scss']
})
export class VisualItemInfoComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  private subscriptionVisualItem: Subscription;
  imageSrc: string;

  constructor(
    private dispatcherService: DispatcherService,
    protected store: Store<string>,
  ) {
    this.dispatcherService.isBackButtonVisible = true;
  }

  ngOnInit(): void {
    this.subscription = this.dispatcherService.onBackButtonClickSubscribe(() => this.onBackButtonClick());
    this.subscriptionVisualItem = this.store.select('visualItem')
      .subscribe((x: VisualItem) => this.onVisualItemChanged(x));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionVisualItem.unsubscribe();
    this.dispatcherService.pageHeaderSet();
  }

  private onVisualItemChanged(visualItem: VisualItem) {
    try {
      this.dispatcherService.pageHeaderSet(visualItem.name);
      this.imageSrc = visualItem.data.imageSrc;

    } catch (e) {
      this.imageSrc = '';
    }
  };

  private onBackButtonClick() {
    this.dispatcherService.onBackButtonVisualItemInfoClicked();
  }
}

