import { Subject } from 'rxjs';

export abstract class BaseModalComponent {

  data: any;
  eventClose: Subject<any> = new Subject();

  close(data: any) {
    this.eventClose.next(data);
  }

  protected get internalData() {
    if (!this.data)
      this.data = {};
    return this.data;
  }
}
