import { LogSimulator } from '../services/logging/log.simulator';
import { ILog } from '../services/logging/log.interface';

let currentLocale = 'en-US';

let translateFunc: (x: string) => string;
let log: ILog;
let logSimulator: ILog;

export class GlobalSettings {
  static getCultureName(): string {
    return 'de-DE';
  }

  static getCurrencyCode(): string {
    return 'EUR';
  }

  static numberRound(value: number, pointsNumber: number = 2): number {
    return Number((value).toFixed(pointsNumber));
  }

  static numberFormat(value: number, pointsNumber: number = 2): string {
    return value.toFixed(pointsNumber);
  }

  static moneyToString(value: number): string {
    return new Intl.NumberFormat(GlobalSettings.getCultureName(),
      { style: 'currency', currency: GlobalSettings.getCurrencyCode() }).format(value);
  }

  static getCurrentLocale(): string {
    return currentLocale;
  }

  static setCurrentLocale(value: string) {
    currentLocale = value;
  }

  static setTranslateFunc(func: (x: string) => string) {
    translateFunc = func;
  }

  static translate(x: string): string {
    if (!x || !translateFunc) {
      return x;
    }
    return translateFunc(x);
  }

  static setLog(realLog: ILog) {
    log = realLog;
  }

  static getLog(): ILog {
    if (log) {
      return log;
    }

    if (!logSimulator) {
      logSimulator = new LogSimulator();
    }

    return logSimulator;
  }

  static getIsoDateStrByDate(date: Date): string {
    if (!date) {
      return null;
    }

    return this.getIsoDateStr(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  static getIsoDateStr(year: number, month: number, day: number): string {
    return year + '-' + this._pad(month) + '-' + this._pad(day);
  }

  static _pad(n) {
    return n < 10 ? '0' + n : n;
  }
}
