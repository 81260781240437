import { Injectable, EventEmitter, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { CookieService } from 'ngx-cookie';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from './ui.service';
import { ThemeService } from './theme.service';
import {
  VisualItem,
  VuState,
  ShopState,
  Workflow,
  WorkflowStep,
  WorkflowStepState,
  MachineInactivity,
  Teaser,
  TeaserType,
  Ticket,
  TicketUse,
  TicketUseInfo,
  Configuration,
  VuRole,
  CashDevicesState,
  PrintTaskType,
  MessageView,
  Money,
  WorkflowStepType,
} from '../lib/lib';

import {
  VuActionType,
  ShopActionType,
  PAGE_HEADER_SET,
  VISUAL_ITEM_SELECTED,
  SIMULATOR_IS_TEST_RUNNING,
  SimulatorState,
  WorkflowActionType,
  BackButtonActionType,
  TeaserActionType,
  TicketActionType,
  TicketUseInfoActionType,
  ConfigurationActionType,
  CashDevicesStateActionType,
  MessageViewActionType,
  PAGE_HEADER_VISIBLE,
  PAGE_FOOTER_VISIBLE,
  AbortButtonActionType,
} from '../reducers/reducers';
import { IVuConnection } from './vu/connection/vu-connection.interfaces';
import { IVuHttp } from './vu/http/vu-http.interface';
import { MessageService, MessageType, Message } from './message.service';
import { VuCommunicationService } from './vu/vu-communication.service';
import { BarcodeInputMethod } from '../lib/barcode-reader/barcode-input-method';
import { CreditCardTerminalSimulatorService } from './credit-card-terminal-simulator.service';
import { CreditCardTerminalState } from '../lib/credit-card/credit-card-terminal-state';
import { MachineSaleCreditCardService } from './machines/machine-sale-credit-card.service';
import { OneLineArticleSaleMode } from '../lib/one-line-article-sale-mode';
import { OpenGateTypeEnum } from '../lib/gate/open-gate-type.enum';

@Injectable()
export class DispatcherService {
  private internalVuConnection: IVuConnection;
  private internalVuHttp: IVuHttp;
  subscription: Subscription;
  eventServiceInitialized: EventEmitter<any> = new EventEmitter(true);
  eventMachineInactivityChangeTracking: EventEmitter<MachineInactivity> = new EventEmitter(false);
  eventUserActivity: EventEmitter<any> = new EventEmitter(true);
  private internalCreditCardTerminalSimulatorService: CreditCardTerminalSimulatorService;
  private machineSaleCreditCardService: MachineSaleCreditCardService;

  barcode: string;
  constructor(
    private store: Store<string>,
    private messageService: MessageService,
    private router: Router,
    private cookieService: CookieService,
    private translateService: TranslateService,
    private uiService: UiService,
    private themeService: ThemeService,
    private injector: Injector,
  ) {
    const scope = this;
    setTimeout(() => {
      const vuCommunicationService = scope.injector.get(VuCommunicationService);
      scope.internalVuConnection = vuCommunicationService.vuConnection;
      scope.internalVuConnection.eventVuStateChanged.subscribe((x: VuState) => scope.vuStateSet(x));
      scope.internalVuHttp = vuCommunicationService.vuHttp;
      scope.internalCreditCardTerminalSimulatorService = scope.injector.get(CreditCardTerminalSimulatorService);
      scope.machineSaleCreditCardService = scope.injector.get(MachineSaleCreditCardService);

      this.eventServiceInitialized.emit();
    }, 1);
  }

  get vuHttp(): IVuHttp {
    return this.internalVuHttp;
  }

  get vuConnection(): IVuConnection {
    return this.internalVuConnection;
  }

  pageHeaderSet(header = '') {
    this.pageHeaderLinesSet([header]);
  }

  pageHeaderLinesSet(headerLines = ['']) {
    this.dispatch(PAGE_HEADER_SET, headerLines);
  }

  set isHeaderVisible(x: boolean) {
    this.dispatch(PAGE_HEADER_VISIBLE, x);
  }

  get isHeaderVisible() {
    return this.uiService.headerState.visible;
  }

  onBackButtonClick() {
    this.messageService.sendMessage(new Message(MessageType.ButtonBackClicked));
  }

  onAbortButtonClick() {
    this.sendMessage(MessageType.ButtonAbortClicked);
  }

  set isAbortButtonVisible(x: boolean) {
    this.dispatch(AbortButtonActionType.ABORT_BUTTON_VISIBLE, x);
  }

  get isAbortButtonVisible() {
    return this.uiService.abortButtonState.visible;
  }

  set isAbortButtonEnabled(x: boolean) {
    this.dispatch(AbortButtonActionType.ABORT_BUTTON_ENABLED, x);
  }

  get isAbortButtonEnabled() {
    return this.uiService.abortButtonState.enabled;
  }

  set isBackButtonVisible(x: boolean) {
    this.dispatch(BackButtonActionType.BACK_BUTTON_VISIBLE, x);
  }

  get isBackButtonVisible() {
    return this.uiService.backButtonState.visible;
  }

  set isBackButtonEnabled(x: boolean) {
    this.dispatch(BackButtonActionType.BACK_BUTTON_ENABLED, x);
  }

  get isBackButtonEnabled() {
    return this.uiService.backButtonState.enabled;
  }

  set isFooterVisible(x: boolean) {
    this.dispatch(PAGE_FOOTER_VISIBLE, x);
  }

  get isFooterVisible() {
    return this.uiService.footerState.visible;
  }

  backButtonTextSet(x: string) {
    this.dispatch(BackButtonActionType.BACK_BUTTON_TEXT_SET, x);
  }

  backButtonTextReset() {
    this.dispatch(BackButtonActionType.BACK_BUTTON_TEXT_RESET);
  }

  back() {
    this.sendMessage(MessageType.Back);
  }

  onBackButtonVisualItemInfoClicked() {
    this.sendMessage(MessageType.BackButtonVisualItemInfoClicked);
  }

  onButtonSaleShopClick() {
    this.sendMessage(MessageType.ButtonSaleShopClicked);
  }

  onButtonInfoClick() {
    this.sendMessage(MessageType.ButtonInfoClicked);
  }

  onButtonTicketClick() {
    this.sendMessage(MessageType.ButtonTicketClicked);
  }

  onButtonTicketActivationClick() {
    this.sendMessage(MessageType.TicketActivation);
  }

  vuStateMaintenanceMode(x: boolean) {
    this.dispatch(VuActionType.VU_STATE_MAINTENANCE_MODE, x);
  }

  vuStateOfflineMode(x: boolean) {
    this.dispatch(VuActionType.VU_STATE_OFFLINE_MODE, x);
  }

  vuStateServiceMode(x: boolean) {
    this.dispatch(VuActionType.VU_STATE_SERVICE_MODE, x);
  }

  vuStateBurglaryMode(x: boolean) {
    this.dispatch(VuActionType.VU_STATE_BURGLARY_MODE, x);
  }

  vuStateSet(x: VuState) {
    this.dispatch(VuActionType.VU_STATE_ALL, x);
  }

  onVuStateChangedSubscribe(f: (x: VuState) => void): Subscription {
    return this.store.select<VuState>('vuState').subscribe(f);
  }

  cashDevicesStatePayIn(x: boolean) {
    this.dispatch(CashDevicesStateActionType.CASH_DEVICES_STATE_PAY_IN, x);
  }

  cashDevicesStatePayOut(x: boolean) {
    this.dispatch(CashDevicesStateActionType.CASH_DEVICES_STATE_PAY_OUT, x);
  }

  onCashDevicesStateSubscribe(f: (x: CashDevicesState) => void): Subscription {
    return this.store.select<CashDevicesState>('cashDevicesState').subscribe(f);
  }

  messageViewUpdate(x: MessageView) {
    this.dispatch(MessageViewActionType.MESSAGE_VIEW_UPDATE, x);
  }

  messageViewText(x: string) {
    this.dispatch(MessageViewActionType.MESSAGE_VIEW_TEXT, x);
  }

  onMessageViewSubscribe(f: (x: MessageView) => void): Subscription {
    return this.store.select<MessageView>('messageView').subscribe(f);
  }

  onTicketChangedSubscribe(f: (x: Ticket) => void): Subscription {
    return this.store.select<Ticket>('ticket').subscribe(f);
  }

  ticketReset() {
    this.dispatch(TicketActionType.TICKET_RESET);
  }

  ticketUpdate(x: Ticket) {
    this.dispatch(TicketActionType.TICKET_UPDATE, x);
  }

  ticketOvertimePayment() {
    this.sendMessage(MessageType.TicketOvertimePayment);
  }

  ticketUse(x: Ticket) {
    this.sendMessage(MessageType.TicketUse, x);
  }

  ticketUseInfoTicket(x: Ticket) {
    this.dispatch(TicketUseInfoActionType.TICKET_USE_INFO_TICKET, x);
  }

  ticketUseInfoUse(x: TicketUse[]) {
    this.dispatch(TicketUseInfoActionType.TICKET_USE_INFO_USE, x);
  }

  ticketReturnAmount(isDisplayMode: boolean) {
    this.sendMessage(MessageType.TicketReturnAmount, isDisplayMode);
  }

  onTicketUseInfoSubscribe(f: (x: TicketUseInfo) => void): Subscription {
    return this.store.select<TicketUseInfo>('ticketUseInfo').subscribe(f);
  }

  configurationUpdate(x: Configuration) {
    this.dispatch(ConfigurationActionType.CONFIGURATION_UPDATE, x);
  }

  configurationVuRole(x: VuRole) {
    this.dispatch(ConfigurationActionType.CONFIGURATION_VU_ROLE, x);
  }

  configurationLocale(x: string) {
    this.dispatch(ConfigurationActionType.CONFIGURATION_LOCALE, x);
  }

  configurationShowArticlesOnMainPage(x: boolean) {
    this.dispatch(ConfigurationActionType.CONFIGURATION_SHOW_ARTICLES_ON_MAIN_PAGE, x);
  }

  configurationPrintCardTerminalReceipt(x: string) {
    this.dispatch(ConfigurationActionType.CONFIGURATION_PRINT_CARD_TERMINAL_RECEIPT, x);
  }

  configurationPrintOrderReceipt(x: string) {
    this.dispatch(ConfigurationActionType.CONFIGURATION_PRINT_ORDER_RECEIPT, x);
  }

  configurationOneLineArticleSaleMode(x: OneLineArticleSaleMode) {
    this.dispatch(ConfigurationActionType.CONFIGURATION_ONE_LINE_ARTICLE_SALE_MODE, x);
  }

  configurationBarcodeInputMethod(x: BarcodeInputMethod) {
    this.dispatch(ConfigurationActionType.CONFIGURATION_BARCODE_INPUT_METHOD, x);
  }

  configurationTicketPrinterType(x: string) {
    this.dispatch(ConfigurationActionType.CONFIGURATION_TICKET_PRINTER_TYPE, x);
  }

  configurationReceiptPrinterType(x: string) {
    this.dispatch(ConfigurationActionType.CONFIGURATION_RECEIPT_PRINTER_TYPE, x);
  }

  configurationAdditionalProperties(x: any) {
    this.dispatch(ConfigurationActionType.CONFIGURATION_ADDITIONAL_PROPERTIES, x);
  }

  configurationCustomCss(x: string) {
    this.dispatch(ConfigurationActionType.CONFIGURATION_CUSTOM_CSS, x);
  }

  configurationCustomLogoId(x: number) {
    this.dispatch(ConfigurationActionType.CONFIGURATION_CUSTOM_LOGO_ID, x);
  }

  configurationBackgorundId(x: number) {
    this.dispatch(ConfigurationActionType.CONFIGURATION_BACKGROUND_ID, x);
  }

  onConfigurationChangedSubscribe(f: (x: Configuration) => void): Subscription {
    return this.store.select<Configuration>('configuration').subscribe(f);
  }

  shopStateCanPayCash(x: boolean) {
    this.dispatch(ShopActionType.SHOP_CAN_PAY_CASH, x);
  }

  shopStateCanPayCard(x: boolean) {
    this.dispatch(ShopActionType.SHOP_CAN_PAY_CARD, x);
  }

  shopStateOrderUid(x: string) {
    this.dispatch(ShopActionType.SHOP_ORDER_UID, x);
  }

  shopStateIsSavingOrder(x: boolean) {
    this.dispatch(ShopActionType.SHOP_IS_ORDER_SAVING, x);
  }

  shopStatePrinting(type: PrintTaskType, x: boolean) {
    const shopActionType = type === PrintTaskType.Ticket ?
      ShopActionType.SHOP_IS_TICKET_PRINTING : ShopActionType.SHOP_IS_RECEIPT_PRINTING;
    this.dispatch(shopActionType, x);
  }

  onShopStateChangedSubscribe(f: (x: ShopState) => void): Subscription {
    return this.store.select<ShopState>('shopState').subscribe(f);
  }

  onSimulatorStateChangedSubscribe(f: (x: SimulatorState) => void): Subscription {
    return this.store.select<SimulatorState>('simulatorState').subscribe(f);
  }

  onWorkflowStateChangedSubscribe(f: (x: Workflow) => void): Subscription {
    return this.store.select<Workflow>('workflow').subscribe(f);
  }

  workflowReset(name: string, stepType: WorkflowStepType, ...args: string[]) {
    this.dispatch(WorkflowActionType.WORKFLOW_RESET, new Workflow(name, stepType, ...args));
  }

  workflowAddStep(stepType: WorkflowStepType, ...args: string[]) {
    this.dispatch(WorkflowActionType.WORKFLOW_ADD_STEP, new WorkflowStep(stepType, ...args));
  }

  workflowDeleteLastStep() {
    this.dispatch(WorkflowActionType.WORKFLOW_DELETE_LAST_STEP);
  }

  workflowLastStepStateSet(state: WorkflowStepState = WorkflowStepState.CompleteSuccess) {
    this.dispatch(WorkflowActionType.WORKFLOW_LAST_STEP_STATE_SET, state);
  }

  workflowLastStepUpdate(...args: string[]) {
    this.dispatch(WorkflowActionType.WORKFLOW_LAST_STEP_UPDATE, args);
  }

  onTeaserChangedSubscribe(f: (x: Teaser) => void): Subscription {
    return this.store.select<Teaser>('teaser').subscribe(f);
  }

  teaserReset() {
    this.dispatch(TeaserActionType.TEASER_RESET);
  }

  teaserAddItem(x: TeaserType) {
    this.dispatch(TeaserActionType.TEASER_ADD_ITEM, x);
  }

  teaserDeleteItem(x: TeaserType) {
    this.dispatch(TeaserActionType.TEASER_DELETE_ITEM, x);
  }

  onUserActivity() {
    this.eventUserActivity.emit();
  }

  machineHardResetRoot() {
    this.sendMessage(MessageType.MachineHardResetRoot);
  }

  machineTimeoutModalCancel(machineName: string) {
    this.sendMessage(MessageType.MachineTimeoutModalCancel, machineName);
  }

  simulatorIsTestRunningSet(x: boolean) {
    this.dispatch(SIMULATOR_IS_TEST_RUNNING, x);
  }

  visualItemsInfoSet(x: VisualItem) {
    this.dispatch(VISUAL_ITEM_SELECTED, x);
  }

  toPaymentCash() {
    this.sendMessage(MessageType.ToPaymentCash);
  }

  toPaymentCard() {
    this.sendMessage(MessageType.ToPaymentCard);
  }

  gateOpen(barcode: string, baseUrl: string = '', openGateType: string = OpenGateTypeEnum.Immediately, timeout: number = 0) {
    this.sendMessage(MessageType.OpenGate, { barcode, baseUrl, openGateType, timeout});
  }

  gateOpenComplite(entered: boolean) {
    this.sendMessage(MessageType.OpenGateComplete, entered);
  }

  gateOpenStop(barcode: string, baseUrl: string = '') {
    this.sendMessage(MessageType.OpenGateStop, { barcode, baseUrl });
  }

  onGateOpenComplite(f: (x: Message) => void): Subscription {
    return this.subscribe(f, MessageType.OpenGateComplete);
  }

  toBasket() {
    this.sendMessage(MessageType.ToBasket);
  }

  toDisplayModePayment(abortPayment: boolean) {
    this.sendMessage(MessageType.ToDisplayModePayment, abortPayment);
  }

  displayModePaymentComplete(complite: boolean) {
    this.sendMessage(MessageType.DisplayModePaymentComplete, complite);
  }

  onDisplayModePaymentComplete(f: (x: Message) => void): Subscription {
    return this.subscribe(f, MessageType.DisplayModePaymentComplete);
  }

  displayModeRefundComplete() {
    this.sendMessage(MessageType.DisplayModeRefundComplete);
  }

  onDisplayModeRefundComplete(f: (x: Message) => void): Subscription {
    return this.subscribe(f, MessageType.DisplayModeRefundComplete);
  }

  onBackButtonClickSubscribe(f: (x: Message) => void): Subscription {
    return this.subscribe(f, MessageType.ButtonBackClicked);
  }

  onVisualItemLeafSelectedSubscribe(f: (x: Message) => void): Subscription {
    return this.subscribe(f, MessageType.VisualItemLeafSelected);
  }

  machineInactivityChangeTracking(machineInactivity: MachineInactivity) {
    this.eventMachineInactivityChangeTracking.emit(machineInactivity);
  }

  get creditCardTerminalSimulatorService(): CreditCardTerminalSimulatorService {
    return this.internalCreditCardTerminalSimulatorService;
  }

  get creditCardTerminalState(): CreditCardTerminalState {
    return this.creditCardTerminalSimulatorService.creditCardTerminalState;
  }

  get machineSaleCreditCardState(): string {
    return this.machineSaleCreditCardService ? this.machineSaleCreditCardService.state : '?';
  }

  languageChanged() {
    this.sendMessage(MessageType.LanguageChanged);
  }

  private sendMessage(messageType: MessageType, info: any = null) {
    this.messageService.sendMessage(new Message(messageType, info));
  }

  private dispatch(type: any, payload: any = null) {
    this.store.dispatch({ type, payload });
  }

  private subscribe(
    f: (x: Message) => void,
    ...messageTypes: MessageType[]
  ): Subscription {
    return this.messageService
      .getSubscription()
      .filter((x: Message) => messageTypes.indexOf(x.messageType) > -1)
      .subscribe(f);
  }
}
