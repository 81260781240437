import { Money } from '../lib';

export class AcceptedCash {
  coins: Money[];
  banknotes: Money[];

  constructor(coins: Money[], banknotes: Money[]) {
    this.coins = coins;
    this.banknotes = banknotes;
  }

  moneyToString(money: Money[]): string {
    let result = '';
    if (money == null) {
      return result;
    }

    for (const m of money) {
      result += `${m}; `;
    }
    return result;
  }

  toString() {
    return `coins: '${this.moneyToString(this.coins)}'. banknotes: '${this.moneyToString(this.banknotes)}'`;
  }
}
