import { MachineBaseService } from './machine-base.service';
import {
  PaymentSession,
  MachineInactivitySettings,
  PaymentMethod
} from '../../lib/lib';
import { SaleService } from '../sale.service';
import { MessageType } from '../message.service';
import { CreditCardMessageStore } from '../../lib/credit-card/credit-card-message-store';
import { Subject } from 'rxjs';
import { CreditCardTerminalEvent } from '../../lib/credit-card/credit-card-terminal-event';
import { CreditCardTerminalEventType } from '../../lib/credit-card/credit-card-terminal-event-type';

export enum CreditCardTransactionResult {
  Canceled,
  PaymentAborted,
  PaymentCompleted
}

export class MachineSaleCreditCardService extends MachineBaseService {
  private saleService: SaleService;
  private creditCardMessageStore: CreditCardMessageStore;
  eventCreditCardTransactionEnd = new Subject<CreditCardTransactionResult>();
  private lastBackButtonVisibleState: boolean;

  init(): void {
    this.saleService = this.injector.get(SaleService);
    this.creditCardMessageStore = this.saleService.creditCardMessageStore;
    if (!this.creditCardMessageStore) {
      this.log.error(
        'this.saleService.creditCardPaymentMessage must be initialized!'
      );
    }

    this.vuConnection.eventCreditCardTerminalEvent.subscribe(
      (x: CreditCardTerminalEvent) => this.onEventCreditCardTerminalEvent(x)
    );
    super.init();
  }

  get machineName(): string {
    return 'Credit Card Sale Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      {
        name: 'toTransactionBegin',
        from: ['*'],
        to: 'transactionBegin'
      },
      {
        name: 'toWaitForCard',
        from: ['*'],
        to: 'waitForCard'
      },
      {
        name: 'toCanceling',
        from: ['*'],
        to: 'canceling'
      },
      {
        name: 'toCanceled',
        from: ['*'],
        to: 'canceled'
      },
      {
        name: 'toPaymentInProgress',
        from: ['*'],
        to: 'paymentInProgess'
      },
      {
        name: 'toPaymentAborted',
        from: ['*'],
        to: 'paymentAborted'
      },
      {
        name: 'toPaymentCompleted',
        from: ['*'],
        to: 'paymentCompleted'
      },
      {
        name: 'toTransactionEnd',
        from: ['*'],
        to: 'transactionEnd'
      },
      {
        name: 'toOff',
        from: ['*'],
        to: 'off'
      }
    );
  }

  protected getMethods(): any {
    const scope = this;
    return super.getMethods({
      onToOff(event: any, isHardReset: false) {
      },
      onToTransactionBegin() {
        scope.sessionOpenPaymentSessionCard();
        scope.creditCardMessageStore.reset();
        scope.router.navigateByUrl('/payment-card');
      },
      onToWaitForCard() {
        scope.setMessage('Wait', true);
      },
      onToPaymentInProgress() {
        scope.setMessage('Follow the instructions on the card reader');
      },
      onToCanceling() {
        scope.setMessage('Wait', false);
        scope.saleService.remoteTransaction.closeRemoteTransaction(false, 'CreditCardTerminal. onToCanceling');
        setTimeout(() => scope.machine.toCanceled(), 1000);
      },
      onToCanceled() {
        scope.doAsync(() => scope.machine.toTransactionEnd(CreditCardTransactionResult.Canceled));
      },
      onToPaymentCompleted() {
      },
      onToPaymentAborted() {
      },
      onToTransactionEnd(
        t: any,
        transactionResult: CreditCardTransactionResult
      ) {
        if (transactionResult === CreditCardTransactionResult.PaymentCompleted) {
          scope.sessionRegisterCompleteAmount();
        } else {
          scope.saleService.remoteTransaction.closeRemoteTransaction(false, 'CreditCardTerminal. onToPaymentAborted');
        }
        scope.eventCreditCardTransactionEnd.next(transactionResult);
      }
    });
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(MessageType.ButtonBackClicked);
  }

  machineStart() {
    this.machine.toTransactionBegin();
  }

  protected get timeoutTrackingStates(): string[] {
    return this.getsAllStatesExceptFor('off');
  }

  protected getMachineInactivitySettings(
    state: string
  ): MachineInactivitySettings {
    return new MachineInactivitySettings(180000);
  }

  protected onMachineTimeoutModalCancel(machineName: string) {
    this.saleService.remoteTransaction.closeRemoteTransaction(false, 'CreditCardTerminal. onMachineTimeoutModalCancel');
    super.onMachineTimeoutModalCancel(machineName);
  }

  onTerminalTextChanged(terminalText: string) {
    if (terminalText && terminalText.length > 0) {
      this.setMessage(terminalText, true);
    }
  }

  private onEventCreditCardTerminalEvent(x: CreditCardTerminalEvent) {
    this.log.info(`onEventCreditCardTerminalEvent. ${x}`);
    if (this.state === 'off') {
      this.log.info(`onEventCreditCardTerminalEvent. The machine is off. Ignore the event.`);
      return;
    }

    switch (x.eventType) {
      case CreditCardTerminalEventType.CardInserted:
        this.onCardInserted();
        break;
      case CreditCardTerminalEventType.CardRemoved:
        this.onCardRemoved();
        break;
      case CreditCardTerminalEventType.TerminalTextChanged:
        this.onTerminalTextChanged(x.eventInfo);
        break;
      case CreditCardTerminalEventType.PaymentCompleted:
        this.onPaymentCompleted();
        break;
      case CreditCardTerminalEventType.PaymentAborted:
        this.onPaymentAborted();
        break;
      case CreditCardTerminalEventType.FundsReservationIsCompleted:
        this.log.info('FundsReservationIsCompleted');
        this.saleService.remoteTransaction.closeRemoteTransaction(true, 'CreditCardTerminal. onToPaymentCompleted');
        break;
      case CreditCardTerminalEventType.ReceiptReceived:
        this.log.info('ReceiptReceived');
        this.onReceiptReceived(x.eventInfo);
        break;
      default:
        this.log.error(`This CreditCardTerminalEvent not supported: ${x}`);
        break;
    }
  }

  private onCardInserted() {
    this.log.info('onCardInserted');
    this.machine.toPaymentInProgress();
  }

  private onCardRemoved() {
    this.log.info('onCardRemoved');
  }

  private onPaymentCompleted() {
    this.log.info('onPaymentCompleted');
    this.machine.toTransactionEnd(
      CreditCardTransactionResult.PaymentCompleted
    );
  }

  private onPaymentAborted() {
    this.log.info('onPaymentAborted');
    this.machine.toTransactionEnd(
      CreditCardTransactionResult.PaymentAborted
    );
  }

  private onReceiptReceived(receipt: string) {
    this.doActionSafeOnPaymentSession((s: PaymentSession) => s.cardTerminalReceipt = receipt, 'onReceiptReceived');
    this.saleService.remoteTransaction.appendTransactionInfo(receipt);
  }

  switchOff(callback: any) {
    const scope = this;
    this.doAsync(() => {
      scope.machine.toOff();
      callback();
    });
  }

  private setMessage(x: string, highlight = false) {
    this.creditCardMessageStore.message = x;
    this.creditCardMessageStore.higlightMessage = highlight;
  }

  private sessionOpenPaymentSessionCard() {
    this.saleService.openPaymentSession(PaymentMethod.PaymentCard, this.saleService.order.amountTotal);
  }

  private sessionRegisterCompleteAmount() {
    this.doActionSafeOnPaymentSession((s: PaymentSession) => s.registerCompleteAmountToPay(), 'sessionRegisterCompleteAmount');
  }

  private doActionSafeOnPaymentSession(action: (s: PaymentSession) => void, callContext: string) {
    const f = (message: string) => {
      this.log.error(`doPaymentSession. '${message}'. callContext: '${callContext}'`);
    };

    if (this.state === 'off') {
      f(`Cannot do this action when the state is 'off'`);
      return;
    }

    const paymentSession = this.saleService.paymentSession;
    if (paymentSession == null) {
      f(`paymentSession not exist`);
      return;
    }

    action(paymentSession);
  }
}
