import { BaseButton } from './base-button';

export class BackButton extends BaseButton {

  static fromOther(other: BackButton): BackButton {
    return Object.assign(new BackButton(), other);
  }

  resetText() {
    this.text = 'Back';
  }
}
