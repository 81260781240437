import { Action } from '@ngrx/store';
import { CashDevicesState } from '../lib/lib';
import { PayloadAction } from '../lib/payload-action';

const initialState = new CashDevicesState();

export class CashDevicesStateActionType {
  static readonly CASH_DEVICES_STATE_PAY_IN = 'CashDevicesStatePayIn';
  static readonly CASH_DEVICES_STATE_PAY_OUT = 'CashDevicesStatePayOut';
}

export function cashDevicesStateReducer(state = initialState, action: PayloadAction) {
  let isChanged = true;
  switch (action.type) {
    case CashDevicesStateActionType.CASH_DEVICES_STATE_PAY_IN:
      state.isCashPayIn = action.payload;
      break;
    case CashDevicesStateActionType.CASH_DEVICES_STATE_PAY_OUT:
      state.isCashPayOut = action.payload;
      break;
    default:
      isChanged = false;
      break;
  }
  return isChanged ? CashDevicesState.fromOther(state) : state;
}
