import { Component, OnInit, Input } from '@angular/core';
import { DisplayItem } from '../../../lib/display/display-item/display-item';
import { AssetsService } from '../../../services/assets.service';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'app-grid-visual-item-image-viewer',
  templateUrl: './grid-visual-item-image-viewer.component.html',
  styleUrls: ['./grid-visual-item-image-viewer.component.css']
})
export class GridVisualItemImageViewerComponent implements OnInit {

  @Input()
  displayItem: DisplayItem;

  constructor(
    private assetsService: AssetsService,
    private languageService: LanguageService,
  ) {
  }

  ngOnInit() {
  }

  get imageUrl(): string {
    if (this.displayItem) {
      if (this.displayItem.apiUrl) {
        return this.displayItem.apiUrl;
      }

      if (this.displayItem.backgroundId) {
        const attachmentUrl = this.assetsService.getAttachmentUrlById(this.displayItem.backgroundId, this.languageService.getLanguage());
        if (attachmentUrl) {
          return attachmentUrl;
        }
        return this.assetsService.getImageUrlById(this.displayItem.backgroundId);
      }
    }

    return '';
  }
}
