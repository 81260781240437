import { Component, OnInit, Input } from '@angular/core';
import { Money } from '../../../lib/lib';

@Component({
  selector: 'app-coins',
  templateUrl: './coins.component.html',
  styleUrls: ['./coins.component.css']
})
export class CoinsComponent implements OnInit {

  @Input()
  coins: Money[];

  constructor() { }

  ngOnInit() {
  }

  isCoinsAvalible(value: number): boolean {
    if (!this.coins) {
      return false;
    }

    for (let index = 0; index < this.coins.length; index++) {
      if (this.coins[index] && this.coins[index].value == value) {
        return true;
      }
    }

    return false;
  }

}
