import { Component, OnInit, Input, Output, forwardRef, ViewChild, Optional, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel, NG_ASYNC_VALIDATORS, NG_VALIDATORS } from '@angular/forms';
import { ElementBase } from '../base/element-base';
import { DateTimeHelper } from '../../../lib/date-time-helper';

@Component({
  selector: 'app-float-time',
  templateUrl: './float-time.component.html',
  styleUrls: ['./float-time.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FloatTimeComponent),
      multi: true
    }
  ]
})
export class FloatTimeComponent extends ElementBase<number> {

  private _hour: number;
  private _minute: number;

  private _value: number;

  @ViewChild(NgModel, {static: false}) model: NgModel;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }

  get hour(): number {
    return DateTimeHelper.getHour(this.value);
  }

  set hour(value: number) {
    this.value = DateTimeHelper.createFloatTime(value, this.minute);
  }

  get minute(): number {
    return DateTimeHelper.getMinutes(this.value);
  }

  set minute(value: number) {
    this.value = DateTimeHelper.createFloatTime(this.hour, value);
  }

  get hours(): number[] {
    return Array.apply(null, { length: 24 }).map(Number.call, Number);
  }

  get minutes(): number[] {
    return Array.apply(null, { length: 60 }).map(Number.call, Number);
  }
}
