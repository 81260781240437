import { GlobalSettings, Money } from '../lib';
import { TranslatePropertyHelper } from '../languages/translation-properties-helper';
import { TicketStatus } from './ticket-status';

export class Ticket {
  code = '';
  quantity = 0;
  quantityUsed = 0;
  quantityUsedTotal = 0;
  productName = '';
  productId = 0;
  isUnknown = false;
  totalOvertime = 0;
  outstandingOvertime = 0;
  overtimeUnit = 30;
  errorMessage = '';
  canEnter = false;
  canExit = false;
  todayUsed = false;
  createDate = '';
  validUntil = '';
  amount: Money = Money.empty;
  orderId = 0;
  additionalProperties: any = {};
  logisticsRequestId: number = null;
  logisticsRequestLineId: number = null;

  private translatePropertyHelper: TranslatePropertyHelper;

  static fromOther(other: Ticket): Ticket {
    const ticket = new Ticket();
    ticket.code = other.code;
    ticket.quantity = other.quantity;
    ticket.quantityUsed = other.quantityUsed;
    ticket.quantityUsedTotal = other.quantityUsedTotal;
    ticket.productName = other.productName;
    ticket.productId = other.productId;
    ticket.isUnknown = other.isUnknown;
    ticket.totalOvertime = other.totalOvertime;
    ticket.outstandingOvertime = other.outstandingOvertime;
    ticket.overtimeUnit = other.overtimeUnit;
    ticket.errorMessage = other.errorMessage;
    ticket.canEnter = other.canEnter;
    ticket.canExit = other.canExit;
    ticket.todayUsed = other.todayUsed;
    ticket.createDate = other.createDate;
    ticket.validUntil = other.validUntil;
    ticket.amount = other.amount;
    ticket.orderId = other.orderId;
    ticket.additionalProperties = other.additionalProperties;
    ticket.logisticsRequestId = other.logisticsRequestId;
    ticket.logisticsRequestLineId = other.logisticsRequestLineId;
    ticket.translatePropertyHelper = new TranslatePropertyHelper(
      other.additionalProperties ? other.additionalProperties.translations : null);
    return ticket;
  }

  static fromJson(other: any): Ticket {
    const ticket = new Ticket();
    ticket.code = other.code;
    ticket.quantity = other.quantity;
    ticket.quantityUsed = other.quantityUsed;
    ticket.quantityUsedTotal = other.quantityUsedTotal;
    ticket.productName = other.productName;
    ticket.productId = other.productId;
    ticket.isUnknown = other.isUnknown;
    ticket.totalOvertime = other.totalOvertime;
    ticket.outstandingOvertime = other.outstandingOvertime;
    ticket.overtimeUnit = other.overtimeUnit;
    ticket.errorMessage = other.errorMessage;
    ticket.canEnter = other.canEnter;
    ticket.canExit = other.canExit;
    ticket.todayUsed = other.todayUsed;
    ticket.createDate = other.createDate;
    ticket.validUntil = other.validUntil;
    ticket.amount = new Money(other.price, GlobalSettings.getCurrencyCode());
    ticket.orderId = other.orderId;
    ticket.additionalProperties = other.additionalProperties;
    ticket.logisticsRequestId = other.logisticsRequest;
    ticket.logisticsRequestLineId = other.logisticsRequestLine;
    ticket.translatePropertyHelper = new TranslatePropertyHelper(
      other.additionalProperties ? other.additionalProperties.translations : null);
    return ticket;
  }

  get expired(): boolean {
    return this.ValidUntil !== new Date(1973, 1, 1) && this.ValidUntil < new Date();
  }

  get isVirtualTicket(): boolean {
    return this._getBooleanAdditionalProperty('virtual_ticket');
  }

  get isSeasoned(): boolean {
    return this._getBooleanAdditionalProperty('is_seasoned');
  }

  getCreateDate(): Date {
    const date = new Date(this.createDate);
    return Object.prototype.toString.call(date) === '[object Date]' ? date : new Date(1973, 1, 1);
  }

  get ValidUntil(): Date {
    const date = new Date(this.validUntil);
    return Object.prototype.toString.call(date) === '[object Date]' ? date : new Date(1973, 1, 1);
  }

  get ValidUntilString(): string {
    return this.ValidUntil.toLocaleString(GlobalSettings.getCurrentLocale());
  }

  get ValidUntilDateString(): string {
    return this.ValidUntil.toLocaleDateString(GlobalSettings.getCurrentLocale());
  }

  get CanBeRefunded(): boolean {
    return this.amount.isPositive
      && this.productId > 0
      && !this.expired;
  }

  get quantityRemaining(): number {
    return this.quantity - this.quantityUsedTotal;
  }

  get status(): TicketStatus {
    let result: TicketStatus = TicketStatus.Unknown;
    if (this.additionalProperties) {
      const ticketStatus: string = this.additionalProperties.ticket_status;
      if (ticketStatus && ticketStatus !== '') {
        result = TicketStatus[ticketStatus];
      }
    }
    return result;
  }

  private _getNumberAdditionalProperty(name: string, defaultValue = 0): number {
    let value = defaultValue;
    if (this.additionalProperties) {
      value = this.additionalProperties[name];
    }
    if (!value) {
      return defaultValue;
    }

    return Number(value);
  }

  get accessTimeMinutes(): number {
    return this._getNumberAdditionalProperty('access_time_minutes');
  }

  get isAdditionalTicket(): boolean {
    return this._getBooleanAdditionalProperty('is_additional_ticket');
  }

  toString() {
    let m = `isUnknown: ${this.isUnknown}. `;
    m += `productName: ${this.productName}. `;
    m += `code: ${this.code}. `;
    m += `quantity: ${this.quantity}. `;
    m += `quantityUsed: ${this.quantityUsed}. `;
    m += `quantityUsedTotal: ${this.quantityUsedTotal}. `;
    m += `totalOvertime: ${this.totalOvertime}. `;
    m += `outstandingOvertime: ${this.outstandingOvertime}. `;
    m += `overtimeUnit: ${this.overtimeUnit}. `;
    m += `errorMessage: ${this.errorMessage}. `;
    m += `canEnter: ${this.canEnter}. `;
    m += `canExit: ${this.canExit}. `;
    m += `todayUsed: ${this.todayUsed}. `;
    m += `createDate: ${this.createDate}. `;
    m += `validUntil: ${this.validUntil}. `;
    m += `amount: ${this.amount}. `;
    m += `orderId: ${this.orderId}. `;
    m += `status: ${this.status}. `;
    m += `accessTimeMinutes: ${this.accessTimeMinutes}. `;
    return `${m}`;
  }

  private _getBooleanAdditionalProperty(name: string): boolean {
    return this.additionalProperties && this.additionalProperties[name];
  }

  productFormattedName(language: string) {
    return this.translateProductName(language) || this.productName;
  }

  translateProductName(language: string): string {
    return this.translatePropertyHelper.getTranslationByFieldName(language, 'product_name');
  }
}
