export class CredtitCardReceipt {
  static getReceiptPaymentSuccess(): string {
    return `
        K-U-N-D-E-N-B-E-L-E-G


        Wanzl Metallwarenfabrik
             TESTSYSTEM CP
          Rudolf-Wanzl-Str. 4
             89340 Leipheim
         CardProcess Testsystem

           Bezahlung Maestro

     Betrag               10,00 EUR

     01.11.2018               17:21
     Terminal-ID           52001201
     TA-Nr. 000170   Beleg-Nr. 0048
     Kartennr.  ###############7325
     K.folg.Nr                 0000
     Kontaktlos Chip         Online
     VU-Nummer      270001838
     Autorisierungsnummer    880170
     Autorisierungsantwortcode   00

     AS-Proc-Code = 00 076 00
     Capt.-Ref.= 0000
     VU-Nr.= 270001838
     AID59: SM0170
     Antwortcode = 00

     **     Zahlung erfolgt      **
`;
  }
}
