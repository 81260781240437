export class CreditCard {
  name: string;
  imagePath: string;

  static fromJSONObject(jsonObject: any, type: string): CreditCard {
    try {
      return this.fromJSONObjectImpl(jsonObject, type);
    } catch (e) {
      let json: any = null;
      try { json = JSON.stringify(jsonObject); } catch (e) { }
      return null;
    }
  }

  private static fromJSONObjectImpl(jsonObject: any, type: string): CreditCard {
    let item = new CreditCard(jsonObject.name);
    return item;
  }

  static fromJson(json: any): CreditCard {
    return new CreditCard(json.name);
  }

  constructor(name: string) {
    this.name = name;
    this.imagePath = this.getImagePath(name);
  }

  private getImagePath(name: string) {
    let imageFolderPath = "./../../../../static/images/cards/";
    let imagePath;
    switch (name.toUpperCase()) {
      case 'MASTERCARD':
        imagePath = imageFolderPath + "master_card.png";
        break;
      case 'VISA':
        imagePath = imageFolderPath + "visa.png";
        break;
      case 'EC':
        imagePath = imageFolderPath + "ec.png";
        break;
      case 'AMERICANEXPRESS':
        imagePath = imageFolderPath + "american_express.png";
        break;
      case 'VPAY':
        imagePath = imageFolderPath + "v_pay.png";
        break;
      case 'MAESTRO':
        imagePath = imageFolderPath + "maestro.png";
        break;
      case 'GIROCARD':
        imagePath = imageFolderPath + "girocard.png";
        break;
      case 'APPLE':
        imagePath = imageFolderPath + "apple.png";
        break;
      case 'GPAY':
        imagePath = imageFolderPath + "gpay.png";
        break;
      case 'CONTACTLESS':
        imagePath = imageFolderPath + "contactless.png"
        break;
    }
    return imagePath;
  }
}
