import { Injectable } from '@angular/core';
import { CashPaymentBaseGridWorkflowService } from '../cash-payment-base-workflow.service';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';

@Injectable()
export class CashPaymentCancellationGridWorkflowService extends CashPaymentBaseGridWorkflowService {

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.CashPaymentCancellation;
  }

  show(displayItem: DisplayItem, context: any) {
    super.show(displayItem, context);

    this.dispatcherService.toDisplayModePayment(true);
  }

  get hardResetProccessDisplayItem(): DisplayItem {
    if (this.saleService.paymentSession.isFloatingAmount && !this.isPaymentConfirmationAvalible) {
      return this.findDisplayItemByUniqueName('payment_cancellation');
    }
    return null;
  }
}
