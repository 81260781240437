import { GlobalSettings } from '../lib';

export class Money {
  value: number;
  currencyCode: string;

  static toMoneyArray(objects: any[]): Money[] {
    if (!objects) {
      return [];
    }
    return objects.map(x => new Money(x.value, x.currencyCode));
  }

  static fromJSON(json: any) {
    if (json == null) {
      return Money.empty;
    }
    return new Money(json.value, json.currencyCode);
  }

  constructor(value: number, currencyCode: string) {
    this.value = value;
    this.currencyCode = currencyCode;
  }

  static get empty(): Money {
    return new Money(0, '');
  }

  get currencySign(): string {
    switch (this.currencyCode) {
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      case 'UAH':
        return '₴';
      case 'NOK':
        return 'kr';
      case 'ESP':
        return '₧';
      case 'ATS':
        return 'S';
      case 'JPY':
        return '¥';
      case 'GBP':
        return '£';
      case 'CHF':
        return 'Fr';
      case 'SEK':
        return 'kr';
      case 'PLN':
        return 'zł';
    }
    return this.currencyCode;
  }

  get currencyCoinSign(): string {
    switch (this.currencyCode) {
      case 'EUR':
        return 'ct';
      case 'USD':
        return '¢';
      case 'UAH':
        return 'kop';
      case 'NOK':
        return 'kr';
      case 'ESP':
        return '₧';
      case 'ATS':
        return 'gro';
      case 'JPY':
        return '¥';
      case 'GBP':
        return 'p';
      case 'CHF':
        return 'Rp';
      case 'SEK':
        return 'kr';
      case 'PLN':
        return 'gr';
    }
    return this.currencyCode;
  }

  add(other: Money): Money {
    const currencyCode = this.currencyCode ? this.currencyCode : other.currencyCode;
    return new Money(this.value + other.value, currencyCode);
  }

  distract(other: Money): Money {
    return this.add(new Money(-other.value, other.currencyCode));
  }

  get isPositive(): boolean {
    return this.value > 0;
  }

  get isNegative(): boolean {
    return this.value < 0;
  }

  get isZero(): boolean {
    return this.value === 0;
  }

  absolute(): Money {
    return new Money(Math.abs(this.value), this.currencyCode);
  }

  clone(): Money {
    return new Money(this.value, this.currencyCode);
  }

  negate(): Money {
    return new Money(-this.value, this.currencyCode);
  }

  zero(): Money {
    return new Money(0, this.currencyCode);
  }

  toString() {
    const value = this.value.toLocaleString(GlobalSettings.getCurrentLocale(), { minimumFractionDigits: 2 });
    return `${value} ${this.currencyCode}`;
  }

  toStringCurrencySign() {
    const value = this.value.toLocaleString(GlobalSettings.getCurrentLocale(), { minimumFractionDigits: 2 });
    return `${value} ${this.currencySign}`;
  }

  toStringCompact() {
    const value = this.value.toLocaleString(GlobalSettings.getCurrentLocale(), { maximumFractionDigits: 1 });
    return `${value}${this.currencySign}`;
  }

  toStringCompactWithCoin() {
    if (this.value < 1) {
      const value = (this.value * 100).toLocaleString(GlobalSettings.getCurrentLocale(), { maximumFractionDigits: 1 });
      return `${value}${this.currencyCoinSign}`;
    } else {
      const value = this.value.toLocaleString(GlobalSettings.getCurrentLocale(), { maximumFractionDigits: 1 });
      return `${value}${this.currencySign}`;
    }
  }
}
