import { MachineBaseService } from '../machine-base.service';
import { SaleService } from '../../sale.service';
import { MachineLitePostSaleService } from './machine-lite-post-sale.service';
import { Money, Product, MachineInactivitySettings, PaymentMethod } from '../../../lib/lib';
import { MessageType, Message } from '../../message.service';
import { LiteDisplayService } from '../../lite/lite-display.service';
import { LiteTouchTileService } from '../../lite/lite-touch-tile.service';

export class MachineLiteSaleService extends MachineBaseService {

  private isStopRequested = false;
  private saleService: SaleService;
  private machineLitePostSaleService: MachineLitePostSaleService;
  private product: Product;
  private liteDisplayService: LiteDisplayService;
  private liteTouchTileService: LiteTouchTileService;

  init(): void {
    this.saleService = this.injector.get(SaleService);
    this.liteDisplayService = this.injector.get(LiteDisplayService);
    this.machineLitePostSaleService = this.injector.get(MachineLitePostSaleService);
    this.machineLitePostSaleService.init();
    this.machineLitePostSaleService.eventSwitchedOff.subscribe(() => this.onMachinePostSaleSwitchedOff());
    super.init();
    this.vuHttp.getProducts().then(
      (result) => {
        this.product = result[0];
      }
    );
    this.saleService.eventMoneyChanged.subscribe((x: Money) => this.onMoneyChanged(x));
    this.liteDisplayService.eventRightButtonClick.subscribe(() => this.onRightButtonClick());
    this.liteTouchTileService = this.injector.get(LiteTouchTileService);
    this.vuConnection.eventOnInvalidRfidCardInserted.subscribe(() => this.onInvalidRfidCardInserted());
  }

  get machineName(): string {
    return 'Lite Sale Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toIdle', from: ['off', 'paymentCash', 'paymentCard', 'postSale', 'invalidRfidCardInserted'], to: 'idle' },
      { name: 'toPaymentCash', from: ['idle'], to: 'paymentCash' },
      { name: 'toPaymentCard', from: ['idle'], to: 'paymentCard' },
      { name: 'toInvalidRfidCardInserted', from: ['idle'], to: 'invalidRfidCardInserted' },
      { name: 'toPostSale', from: ['idle', 'paymentCash', 'paymentCard'], to: 'postSale' },
    );
  }

  protected getMethods(): any {
    const scope = this;
    return super.getMethods({
      onToOff(event: any, isHardReset = false) {
        scope.saleService.closePaymentSession();
        scope.isStopRequested = false;
        scope.liteDisplayService.stop();
      },
      onToIdle() {
        scope.liteDisplayService.start();
        scope.saleService.resetOrder();
        scope.saleService.order.addProduct(scope.product, 1);
        const amountToPay = scope.saleService.order.amountTotal;
        scope.saleService.openPaymentSession(PaymentMethod.Cash, amountToPay);
        scope.liteDisplayService.reset();
        scope.liteDisplayService.updateAmountToPay(amountToPay);
        scope.liteTouchTileService.standby();
        scope.do(() => scope.router.navigateByUrl('/lite-mode'), 'onToIdle');
      },
      onToPaymentCash() {
        scope.liteDisplayService.activePaymentByCoins();
        scope.liteTouchTileService.coinsPayment();
        scope.log.info(`MachineLiteSaleService. Pay Order: ${scope.saleService.order}`);
      },
      onToPaymentCard() {
        scope.liteDisplayService.activePaymentByCard();
        scope.liteTouchTileService.creditCardPayment();
        scope.log.info(`MachineLiteSaleService. Pay Order: ${scope.saleService.order}`);
      },
      onToInvalidRfidCardInserted() {
        scope.liteDisplayService.rfidAccessDenied();
        scope.liteTouchTileService.rfidAccessDenied();
      },
      onToPostSale() {
        scope.vuHttp.cashDevicesPayIn(false);
        scope.do(() => scope.machineLitePostSaleService.machineStart(), 'onToPostSale');
      },
    });
  }

  machineStart() {
    if (this.isOff) {
      this.machine.toIdle();
    }
  }

  machineStop() {
    if (this.isOff) {
      return;
    }
    this.isStopRequested = true;
    this.machineTryToStop();
  }

  machineStopCancel() {
    this.isStopRequested = false;
  }

  private machineTryToStop() {
    if (this.isStopRequested && !this.isInState('paymentCash', 'postSale')) {
      this.doAsync(() => this.machine.toOff(), 'machineTryToStop');
    }
  }

  protected onAfterTransition(event: any) {
    this.machineTryToStop();
    super.onAfterTransition(event);
  }

  onMachinePostSaleSwitchedOff() {
    if (this.state === 'postSale') {
      this.machine.toIdle();
    }
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(
      MessageType.ButtonBackClicked,
      MessageType.Back,
      MessageType.ToPaymentCash,
      MessageType.ToPaymentCard,
    );
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    const state = this.state;
    switch (message.messageType) {
      case MessageType.Back:
        this.machine.toOff();
        break;
      case MessageType.ButtonBackClicked:
        if (state === 'idle') {
          this.machine.toPaymentCash();
        } else if (state === 'paymentCash') {
          if (this.saleService.order.amountReceived.value === 0) {
            this.machine.toIdle();
          } else {
            this.saleService.paymentSession.isCancelled = true;
            this.machine.toPostSale();
          }
        } else if (state === 'paymentCard') {
          this.machine.toIdle();
        }
        break;
      case MessageType.ToPaymentCash:
        this.machine.toPaymentCash();
        break;
      case MessageType.ToPaymentCard:
        this.machine.toPaymentCard();
        break;
      default:
        break;
    }

    return false;
  }

  protected get timeoutTrackingStates(): string[] {
    return ['paymentCash', 'paymentCard', 'invalidRfidCardInserted'];
  }

  private onMoneyChanged(money: Money) {
    if (this.state === 'postSale') {
      return;
    }

    if (this.state === 'idle') {
      this.machine.toPaymentCash();
    }

    this.dispatcherService.onUserActivity();

    const paymentSession = this.saleService.paymentSession;
    if (!paymentSession) {
      this.log.error('MachineSaleShopService. !paymentSession');
      return;
    }

    this.liteDisplayService.updateAmountToPay(paymentSession.amountRemainingToPay);
    if (paymentSession.amountRemainingToPay.value <= 0) {
      this.machine.toPostSale();
    } else {
      this.liteDisplayService.showPayinCoins();
    }
  }

  protected onMachineTimeoutModalCancel(machineName: string) {
    if (machineName === this.machineName &&
      (this.state === 'paymentCash' || this.state === 'paymentCard' || this.state === 'invalidRfidCardInserted')) {
      if (this.saleService.order.amountReceived.value === 0) {
        this.machine.toIdle();
      } else {
        this.saleService.paymentSession.isCancelled = true;
        this.machine.toPostSale();
      }
      return;
    }
    super.onMachineTimeoutModalCancel(machineName);
  }

  protected getMachineInactivitySettings(state: string): MachineInactivitySettings {
    switch (state) {
      case 'paymentCash':
      case 'paymentCard':
        return this.getTimeoutSettings(this.additionalPropertiesConfigurationService.timeoutPaymentSec);
      case 'invalidRfidCardInserted':
        return this.getTimeoutSettings(this.additionalPropertiesConfigurationService.timeoutInvalidRfidCardInserted);
      default:
        return super.getMachineInactivitySettings(state);
    }
  }

  private getTimeoutSettings(timeoutInSeconds: number) {
    return new MachineInactivitySettings(timeoutInSeconds * 1000, true);
  }

  onRightButtonClick() {
    if (this.state === 'idle') {
      this.machine.toPaymentCard();
    } else if (this.state === 'paymentCard') {
      this.machine.toIdle();
    }
  }

  onInvalidRfidCardInserted() {
    if (this.state === 'idle') {
      this.machine.toInvalidRfidCardInserted();
    }
  }
}
