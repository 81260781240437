import { Component, OnInit } from '@angular/core';
import { CreditCardTerminalSimulatorService } from '../../../services/credit-card-terminal-simulator.service';

@Component({
  selector: 'app-credit-card-terminal-simulator',
  templateUrl: './credit-card-terminal-simulator.component.html',
  styleUrls: ['./credit-card-terminal-simulator.component.css']
})
export class CreditCardTerminalSimulatorComponent implements OnInit {


  constructor(
    private cardTerminal: CreditCardTerminalSimulatorService,
  ) {

  }

  ngOnInit() {
  }

  onCreditCardInsertClick() {
    this.cardTerminal.creditCardInserted();
  }

  onCreditCardRemoveClick() {
    this.cardTerminal.creditCardRemoved();
  }

  onCreditCardPinClick(isValid: boolean) {
    this.cardTerminal.pinEntered(isValid);
  }

  onCreditCardCancel() {
    this.cardTerminal.cancel();
  }

  get cssClassInsertCard(): string {
    return this.cardTerminal.isCreditCardInserted ? 'disabled' : '';
  }

  get cssClassTakeCard(): string {
    return this.cardTerminal.isCreditCardLocked || !this.cardTerminal.isCreditCardInserted ? 'disabled' : '';
  }

  onRevertCardTerminalTransactionCompleted() {
    this.cardTerminal.revertingTransactionCompleted();
  }

  onRevertCardTerminalTransactionFailed() {
    this.cardTerminal.revertingTransactionFailed();
  }
}
