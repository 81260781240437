import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigurationService } from '../../../services/configuration/configuration.service'
import { UiService } from '../../../services/ui.service';
import { AssetsService } from '../../../services/assets.service';
import { DispatcherService } from '../../../services/dispatcher.service';
import { Configuration } from '../../../lib/lib';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../services/notification/notification.service';
import { NotificationMessage } from '../../../lib/notification-message/notification-message';

@Component({
  moduleId: module.id,
  selector: 'page-header',
  templateUrl: 'page-header.component.html',
  styleUrls: ['page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  imgSrc: string;
  defaultLogoPath: string = './../../../../static/images/logo.png';
  subsrcription: Subscription;


  constructor(
    public uiService: UiService,
    public configurationService: ConfigurationService,
    public assetsService: AssetsService,
    public dispatcherService: DispatcherService,
    private notificationService: NotificationService,
  ) {
  }

  ngOnInit() {
    this.subsrcription = this.dispatcherService.onConfigurationChangedSubscribe((configuration: Configuration) => {
      this.reloadLogo(configuration.customLogoId);
    });
  }
  ngOnDestroy() {
    if (this.subsrcription) {
      this.subsrcription.unsubscribe();
    }
  }

  reloadLogo(logoId: number) {
    switch (logoId) {
      case 0:
      case null:
        this.imgSrc = this.defaultLogoPath;
        break;
      default:
        this.imgSrc = this.assetsService.getImageUrlById(logoId);
        break;
    }
  }

  get notificationMessage(): NotificationMessage {
    if(!this.notificationService || !this.notificationService.notificationMessage || !this.notificationService.notificationMessage.message)
      return null;

    return this.notificationService.notificationMessage;
  }
}
