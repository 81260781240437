import { Injectable } from '@angular/core';

import { DisplayItem } from '../../../../lib/display/display-item/display-item';
import { BaseGridWorkflowService } from '../base-grid-workflow.service';
import { SaleService } from '../../../sale.service';

@Injectable()
export class CashPaymentBaseGridWorkflowService extends BaseGridWorkflowService {

  protected saleService: SaleService;

  init(
  ) {
    super.init();
    this.saleService = this.injector.get(SaleService);
    this.dispatcherService.onDisplayModePaymentComplete((message) => {
      if (!this.displayItem) {
        return;
      }

      this.userActivity();
      this.paymentComplete();
    });
  }

  paymentComplete() {
    if (this.displayItem) {
      this.nextStep(this.displayItem);
    }
    this.delayedHardReset();
  }

  show(displayItem: DisplayItem, context: any) {
    super.show(displayItem, context);
    if (!this.saleService.order || !this.saleService.order.orderLines || this.saleService.order.orderLines.length === 0) {
      this.saleService.resetOrder();
      this.navigateToRootPage();
      return;
    }
  }

  hide(displayItem: DisplayItem, context: any) {
    super.hide(displayItem, context);
    if (this.saleService.order && this.saleService.order.orderLines && this.saleService.order.orderLines.length !== 0) {
      this.saleService.resetOrder();
    }
  }

  get isPaymentConfirmationAvalible(): boolean {
    return this.saleService.paymentSession.amountPaidIn.isPositive;
  }
}
