import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Order, GlobalSettings } from '../../../lib/lib';
import { ShopState } from '../../../lib/lib';
import { LoggingService } from '../../../services/logging/logging.service';
import { DispatcherService } from '../../../services/dispatcher.service';
import { SaleService } from '../../../services/sale.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { AdditionalPropertiesConfigurationService } from '../../../services/configuration/additional-properties-configuration.service';
import { OneLineArticleSaleMode } from '../../../lib/one-line-article-sale-mode';

@Component({
  moduleId: module.id,
  selector: 'basket',
  templateUrl: 'basket.component.html',
  styleUrls: ['basket.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BasketComponent implements OnInit, OnDestroy {
  order: Order;
  headerText: string;
  shopSubscription: Subscription;
  shopState: ShopState;
  private isBackButtonVisible: boolean;
  private isAbortButtonVisible: boolean;

  constructor(private log: LoggingService,
              private dispatcherService: DispatcherService,
              private saleService: SaleService,
              public configurationService: ConfigurationService,
              public additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService,
  ) {
  }

  ngOnInit(): void {
    if (this.configurationService.oneLineArticleSaleMode === OneLineArticleSaleMode.Disabled) {
      this.dispatcherService.backButtonTextSet('Continue shopping');
    }
    this.dispatcherService.pageHeaderSet('Basket');
    this.order = this.saleService.order;
    this.shopSubscription = this.dispatcherService.onShopStateChangedSubscribe(x => this.onShopStateChanged(x));
    this.isBackButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = true;
    this.isAbortButtonVisible = this.dispatcherService.isAbortButtonVisible;
    this.dispatcherService.isAbortButtonVisible = true;
  }

  ngOnDestroy() {
    this.dispatcherService.isBackButtonVisible = this.isBackButtonVisible;
    this.dispatcherService.backButtonTextReset();
    this.dispatcherService.pageHeaderSet();
    this.shopSubscription.unsubscribe();
    this.dispatcherService.isAbortButtonVisible = this.isAbortButtonVisible;
  }

  get currencyCode() {
    return GlobalSettings.getCurrencyCode();
  }

  onOrderLineDeleted(orderLine: any) {
    this.order.deleteOrderLine(orderLine);
  }

  onShopStateChanged(shopState: ShopState) {
    this.shopState = shopState;
  }

  onPayCashClick($event) {
    this.dispatcherService.toPaymentCash();
  }

  onPayCardClick($event) {
    this.dispatcherService.toPaymentCard();
  }

  removeAllOrderLines() {
    this.saleService.closePaymentSession();
    this.order = this.saleService.order;
  }

  get clearBasketBtnIsAvailable() {
    return this.order && this.order.orderLines && this.order.orderLines.length > 0 && !this.requiredLineExist;
  }

  get isOrderLinesAvailable(): boolean {
    return this.order
      && this.order.orderLines
      && this.order.orderLines.length !== 0;
  }

  get isCreditCardPaymentAvailable(): boolean {
    return this.additionalPropertiesConfigurationService
      && this.additionalPropertiesConfigurationService.isCreditCardPaymentAvailable
      && !this.additionalPropertiesConfigurationService.isLiteMode;
  }

  get requiredLineExist(): boolean {
    return this.order.orderLines.filter(x => x.required).length > 0;
  }

  get isCashPaymentAvailable(): boolean {
    return this.order
      &&
      this.order.amountTotal.isPositive
      &&
      this.additionalPropertiesConfigurationService
      &&
      this.additionalPropertiesConfigurationService.isCashPaymentAvailable;
  }

  get areControlsForBasketClearingVisible(): boolean {
    return this.configurationService.oneLineArticleSaleMode === OneLineArticleSaleMode.Disabled;
  }
}
