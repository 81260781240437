import { Component, OnInit, OnDestroy } from '@angular/core';
import { DispatcherService } from '../../../services/dispatcher.service';

@Component({
  selector: 'app-page-lite-message',
  templateUrl: './page-lite-message.component.html',
  styleUrls: ['./page-lite-message.component.css']
})
export class PageLiteMessageComponent implements OnInit, OnDestroy {

  private backButtonVisible: boolean;
  private headerVisible: boolean;

  constructor(
    private dispatcherService: DispatcherService,
  ) {
  }

  ngOnInit() {
    this.backButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = false;

    this.headerVisible = this.dispatcherService.isHeaderVisible;
    this.dispatcherService.isHeaderVisible = false;
  }

  ngOnDestroy() {
    this.dispatcherService.isBackButtonVisible = this.backButtonVisible;
    this.dispatcherService.isHeaderVisible = this.headerVisible;
  }
}
