import { Injectable } from '@angular/core';
import { TouchTile } from '../../lib/touch-tile/touch-tile';
import { TouchTileType } from '../../lib/touch-tile/touch-tile-type';
import { TouchTileColor } from '../../lib/touch-tile/touch-tile-color';
import { TouchTileSwitch } from '../../lib/touch-tile/touch-tile-switch';
import { RunningLightScenario } from '../../lib/touch-tile/running-light-scenario';

@Injectable()
export class TouchTileSimulatorService {
  private _TouchTiles: TouchTile[] = new Array<TouchTile>();
  runningLightScenario: RunningLightScenario;

  constructor(
  ) {
    this._initDefaultValues();
  }

  private _initDefaultValues() {
    this._TouchTiles.push(this._generateDefaultValue(TouchTileType.BarcodeReaderLight));
    this._TouchTiles.push(this._generateDefaultValue(TouchTileType.BillAcceptorLight));
    this._TouchTiles.push(this._generateDefaultValue(TouchTileType.CoinAcceptorLight));
    this._TouchTiles.push(this._generateDefaultValue(TouchTileType.CoinBoxLight));
    this._TouchTiles.push(this._generateDefaultValue(TouchTileType.CreditCardLight));
    this._TouchTiles.push(this._generateDefaultValue(TouchTileType.DispenserChuteLight));
    this._TouchTiles.push(this._generateDefaultValue(TouchTileType.FrameLight));
    this._TouchTiles.push(this._generateDefaultValue(TouchTileType.LogoLight));
    this._TouchTiles.push(this._generateDefaultValue(TouchTileType.PinPadLight));
    this._TouchTiles.push(this._generateDefaultValue(TouchTileType.RFIDLight));
    this._TouchTiles.push(this._generateDefaultValue(TouchTileType.TicketBoxLight));
  }

  private _generateDefaultValue(type: TouchTileType) {
    const tile = new TouchTile();
    tile.name = type;
    tile.color = "#ffffff";
    tile.enabled = false;
    return tile;
  }

  get touchTiles(): TouchTile[] {
    return this._TouchTiles;
  }

  changeColors(tileColors: TouchTileColor[]) {
    for (let i = 0; i < tileColors.length; i++) {
      const tile = this._TouchTiles.find(x => x.name == tileColors[i].name);
      tile.color = tileColors[i].color;
    }
  }

  changeSwitches(tileSwitches: TouchTileSwitch[]) {
    for (let i = 0; i < tileSwitches.length; i++) {
      const tile = this._TouchTiles.find(x => x.name == tileSwitches[i].name);
      tile.enabled = tileSwitches[i].enabled;
    }
  }

  setRunningLight(runningLightScenario: RunningLightScenario) {
    this.runningLightScenario = runningLightScenario;
  }

  testChangeColor() {
    const barcodeReader = new TouchTileColor();
    barcodeReader.name = TouchTileType.BarcodeReaderLight;
    barcodeReader.color = "#ff00ff";

    const bna = new TouchTileColor();
    bna.name = TouchTileType.BillAcceptorLight;
    bna.color = "#ff0000";

    const t = [barcodeReader, bna]
    this.changeColors(t);
  }

  testChangeSwitches() {
    this.changeSwitches(
      [
        new TouchTileSwitch(TouchTileType.BarcodeReaderLight, true),
        new TouchTileSwitch(TouchTileType.BillAcceptorLight, true),
      ]
    );
  }
}
