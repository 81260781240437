import { Component, OnInit, Input, EventEmitter, Output,  } from '@angular/core';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { Order } from '../../../lib/lib';
import { DispatcherService } from '../../../services/dispatcher.service';
import { SaleService } from '../../../services/sale.service';
import { PrintReceiptEnum } from '../../../lib/printer/print-receipt-enum';
import { MatSlideToggleChange } from '@angular/material';
import { PrintReceiptTypeEnum } from '../../../lib/printer/print-receipt-type-enum';

@Component({
  selector: 'receipt-switcher',
  templateUrl: './receipt-switcher.component.html',
  styleUrls: ['./receipt-switcher.component.scss']
})
export class ReceiptSwitcherComponent implements OnInit {
    order: Order;

    @Input()
    cssClass: string;

    @Input()
    receiptType = PrintReceiptTypeEnum.OrderReceipt;

    @Output()
    Click: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private configurationService: ConfigurationService,
        private dispatcherService: DispatcherService,
        saleService: SaleService,
    ) {
        this.order = saleService.order;
    }

    ngOnInit() {
        this.order.isReceipt = this.configurationService.configuration.printOrderReceipt == PrintReceiptEnum.ForcePrint;
    }

    get slideToggleText(): string {
      if (this.receiptType === PrintReceiptTypeEnum.OrderReceipt){
        return 'Print Receipt';
      }

      return 'Card Terminal Receipt';
    }

    get isReceiptEnabled(): boolean {
      if (this.receiptType === PrintReceiptTypeEnum.OrderReceipt) {
        return this.order.isReceipt;
      }
      return this.order.isCardTerminalReceipt;
    }

    set isReceiptEnabled(value: boolean) {
      if (this.receiptType === PrintReceiptTypeEnum.OrderReceipt) {
        this.order.isReceipt = value;
      } else {
        this.order.isCardTerminalReceipt = value;
      }
      this.dispatcherService.onUserActivity();
    }

    get showReceiptButton(): boolean {
        if (
          !this.configurationService ||
          !this.configurationService.configuration ||
          !this.configurationService.configuration.printOrderReceipt) {
            return false;
          }

        return this.configurationService.configuration.printOrderReceipt === PrintReceiptEnum.UserChoice;
    }
}
