import { Store } from '@ngrx/store';
import { Component } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ConfigurationService } from '../../../services/configuration/configuration.service'
import { Header } from '../../../lib/header';

@Component({
  moduleId: module.id,
  selector: 'page-header-title',
  templateUrl: 'page-header-title.component.html',
  styleUrls: ['page-header-title.component.scss']
})
export class PageHeaderTitleComponent {

  headerLines: Observable<Header>;

  constructor(
    protected store: Store<string>,
    protected configurationService: ConfigurationService,
  ) {
    this.headerLines = this.store.select('pageHeader');
  }
}
