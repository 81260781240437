import { Injectable } from '@angular/core';
import { BaseGridWorkflowService } from '../../base-grid-workflow.service';
import { SaleService } from '../../../../sale.service';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';

@Injectable()
export class CashRefundConfirmationGridWorkflowService extends BaseGridWorkflowService {

  private saleService: SaleService;

  init(
  ) {
    super.init();
    this.saleService = this.injector.get(SaleService);
    this.dispatcherService.onDisplayModeRefundComplete((message) => {
      if (!this.displayItem) {
        return;
      }

      this.refundComplete();
    });
  }

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.CashRefundConfirmation;
  }

  refundComplete() {
    if (this.displayItem) {
      this.nextStep(this.displayItem);
    }
  }

  show(displayItem: DisplayItem, context: any) {
    super.show(displayItem, context);
    const refundAmount = context.refundAmount;
    const refundProduct = context.refundProduct;
    if (!refundAmount || !refundProduct) {
      this.navigateToRootPage();
      return;
    }

    const rfidCardCode = this.getRfidCardCodeFormattedData(context);
    this.saleService.ticketReturnAmount(refundProduct, refundAmount, 0, rfidCardCode, true);
  }

  getRfidCardCodeFormattedData(context: any): string {
    let result = '';
    if (!context) {
      return result;
    }

    result += `${context.rfidCardCode}`;

    if (context.odooContext && context.odooContext.externalContext && context.odooContext.externalContext.PersonId) {
      result += `#${context.odooContext.externalContext.PersonId}`;
    }

    return result;
  }
}
