import { VuRole, DEFAULT_LANGUAGE_ID } from './lib';
import { BarcodeInputMethod } from './barcode-reader/barcode-input-method';
import { OneLineArticleSaleMode } from './one-line-article-sale-mode';
import { DisplayConfiguration } from './display/configuration/display-configuration';
import { DictionaryHelper } from './dictionary-helper';
import { PrintReceiptEnum } from './printer/print-receipt-enum';
import { Asset } from './assets/asset';

export class Configuration {
  isProduction = false;
  languageTimeout = 120000;
  timeIntervalBeforeTimeoutModal = 60000;
  vuRole = VuRole.Entry;
  locale = DEFAULT_LANGUAGE_ID;
  showLanguageButton = false;
  customRoutes: any[];
  defaultShopTitle = '';
  showArticlesOnMainPage = false;
  printCardTerminalReceipt = PrintReceiptEnum.UserChoice;
  printOrderReceipt = PrintReceiptEnum.UserChoice;
  oneLineArticleSaleMode = OneLineArticleSaleMode.Disabled;
  receiptPrinterType = '';
  ticketPrinterType = '';
  barcodeInputMethod = BarcodeInputMethod.BarcodeReaderAndVirtualKeyboard;
  private internalAdditionalProperties = {};
  private internalDictionaryHelper: DictionaryHelper;
  showDemoBackground = true;
  displayConfiguration: DisplayConfiguration = null;
  displayConfigurationId: number = null;
  customCss = '';
  customLogoId: number = null;
  backgroundId: number = null;
  assets: Asset[] = null;

  constructor() {
    this.internalDictionaryHelper = new DictionaryHelper(this.internalAdditionalProperties);
  }

  get additionalProperties() {
    return this.internalAdditionalProperties;
  }

  set additionalProperties(value) {
    this.internalAdditionalProperties = value;
    this.internalDictionaryHelper = new DictionaryHelper(this.internalAdditionalProperties);
  }

  get additionalPropertiesHelper(): DictionaryHelper {
    return this.internalDictionaryHelper;
  }

  static fromOther(x: any): Configuration {
    const configuration = Object.assign(new Configuration(), x);

    if (x && x.assets) {
      configuration.assets = x.assets.map(item => Asset.fromAny(item));
    }

    return configuration;
  }

  toString() {
    let m = `vuRole: ${this.vuRole} `;
    m += `languageTimeout: ${this.languageTimeout}. `;
    m += `locale: ${this.locale}. `;
    m += `isProduction: ${this.isProduction}. `;
    m += `timeIntervalBeforeTimeoutModal: ${this.timeIntervalBeforeTimeoutModal}. `;
    m += `showLanguageButton: ${this.showLanguageButton}. `;
    m += `defaultShopTitle: ${this.defaultShopTitle}. `;
    m += `showArticlesOnMainPage: ${this.showArticlesOnMainPage}. `;
    m += `printCardTerminalReceipt: ${this.printCardTerminalReceipt}. `;
    m += `printOrderReceipt: ${this.printOrderReceipt}. `;
    m += `oneLineArticleSaleMode: ${this.oneLineArticleSaleMode}. `;
    m += `barcodeInputMethod: ${this.barcodeInputMethod}. `;
    m += `receiptPrinterType: ${this.receiptPrinterType}. `;
    m += `ticketPrinterType: ${this.ticketPrinterType}. `;
    m += `additionalProperties: ${this.internalDictionaryHelper}. `;
    m += `displayConfiguration: ${this.displayConfiguration}. `;
    m += `displayConfigurationId: ${this.displayConfigurationId}. `;
    m += `customCss: ${this.customCss}.`;
    m += `customLogoId: ${this.customLogoId}. `;
    m += `backgroundId: ${this.backgroundId}. `;
    return m;
  }
}

export class DtoVuConfiguration {
  vuRole = VuRole.Entry;
  locale = DEFAULT_LANGUAGE_ID;
  showArticlesOnMainPage = false;
  printCardTerminalReceipt = '';
  printOrderReceipt = '';
  oneLineArticleSaleMode = OneLineArticleSaleMode.Disabled;
  barcodeInputMethod = BarcodeInputMethod.BarcodeReaderAndVirtualKeyboard;
  receiptPrinterType = '';
  ticketPrinterType = '';
  additionalProperties = {};
  displayConfiguration: DisplayConfiguration = null;
  displayConfigurationId: number = null;
  customCss = '';
  customLogoId: number = null;
  backgroundId: number = null;
  assets: any;

  static fromOther(other: any): DtoVuConfiguration {
    return Object.assign(new Configuration(), other);
  }

  toString(): string {
    let m = `vuRole: ${this.vuRole} `;
    m += `locale: ${this.locale}. `;
    m += `showArticlesOnMainPage: ${this.showArticlesOnMainPage}. `;
    m += `printCardTerminalReceipt: ${this.printCardTerminalReceipt}. `;
    m += `printOrderReceipt: ${this.printOrderReceipt}. `;
    m += `oneLineArticleSaleMode: ${this.oneLineArticleSaleMode}. `;
    m += `barcodeInputMethod: ${this.barcodeInputMethod}. `;
    m += `receiptPrinterType: ${this.receiptPrinterType}. `;
    m += `ticketPrinterType: ${this.ticketPrinterType}. `;
    m += `additionalProperties: ${this.additionalProperties}. `;
    m += `customCss: ${this.customCss}. `;
    m += `customLogoId: ${this.customLogoId}. `;
    m += `backgroundId: ${this.backgroundId}. `;
    m += `displayConfigurationId: ${this.displayConfigurationId}. `;
    return m;
  }
}
