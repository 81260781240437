import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { DisplayItem } from '../../../lib/display/display-item/display-item';

@Component({
  selector: 'app-grid-visual-item',
  templateUrl: './grid-visual-item.component.html',
  styleUrls: ['./grid-visual-item.component.css']
})
export class GridVisualItemComponent implements OnInit {

  @Input()
  displayItem: DisplayItem;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) {
  }

  ngOnInit() {
  }

  onItemClick() {
    if (this.displayItem != null && this.displayItem != undefined)
      this.onClick.emit(this.displayItem);
  }

  setName(item: DisplayItem) {
    return item.name + ` ${item.sizeHorizontal - item.posX}x` + `${item.sizeVertical - item.posY}`;
  }
}
