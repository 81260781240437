import { Injectable } from '@angular/core';
import { VuCommunicationService } from '../vu/vu-communication.service';

@Injectable()
export class RfidCardReaderSimulatorService {

  constructor(
    private vuCommunicationService: VuCommunicationService,
  ) {
  }

  rfidCardRead(rfidCardCode: string) {
    this.vuCommunicationService.vuConnectionSimulator.rfidCardRead(rfidCardCode);
  }

}
