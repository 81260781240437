import { Action } from '@ngrx/store';
import { VisualItem } from '../../lib/lib';
import { PayloadAction } from '../../lib/payload-action';

export const VISUAL_ITEM_SELECTED = 'VisualItemSelected';

export class VisualItemSelectedAction implements Action {
  readonly type = VISUAL_ITEM_SELECTED;
  constructor(
    public payload: VisualItem,
  ) {
  }
}

export function visualItemReducer(state = {}, action: PayloadAction) {
  switch (action.type) {
    case VISUAL_ITEM_SELECTED:
      return action.payload;
    default:
      break;
  }
  return state;
}
