import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { DispatcherService } from '../../../services/dispatcher.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';

@Component({
  moduleId: module.id,
  selector: 'ticket-loading',
  templateUrl: 'ticket-loading.component.html',
  styleUrls: ['ticket-loading.component.scss']
})
export class TicketLoadingComponent implements OnInit, OnDestroy {
  private isBackButtonVisible: boolean;
  private backButtonSubscription: Subscription;
  private timer: any;
  text: string;
  barcode: string;
  private barcodeReadSubscription: Subscription;

  constructor(
    private dispatcherService: DispatcherService,
    private configurationService: ConfigurationService,
    private vuCommunicationService: VuCommunicationService,
  ) {
  }

  ngOnInit(): void {
    this.dispatcherService.pageHeaderSet(`The ticket is being loaded`);
    this.backButtonSubscription = this.dispatcherService.onBackButtonClickSubscribe(() => this.onBackButtonClick());
    this.barcodeReadSubscription = this.vuCommunicationService.vuConnection.eventBarcodeRead.subscribe((x) => this.resetView(x))
    this.resetView(this.dispatcherService.barcode);
    this.isBackButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = !this.configurationService.isRoleExit;
  }

  ngOnDestroy() {
    this.stopTimer();
    this.dispatcherService.pageHeaderSet();
    this.backButtonSubscription.unsubscribe();
    this.dispatcherService.isBackButtonVisible = this.isBackButtonVisible;
    this.barcodeReadSubscription.unsubscribe();
  }

  resetView(barcode: string) {
    this.text = 'Please wait...';
    this.barcode = barcode;
    this.stopTimer();
    this.startTimer();
  }

  private onBackButtonClick() {
    this.dispatcherService.back();
  }

  private startTimer() {
    let scope = this;
    this.timer = setTimeout(() => {
      if (scope.timer) {
        scope.text = 'Timeout';
        scope.timer = null;
      }
    }, 5000);
  }

  stopTimer() {
    if (this.timer != null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  get cssClass(): string {
    return `normal-text ${this.timer == null ? ' red' : ''}`;
  }
}
