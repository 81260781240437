import { TicketUse } from './ticket-use';

export class TicketUseByDate {
  date: Date;
  ticketUseItems: TicketUse[];

  static fromTicketUseArray(ticketUseItems: TicketUse[], isAsc: boolean): TicketUseByDate[] {
    if (ticketUseItems == null) { return []; }

    const sorted = ticketUseItems.sort((a: TicketUse, b: TicketUse) => (isAsc ? 1 : -1) * (a.dateTime.getTime() - b.dateTime.getTime()));
    const dict: { [date: string]: TicketUse[] } = {};
    for (const x of sorted) {
      const dt = x.dateTime;
      const key = (new Date(dt.getFullYear(), dt.getUTCMonth(), dt.getUTCDate())).toJSON();
      if (!(key in dict)) {
        dict[key] = [];
      }
      dict[key].push(x);
    }

    const result: TicketUseByDate[] = [];

    for (const key in dict) {
      if (!dict.hasOwnProperty(key)) { continue; }

      const date = new Date(key);
      result.push(new TicketUseByDate(date, dict[key]));
    }

    return result;
  }

  constructor(
    date: Date,
    ticketUse: TicketUse[]
  ) {
    this.date = date;
    this.ticketUseItems = ticketUse;
  }

  get isToday(): boolean {
    return this.date.toDateString() === new Date().toDateString();
  }
}
