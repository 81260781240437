import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { IVuHttp } from '../../../services/vu/http/vu-http.interface';
import { DispatcherService } from '../../../services/dispatcher.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { BarcodeInputMethod } from '../../../lib/barcode-reader/barcode-input-method';
import { BarcodeReaderService } from '../../../services/barcode/barcode-reader.service';
import { AdditionalPropertiesConfigurationService } from '../../../services/configuration/additional-properties-configuration.service';
import { KeyboardType } from '../../general/on-screen-keyboard/on-screen-keyboard.component';

@Component({
  moduleId: module.id,
  selector: 'ticket-wait-for',
  templateUrl: 'ticket-wait-for.component.html',
  styleUrls: ['ticket-wait-for.component.scss']
})
export class TicketWaitForComponent implements OnInit, OnDestroy {
  private backButtonSubscription: Subscription;
  private isBackButtonVisible: boolean;
  private vuHttp: IVuHttp;
  barcodeInputMethod = BarcodeInputMethod.VirtualKeyboard;
  isDisabled = false;

  constructor(
    private dispatcherService: DispatcherService,
    private configurationService: ConfigurationService,
    private vuCommunicationService: VuCommunicationService,
    private barcodeReaderService: BarcodeReaderService,
    private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService,
  ) {
    this.vuHttp = this.vuCommunicationService.vuHttp;
  }

  ngOnInit(): void {
    this.backButtonSubscription = this.dispatcherService.onBackButtonClickSubscribe(() => this.onBackButtonClick());
    this.dispatcherService.pageHeaderSet('Ticket Information');
    this.isBackButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = !this.configurationService.isRoleExit;
    this.barcodeInputMethod = this.configurationService.barcodeInputMethod;
    if (this.isBarcodeReaderAvailable) this.barcodeLedEnable(true);
  }

  ngOnDestroy() {
    this.dispatcherService.pageHeaderSet();
    this.backButtonSubscription.unsubscribe();
    this.dispatcherService.isBackButtonVisible = this.isBackButtonVisible;
    if (this.isBarcodeReaderAvailable) this.barcodeLedEnable(false);
  }

  get keyboardType(): KeyboardType {

    switch (this.barcodeInputMethod) {
      case BarcodeInputMethod.BarcodeReader:
        return KeyboardType.None;
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboard:
      case BarcodeInputMethod.VirtualKeyboard:
        return KeyboardType.All;
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboardCharsOnly:
      case BarcodeInputMethod.VirtualKeyboardCharsOnly:
        return KeyboardType.CharsOnly;
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboardNumbersOnly:
      case BarcodeInputMethod.VirtualKeyboardNumbersOnly:
        return KeyboardType.NumbersOnly;
    }

    return KeyboardType.All;
  }

  get ticketLength(): number {
    return this.additionalPropertiesConfigurationService.ticketLength;
  }

  get separatorPosition(): number {
    return this.additionalPropertiesConfigurationService.separatorPosition;
  }

  private barcodeLedEnable(value: boolean) {
    if (this.dispatcherService.vuHttp != null) {
      this.dispatcherService.vuHttp.barcodeLedEnable(value);
    }
  }

  private onBackButtonClick() {
    this.dispatcherService.back();
  }

  private get isBarcodeReaderAvailable(): boolean {

    switch (this.barcodeInputMethod) {
      case BarcodeInputMethod.BarcodeReader:
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboard:
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboardCharsOnly:
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboardNumbersOnly:
        return true;
    }

    return false;
  }

  onEnteredText(text: string) {
    this.barcodeReaderService.onBarcodeRead(text);
    this.isDisabled = true;
  }

  onTextChanged() {
    this.dispatcherService.onUserActivity();
  }

  get isVirtualKeyboardVisible(): boolean {
    return this.barcodeInputMethod !== BarcodeInputMethod.BarcodeReader;
  }

  get barcodeInputMethodDescription(): string {
    switch (this.barcodeInputMethod) {
      case BarcodeInputMethod.BarcodeReader:
        return 'Attach your ticket!';
      case BarcodeInputMethod.VirtualKeyboard:
      case BarcodeInputMethod.VirtualKeyboardCharsOnly:
      case BarcodeInputMethod.VirtualKeyboardNumbersOnly:
        return 'Type in and enter the ticket code!';
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboard:
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboardCharsOnly:
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboardNumbersOnly:
        return 'Attach your ticket or type in and enter the ticket code!';
    }

    return 'Attach your ticket!';
  }
}
