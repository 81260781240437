export enum StepConfigurationType {
  Coins = 'Coins',
  Change = 'Change',
  Amount = 'Amount',
  Card = 'Card',
  RFID = 'RFID',
  Print = 'Print',
  EntryLeft = 'EntryLeft',
  EntryRight = 'EntryRight',
}
