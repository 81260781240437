import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { MessageView } from '../../../lib/lib';
import { DispatcherService } from '../../../services/dispatcher.service';

@Component({
  moduleId: module.id,
  selector: 'app-message',
  templateUrl: 'message.component.html',
  styleUrls: ['message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {
  private backButtonVisible: boolean;
  private subcription: Subscription;
  messageView = new MessageView();


  constructor(
    private dispatcherService: DispatcherService,
  ) {
  }

  ngOnInit(): void {
    this.backButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = false;
    this.subcription = this.dispatcherService.onMessageViewSubscribe((x: MessageView) => {
      if (x != null) {
        this.messageView = x;
      }

    });
  }

  ngOnDestroy() {
    this.dispatcherService.isBackButtonVisible = this.backButtonVisible;
    this.subcription.unsubscribe();
  }
}
