import { Money } from '../lib';
import { TicketParameters } from '../ticket/ticket-parameters';
import { OrderLine } from './order-line';

export class DtoOrderLine {
  uid: string;
  name: string;
  productId: number;
  price: Money;
  quantity: number;
  amount: Money;
  amountTax: Money;
  taxRate: number;
  barcode: string;
  ticketParameters: TicketParameters;

  static fromOrderLine(
    line: OrderLine
  ): DtoOrderLine {
    const r = new DtoOrderLine();
    r.uid = line.uid;
    r.name = line.name;
    r.productId = line.productId;
    r.price = line.price;
    r.quantity = line.quantity;
    r.amount = line.amount;
    r.amountTax = line.amountTax;
    r.taxRate = line.taxRate;
    r.barcode = line.barcode;
    r.ticketParameters = line.ticketParameters;
    return r;
  }
}
