import { Component, OnInit, Input } from '@angular/core';
import { DisplayItem } from '../../../lib/display/display-item/display-item';
import { AssetsService } from '../../../services/assets.service';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'app-grid-visual-item-pdf-viewer',
  templateUrl: './grid-visual-item-pdf-viewer.component.html',
  styleUrls: ['./grid-visual-item-pdf-viewer.component.css']
})
export class GridVisualItemPdfViewerComponent implements OnInit {

  @Input()
  displayItem: DisplayItem;

  constructor(
    private assetsService: AssetsService,
    private languageService: LanguageService,
  ) {
  }

  ngOnInit() {
  }

  get pdfUrl(): string {

    if (this.displayItem) {
      if (this.displayItem.apiUrl) {
        return this.displayItem.apiUrl;
      }

      if (this.displayItem.backgroundId) {
        return this.assetsService.getAttachmentUrlById(this.displayItem.backgroundId, this.languageService.getLanguage());
      }
    }

    return '';
  }
}
