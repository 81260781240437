import { Injectable, Injector } from '@angular/core';
import { SignalRService } from './connection/vu-connection-signalr.service';
import { SignalRSimulatorService } from './connection/vu-connection-simulator.service';
import { ConfigurationService } from '../configuration/configuration.service';
import { VuConnectionBase } from './connection/vu-connection-base.service';
import { VuHttpService } from './http/vu-http.service';
import { VuHttpSimulatorService } from './http/vu-http-simulator.service';
import { IVuHttp, IVuHttpSimulator } from './http/vu-http.interface';
import { DispatcherService } from '../dispatcher.service';
import { IVuConnection, IVuConnectionSimulator } from './connection/vu-connection.interfaces';
import { VuHttpBaseService } from './http/vu-http-base.service';
import { ExternalApiBaseService } from './external-api/external-api-base.service';
import { ExternalApiSimulatorService } from './external-api/external-api-simulator.service';
import { ExternalApiService } from './external-api/external-api.service';
import { IExternalApiService } from './external-api/external-api.interface';

@Injectable()
export class VuCommunicationService {
  private _vuConnection: VuConnectionBase;
  private _vuHttp: VuHttpBaseService;
  private dispatcherService: DispatcherService;
  private сonfigurationService: ConfigurationService;
  private _externalApiBaseService: ExternalApiBaseService;

  constructor(
    private injector: Injector,
  ) {
    this.сonfigurationService = this.injector.get(ConfigurationService);
    this.init();
  }

  private init() {
    this._vuConnection = this.injector.get(this.isSimulator ? SignalRSimulatorService : SignalRService);
    this._vuHttp = this.injector.get(this.isSimulator ? VuHttpSimulatorService : VuHttpService);
    this._externalApiBaseService = this.injector.get(this.isSimulator ? ExternalApiSimulatorService : ExternalApiService);
    this.dispatcherService = this.injector.get(DispatcherService);

    this._vuConnection.navigationUrlChanged.subscribe((navigationUrl: string) => {
      if (navigationUrl === '/Service/') {
        this.dispatcherService.vuStateServiceMode(true);
      }
    });
  }

  get isSimulator(): boolean {
    return !this.сonfigurationService.configuration.isProduction;
  }

  get vuConnection(): IVuConnection {
    return this._vuConnection;
  }

  get vuHttp(): IVuHttp {
    return this._vuHttp;
  }

  get vuConnectionSimulator(): IVuConnectionSimulator {
    return this._vuConnection;
  }

  get vuHttpSimulator(): IVuHttpSimulator {
    return this._vuHttp;
  }

  get ExternalApiService(): IExternalApiService {
    return this._externalApiBaseService;
  }
}
