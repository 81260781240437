export enum TouchTileType {
    CreditCardLight = "CreditCardLight",
    CoinAcceptorLight = "CoinAcceptorLight",
    BillAcceptorLight = "BillAcceptorLight",
    RFIDLight = "RFIDLight",
    TicketBoxLight = "TicketBoxLight",
    CoinBoxLight = "CoinBoxLight",
    PinPadLight = "PinPadLight",
    FrameLight = "FrameLight",
    BarcodeReaderLight = "BarcodeReaderLight",
    DispenserChuteLight = "DispenserChuteLight",
    LogoLight = "LogoLight",
}