import { Action } from '@ngrx/store';
import { AbortButton } from '../lib/buttons/abort-button';
import { PayloadAction } from '../lib/payload-action';

const initialState = new AbortButton();

export class AbortButtonActionType {
  static readonly ABORT_BUTTON_ENABLED = 'AbortButtonEnabled';
  static readonly ABORT_BUTTON_VISIBLE = 'AbortButtonVisible';
  static readonly ABORT_BUTTON_TEXT_SET = 'AbortButtonTextSet';
  static readonly ABORT_BUTTON_TEXT_RESET = 'AbortButtonTextReset';
}

export function abortButtonStateReducer(state = initialState, action: PayloadAction) {
  let isChanged = true;
  switch (action.type) {
    case AbortButtonActionType.ABORT_BUTTON_ENABLED:
      state.enabled = action.payload;
      break;
    case AbortButtonActionType.ABORT_BUTTON_VISIBLE:
      state.visible = action.payload;
      break;
    case AbortButtonActionType.ABORT_BUTTON_TEXT_SET:
      state.text = action.payload;
      break;
    case AbortButtonActionType.ABORT_BUTTON_TEXT_RESET:
      state.resetText();
      break;
    default:
      isChanged = false;
      break;
  }
  return isChanged ? AbortButton.fromOther(state) : state;
}
