import { Action } from '@ngrx/store';
import { Ticket } from '../lib/lib';
import { PayloadAction } from '../lib/payload-action';

const initialState = new Ticket();

export class TicketActionType {
  static readonly TICKET_UPDATE = 'TicketUpdate';
  static readonly TICKET_RESET = 'TicketReset';
}

export function ticketReducer(state = initialState, action: PayloadAction) {
  let isChanged = true;
  switch (action.type) {
    case TicketActionType.TICKET_RESET:
      state = new Ticket();
      break;
    case TicketActionType.TICKET_UPDATE:
      state = Ticket.fromOther(action.payload);
      break;
    default:
      isChanged = false;
      break;
  }
  return isChanged ? Ticket.fromOther(state) : state;
}
