export class DictionaryHelper {

  additionalProperties = {};

  constructor(
    additionalProperties: any,
  ) {
    this.additionalProperties = additionalProperties;
  }

  getProperty(propertyName: string): any {
    if (!this.additionalProperties) {
      return null;
    }

    return this.additionalProperties[propertyName];
  }

  getString(propertyName: string, defaultValue = ''): string {
    const value = this.getProperty(propertyName);
    if (!value) {
      return defaultValue;
    }

    return value;
  }

  getNumber(propertyName: string, defaultValue = 0): number {
    const value = this.getProperty(propertyName);
    if (!value) {
      return defaultValue;
    }

    return Number(value);
  }

  getBoolean(propertyName: string, defaultValue = false): boolean {
    const value = this.getProperty(propertyName);
    if (!value) {
      return defaultValue;
    }

    return value.toString().toLowerCase() === 'true';
  }

  getDate(propertyName: string, defaultValue: Date = null): Date {
    const value = this.getProperty(propertyName);
    if (!value) {
      return defaultValue;
    }

    return new Date(value);
  }

  getNumberArray(propertyName: string, defaultValue: number[] = null): number[] {
    if (!this.additionalProperties) {
      return defaultValue;
    }

    const value: number[] = this.additionalProperties[propertyName];
    if (!value) {
      return defaultValue;
    }

    return value;
  }

  getStringArray(propertyName: string, defaultValue: string[] = null): string[] {
    if (!this.additionalProperties) {
      return defaultValue;
    }

    const value: string[] = this.additionalProperties[propertyName];
    if (!value) {
      return defaultValue;
    }

    return value;
  }

  setProperty(propertyName: string, value: any) {
    if (!this.additionalProperties) {
      return null;
    }

    return this.additionalProperties[propertyName] = value ? value.toString() : null;
  }

  toString(): string {
    if (!this.additionalProperties) {
      return '';
    }

    let result = '';
    for (const key in this.additionalProperties) {
      if (!this.additionalProperties.hasOwnProperty(key)) {
        continue;
      }
      const value = this.additionalProperties[key];
      if (result) {
        result += ';';
      }
      result += `"${key}"="${value}"`;
    }
    return result;
  }
}
