import { Component, OnInit, Input, EventEmitter, Output,  } from '@angular/core';
import { DisplayItem } from '../../../lib/display/display-item/display-item';
import { PinGridWorkflowService } from '../../../services/display/grid/pin/pin-grid-workflow.service';
import { LanguageService } from '../../../services/language.service';
import { Dictionary } from 'lodash';
import { DisplayGridWorkflowService } from '../../../services/display/grid/display-grid-workflow.service';

@Component({
  selector: 'app-grid-visual-item-pinpad',
  templateUrl: './grid-visual-item-pinpad.component.html',
  styleUrls: ['./grid-visual-item-pinpad.component.css']
})
export class GridVisualItemPinpadComponent implements OnInit {
  @Input()
  displayItem: DisplayItem;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor( 
    private pinGridWorkflowService: PinGridWorkflowService,
    private languageService: LanguageService,
    private displayGridWorkflowService: DisplayGridWorkflowService,
  ) {
  }

  ngOnInit() {
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }

  onItemClick() {
    if (this.displayItem != null && this.displayItem != undefined)
      this.onClick.emit(this.displayItem);
  }

  setName(item: DisplayItem) {
    return item.name + ` ${item.sizeHorizontal - item.posX}x` + `${item.sizeVertical - item.posY}`;
  }

  get isVirtualKeyboardVisible(): boolean {
    return true;
  }

  onEnteredText(text: string) {
    this.pinGridWorkflowService.pinInputComplete(text);
  }

  onTextChanged() {
    
  }

  renderHtmlTemplate(displayItem: DisplayItem): string {
    const redenderData = this.displayGridWorkflowService.getDisplayItemRenderData(displayItem);
    return displayItem.getformattedHtmlTemplate(this.language, redenderData);
  }
}
