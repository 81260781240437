import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VisualItem } from '../../../lib/lib';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'app-visual-item',
  templateUrl: './visual-item.component.html',
  styleUrls: ['./visual-item.component.css']
})
export class VisualItemComponent implements OnInit {

  isProduction: boolean;

  @Input()
  visualItem: VisualItem;
  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    configurationService: ConfigurationService,
    private languageService: LanguageService,
  ) {
    this.isProduction = configurationService.configuration.isProduction;
  }

  ngOnInit() {
  }

  get imagePath() {
    if (this.isProduction) {
      return this.visualItem.imagePath;
    }
    return '/../../../static/images/logo.png';
  }

  onButtonClick() {
    this.onClick.emit();
  }

  get productName(): string {
    return this.visualItem.productName(this.language);
  }

  get formattedName(): string {
    return this.visualItem.formattedName(this.language);
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }
}
