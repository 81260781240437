import { Injectable } from '@angular/core';
import { DisplayConfiguration } from '../../../lib/display/configuration/display-configuration';
import { DisplayConfigurationPageCaptionEnum } from '../../../lib/display/configuration/display-configuration-page-caption.enum';
import { DisplayConfigurationExtendedDisplayEnum } from '../../../lib/display/configuration/display-configuration-extended-display.enum';

@Injectable()
export class DisplayConfigurationSimulatorService {

  isStaticModeEnable: boolean = false;
  configurationId: number = 0;
  private _displayConfiguration: DisplayConfiguration;

  get displayConfiguration(): DisplayConfiguration {
    if (!this.isEnable)
      return null;
    return this._displayConfiguration;
  }

  set displayConfiguration(value: DisplayConfiguration) {
    this._displayConfiguration = value;
  }

  get pageCaption(): DisplayConfigurationPageCaptionEnum {
    if (!this.displayConfiguration)
      return null;
    return this._displayConfiguration.pageCaptionType;
  }

  set pageCaption(value: DisplayConfigurationPageCaptionEnum) {
    this._displayConfiguration.pageCaptionType = value;
  }

  get rowCount(): number {
    if (!this.displayConfiguration)
      return 0;
    return this._displayConfiguration.rowCount;
  }

  set rowCount(value: number) {
    if (this.displayConfiguration)
      this._displayConfiguration.rowCount = value;
  }

  get columnsCount(): number {
    if (!this.displayConfiguration)
      return 0;
    return this._displayConfiguration.columnsCount;
  }

  set columnsCount(value: number) {
    if (this.displayConfiguration)
      this._displayConfiguration.columnsCount = value;
  }

  get isEnable(): boolean {
    return this.configurationId != 0;
  }

  set extendedDisplay(value: DisplayConfigurationExtendedDisplayEnum) {
    if (this.displayConfiguration)
      this._displayConfiguration.extendedDisplay = value;
  }

  get extendedDisplay(): DisplayConfigurationExtendedDisplayEnum {
    if (!this.displayConfiguration)
      return null;
    return this._displayConfiguration.extendedDisplay;
  }
}
