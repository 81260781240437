import { Injectable } from '@angular/core';
import { DisplayItemTypeEnum } from '../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../lib/display/display-item/display-item';
import { BaseGridWorkflowService } from '../base-grid-workflow.service';
import { BarcodeReaderService } from '../../../barcode/barcode-reader.service';
import { Subscription } from 'rxjs';

@Injectable()
export class TicketGridWorkflowService extends BaseGridWorkflowService {

  subsrcription: Subscription;
  private barcodeReaderService: BarcodeReaderService;

  init(
  ) {
    super.init();
    this.barcodeReaderService = this.injector.get(BarcodeReaderService);
  }

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.Ticket;
  }

  show(displayItem: DisplayItem, context: any) {
    super.show(displayItem, context);
    this.enableBarcodeScanned();
  }

  hide(displayItem: DisplayItem, context: any) {
    super.hide(displayItem, context);
    this.disabledBarcodeScanned();
  }

  hideAll(): void {
    super.hideAll();
    this.disabledBarcodeScanned();
  }

  get isEnableBarcodeScanned(): boolean {
    return this.subsrcription != null;
  }

  enableBarcodeScanned() {
    if (this.isEnableBarcodeScanned) {
      return;
    }
    this.subsrcription = this.barcodeReaderService.barcodeScanned.subscribe((x: string) => this.onBarcodeScanned(x));
    this.barcodeLedEnable(true);
  }

  disabledBarcodeScanned() {
    if (!this.isEnableBarcodeScanned) {
      return;
    }
    this.barcodeLedEnable(false);
    if (this.subsrcription) {
      this.subsrcription.unsubscribe();
      this.subsrcription = null;
    }
  }

  private barcodeLedEnable(value: boolean) {
    if (this.dispatcherService.vuHttp != null) {
      this.dispatcherService.vuHttp.barcodeLedEnable(value);
    }
  }

  onBarcodeScanned(code: string) {
    this.userActivity();
    if (!this.context) {
      return;
    }
    this.displayGridWorkflowService.updateContext({
      barcode: code
    });

    this.navigateToUrlOrNavigationItem();
  }

  fillExternalApiRequestData(requestData: Map<string, any>, context: any) {
    if (context.barcode) {
      requestData.set('barcode', context.barcode);
    }
  }
}
