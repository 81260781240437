import { Money } from './lib';
import { DictionaryHelper } from './dictionary-helper';
import { TranslatePropertyHelper } from './languages/translation-properties-helper';
import { AgeRestrictionValidator } from './age/age-restriction-validator';

export class Product {
  id: number;
  name: string;
  price: Money;
  tax: number; // 0.19
  subProductId: number;
  accessTimeMinutes: number;
  isPartnerRequired: boolean;
  hasImage: boolean;
  private internalDictionaryHelper: DictionaryHelper;
  additionalProperties: any;
  private translatePropertyHelper: TranslatePropertyHelper;

  private internalSaleAgeRestrictionValidator: AgeRestrictionValidator;
  private internalGroupAgeRestrictionValidator: AgeRestrictionValidator;

  static isProduct(other: any): boolean {
    return other != null && other.constructor.name === Product.name;
  }

  static fromJson(data: any): Product {
    if (data == null || data.id === -1) {
      return null;
    }
    const obj = data;
    return new Product(
      obj.id,
      obj.name,
      Money.fromJSON(obj.price),
      obj.tax,
      obj.subProductId,
      obj.accessTimeMinutes,
      obj.isPartnerRequired,
      obj.hasImage,
      obj.additionalProperties,
    );
  }

  constructor(
    id: number,
    name: string,
    price: Money,
    tax: number,
    subProductId: number,
    accessTimeMinutes: number,
    isPartnerRequired: boolean,
    hasImage: boolean,
    additionalProperties: any = {},
  ) {
    this.id = id;
    this.name = name;
    this.price = price;
    this.tax = tax;
    this.subProductId = subProductId;
    this.accessTimeMinutes = accessTimeMinutes;
    this.isPartnerRequired = isPartnerRequired;
    this.hasImage = hasImage;
    this.internalDictionaryHelper = new DictionaryHelper(additionalProperties);
    this.additionalProperties = additionalProperties;
    this.translatePropertyHelper = new TranslatePropertyHelper(additionalProperties ? additionalProperties.translations : null);
  }

  public toString(): string {
    let m = `Product. id: '${this.id}'. `;
    m += `name: '${this.name}'. `;
    m += `price: '${this.price}'. `;
    m += `tax: '${this.tax}'. `;
    m += `subProductId: '${this.subProductId}' `;
    m += `accessTimeMinutes: '${this.accessTimeMinutes}' `;
    m += `isPartnerRequired: '${this.isPartnerRequired}' `;
    m += `hasImage: '${this.hasImage}' `;
    m += `additionalProperties: ${this.internalDictionaryHelper.additionalProperties}. `;
    return m;
  }

  get displayBirthdayField(): boolean {
    return this.internalDictionaryHelper.getBoolean('display_birthday_field');
  }

  get ticketIsDiscounted(): boolean {
    return this.internalDictionaryHelper.getBoolean('ticket_is_discounted');
  }

  get ticketIsSeasoned(): boolean {
    return this.internalDictionaryHelper.getBoolean('ticket_is_seasoned');
  }

  get quantity(): number {
    return this.internalDictionaryHelper.getNumber('quantity');
  }

  get groupSizeMin(): number {
    return this.internalDictionaryHelper.getNumber('group_size_min');
  }

  get groupSizeMax(): number {
    return this.internalDictionaryHelper.getNumber('group_size_max');
  }

  get ticketType(): string {
    return this.internalDictionaryHelper.getString('ticket_type');
  }

  get buyInOneClick(): boolean {
    return this.internalDictionaryHelper.getBoolean('buy_in_one_click');
  }

  get appointmentEnabled(): boolean {
    return this.internalDictionaryHelper.getBoolean('appointment_enabled');
  }

  get appointmentDateFrom(): Date {
    return this.internalDictionaryHelper.getDate('appointment_date_from_restriction');
  }

  get appointmentDateTo(): Date {
    return this.internalDictionaryHelper.getDate('appointment_date_to_restriction');
  }

  get appointmentKeywords(): string {
    return this.internalDictionaryHelper.getString('appointment_keywords');
  }

  get qtyMin(): number {
    return this.internalDictionaryHelper.getNumber('qty_min');
  }

  get qtyMax(): number {
    return this.internalDictionaryHelper.getNumber('qty_max');
  }

  get ticketDisplayName(): string {
    return this.internalDictionaryHelper.getString('ticket_display_name');
  }

  translateTicketDisplayName(language: string, defaultValue: string = ''): string {
    return this.translatePropertyHelper.getTranslationByFieldName(language, 'ticket_display_name', defaultValue);
  }

  translateName(language: string, defaultValue: string = ''): string {
    return this.translatePropertyHelper.getTranslationByFieldName(language, 'name', defaultValue);
  }

  get additionalProductIds(): number[] {
    return this.internalDictionaryHelper.getNumberArray('additional_product_ids');
  }

  isValidForAge(age: number): boolean {
    if (!this.internalSaleAgeRestrictionValidator) {
      this.internalSaleAgeRestrictionValidator = AgeRestrictionValidator.fromString(
        this.internalDictionaryHelper.getString('sale_age_restriction'));
    }
    return this.internalSaleAgeRestrictionValidator.isAgeValid(age);
  }

  isValidForAgesGroup(ages: number[], groupSize: number): boolean {
    if (!this.internalGroupAgeRestrictionValidator) {
      this.internalGroupAgeRestrictionValidator = AgeRestrictionValidator.fromString(
        this.internalDictionaryHelper.getString('group_age_restriction'));
    }
    return this.internalGroupAgeRestrictionValidator.isAgesValid(ages, groupSize);
  }
}
