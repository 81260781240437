import { Injectable, Injector } from '@angular/core';
import { LoggingService } from './logging/logging.service';
import { CardTerminalSimulator } from '../lib/credit-card/credit-card-terminal-simulator';
import { ExtendedLog } from '../lib/extended-log';
import { IVuConnectionSimulator } from './vu/connection/vu-connection.interfaces';
import { VuCommunicationService } from './vu/vu-communication.service';
import { Money } from '../lib/money/money';
import { CreditCardTerminalState } from '../lib/credit-card/credit-card-terminal-state';
import { CreditCardTerminalEvent } from '../lib/credit-card/credit-card-terminal-event';

@Injectable()
export class CreditCardTerminalSimulatorService {
  private creditCardTerminalSimulator: CardTerminalSimulator;
  private vuSimulator: IVuConnectionSimulator;
  private log: ExtendedLog;

  constructor(
    loggingService: LoggingService,
    private vuCommunicationService: VuCommunicationService
  ) {
    this.log = new ExtendedLog(
      'CreditCardTerminalSimulatorService',
      loggingService
    );
    this.vuSimulator = vuCommunicationService.vuConnectionSimulator;

    this.creditCardTerminalSimulator = new CardTerminalSimulator(
      loggingService
    );

    this.creditCardTerminalSimulator.eventCreditCardTerminalEvent.subscribe(
      (x: CreditCardTerminalEvent) =>
        this.vuSimulator.onCreditCardTerminalEvent(x)
    );
    this.creditCardTerminalSimulator.start();
  }

  get isCreditCardInserted(): boolean {
    return this.creditCardTerminalSimulator.isCardInserted;
  }

  get isCreditCardLocked(): boolean {
    return this.creditCardTerminalSimulator.isCardLocked;
  }

  creditCardInserted() {
    this.creditCardTerminalSimulator.cardInserted();
  }

  creditCardRemoved() {
    this.creditCardTerminalSimulator.cardRemoved();
  }

  beginCardPaymentTransaction(amount: Money) {
    this.creditCardTerminalSimulator.beginCardPaymentTransaction(amount);
  }

  pinEntered(isValid: boolean) {
    this.creditCardTerminalSimulator.pinEntered(isValid);
  }

  cancel() {
    this.creditCardTerminalSimulator.cancel();
  }

  get creditCardTerminalState(): CreditCardTerminalState {
    return this.creditCardTerminalSimulator.creditCardTerminalState;
  }

  revertTransaction() {
    this.creditCardTerminalSimulator.revertTransaction();
  }

  revertingTransactionCompleted() {
    this.creditCardTerminalSimulator.revertingTransactionCompleted();
  }

  revertingTransactionFailed() {
    this.creditCardTerminalSimulator.revertingTransactionFailed();
  }
}
