import { Component, OnInit, Input } from '@angular/core';
import { PinKeyboardComponent } from '../pin-keyboard/pin-keyboard.component';

const SHORTLAYOUT = [
  {
    "row": [
      { "value": "7", "action": "char" },
      { "value": "8", "action": "char" },
      { "value": "9", "action": "char" },
    ]
  },
  {
    "row": [
      { "value": "4", "action": "char" },
      { "value": "5", "action": "char" },
      { "value": "6", "action": "char" },
    ]
  },
  {
    "row": [
      { "value": "1", "action": "char" },
      { "value": "2", "action": "char" },
      { "value": "3", "action": "char" },
    ]
  },
  {
    "row": [
      { "value": "←", "action": "del" },
      { "value": "0", "action": "char" },
      { "value": "", "action": null },
    ]
  },
];


@Component({
  selector: 'cash-keyboard',
  templateUrl: './cash-keyboard.component.html',
  styleUrls: ['./cash-keyboard.component.css']
})
export class CashKeyboardComponent extends PinKeyboardComponent {

  layout: any[] = SHORTLAYOUT;
  @Input() min;
  @Input() max; 


}
