import { DtoVuConfiguration } from '../../lib/configuration';
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Configuration, VuRole } from '../../lib/lib';
import { Router } from '@angular/router';
import { DispatcherService } from '../dispatcher.service';
import { LanguageService } from '../language.service';
import { IVuHttp } from '../vu/http/vu-http.interface';
import { LoggingService } from '../logging/logging.service';
import { IVuConnection } from '../vu/connection/vu-connection.interfaces';
import { VuCommunicationService } from '../vu/vu-communication.service';
import { TicketInfoTwoSidedComponent } from '../../components/ticket/ticket-info-two-sided/ticket-info-two-sided.component';
import { ScreenSaverService } from '../screen-saver.service';
import { BarcodeInputMethod } from '../../lib/barcode-reader/barcode-input-method';
import { OneLineArticleSaleMode } from '../../lib/one-line-article-sale-mode';
import { DemoWanzlRootComponent } from '../../components/demo-wanzl/demo-wanzl-root/demo-wanzl-root.component';
import { Asset } from '../../lib/assets/asset';


@Injectable()
export class ConfigurationService {
  private dispatcherService: DispatcherService;
  private languageService: LanguageService;
  private vuHttp: IVuHttp;
  private log: LoggingService;
  private vuConnection: IVuConnection;
  private vuCommunicationService: VuCommunicationService;
  private router: Router;
  private screenSaverService: ScreenSaverService;
  configuration: Configuration = new Configuration();
  private _lastCustomCssStyle: string;

  constructor(
    private http: HttpClient,
    private injector: Injector,
  ) {
  }

  load(): Promise<any> {
    return this.http.get('./config.json')
      .map((res) => res)
      .toPromise()
      .then(x => {
        this.configuration = Configuration.fromOther(x);
        this.router = this.injector.get(Router);
        this.updateRoute();
        this.languageService = this.injector.get(LanguageService);
        this.dispatcherService = this.injector.get(DispatcherService);
        this.dispatcherService.configurationUpdate(this.configuration);
        this.log = this.injector.get(LoggingService);
        this.dispatcherService.onConfigurationChangedSubscribe((y: Configuration) => {
          this.configuration = y;
          this.log.info(`ConfigurationService. Configuration:  ${y}`);
        });
        this.vuCommunicationService = this.injector.get(VuCommunicationService);
        this.vuHttp = this.vuCommunicationService.vuHttp;
        this.getConfiguration();
        this.updateScreenSaverConfiguration();
        this.vuConnection = this.vuCommunicationService.vuConnection;
        this.vuConnection.eventConnectionChanged.subscribe((isConnected: boolean) => this.onConnectionChanged(isConnected));
        this.vuConnection.eventVuConfigurationChanged.subscribe((x: DtoVuConfiguration) => this.onVuConfigurationChanged(x));
        this.screenSaverService = this.injector.get(ScreenSaverService);
      });
  }

  private onConnectionChanged(isConnected: boolean) {
    if (isConnected) {
      this.getConfiguration();
      this.updateScreenSaverConfiguration();
    }

  }

  private onVuConfigurationChanged(x: DtoVuConfiguration) {
    this.updateDisplayConfiguration(x);
    this.updateConfiguration(x);
    this.updateScreenSaverConfiguration();
  }

  private getConfiguration() {
    this.vuHttp.getVuConfiguration().then((x) => {
      this.updateConfiguration(x);
    });
  }

  private updateConfiguration(x: DtoVuConfiguration) {
    this.configuration.backgroundId = x.backgroundId;
    this.dispatcherService.configurationVuRole(x.vuRole);
    this.dispatcherService.configurationLocale(x.locale);
    this.dispatcherService.configurationShowArticlesOnMainPage(x.showArticlesOnMainPage);
    this.dispatcherService.configurationPrintCardTerminalReceipt(x.printCardTerminalReceipt);
    this.dispatcherService.configurationPrintOrderReceipt(x.printOrderReceipt);
    this.dispatcherService.configurationOneLineArticleSaleMode(x.oneLineArticleSaleMode);
    this.dispatcherService.configurationBarcodeInputMethod(x.barcodeInputMethod);
    this.dispatcherService.configurationTicketPrinterType(x.ticketPrinterType);
    this.dispatcherService.configurationReceiptPrinterType(x.receiptPrinterType);
    this.dispatcherService.configurationCustomCss(x.customCss);
    this.dispatcherService.configurationBackgorundId(x.backgroundId);
    this.dispatcherService.configurationAdditionalProperties(x.additionalProperties);
    this.dispatcherService.configurationCustomLogoId(x.customLogoId);
    this.languageService.defaultLanguageId = x.locale;
    this.languageService.setSelectedLanguageById(x.locale);
    this.updateDisplayConfiguration(x);
    this.updateAssetsConfiguration(x);
    this.updateCustomCss();
  }

  updateCustomCss() {
    if (!this.configuration)
      return;

    let customCss = this.configuration.customCss || '';

    if (this._lastCustomCssStyle == customCss)
      return;

    this._lastCustomCssStyle = customCss;

    let style = document.getElementById('customCssStyle');
    if (style) {
      style.innerHTML = customCss;
    } else {
      let head = document.getElementsByTagName('head')[0];
      head.innerHTML += `<style id='customCssStyle' type="text/css">${customCss}</style>`;
    }
  }

  updateScreenSaverConfiguration() {
    this.vuHttp.getScreenSaverConfiguration().then((x) => {
      this.screenSaverService.configuration = x;
    });
  }

  private updateDisplayConfiguration(x: DtoVuConfiguration) {
    this.configuration.displayConfiguration = x.displayConfiguration;
    this.configuration.displayConfigurationId = x.displayConfigurationId;
  }

  private updateAssetsConfiguration(x: DtoVuConfiguration) {
    this.configuration.assets = x.assets ? x.assets.map(Asset.fromAny) : null;
  }

  private updateRoute() {
    let customRoutes = this.configuration.customRoutes;
    if (customRoutes == null || this.router.config == null) return;

    let routerConfig = this.router.config.map(x => Object.assign({}, x));
    for (let customRoute of customRoutes) {
      let component = this.getComponent(customRoute['component']);
      if (component == null) continue;
      customRoute['component'] = component;

      let path = customRoute['path'];
      if (path == null) continue;
      let items = routerConfig.filter(x => x.path == path);
      let item = items.length > 0 ? items[0] : null;
      if (item == null) continue;
      let index = routerConfig.indexOf(item, 0);
      routerConfig[index] = customRoute;
    }
    this.router.resetConfig(routerConfig);
  }

  private getComponent(cls: string): any {
    if (cls == null) return;
    switch (cls) {
      case 'DemoWanzlRootComponent':
        return DemoWanzlRootComponent;
      case 'TicketInfoTwoSidedComponent':
        return TicketInfoTwoSidedComponent;
      default:
        return null;
    }
  }

  get isRoleExit(): boolean {
    return this.configuration.vuRole === VuRole.Exit;
  }

  get showLanguageButton(): boolean {
    return this.configuration.showLanguageButton;
  }

  get defaultShopTitle(): string {
    return this.configuration.defaultShopTitle;
  }

  get showArticlesOnMainPage(): boolean {
    return this.configuration.showArticlesOnMainPage;
  }

  get oneLineArticleSaleMode(): OneLineArticleSaleMode {
    return this.configuration.oneLineArticleSaleMode;
  }

  get barcodeInputMethod(): BarcodeInputMethod {
    return this.configuration.barcodeInputMethod;
  }
}
