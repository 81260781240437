import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { VuState, NonOperationalState } from '../lib/lib';
import { DispatcherService } from './dispatcher.service';
import { LoggingService } from './logging/logging.service';


@Injectable()
export class NonOperationalService {
  private iState: NonOperationalState;
  protected dispatcherService: DispatcherService;
  protected log: LoggingService;
  eventStateChanged: Subject<NonOperationalState> = new Subject();

  constructor(
    protected injector: Injector,
  ) {
    this.dispatcherService = this.injector.get(DispatcherService);
    this.log = this.injector.get(LoggingService);
    this.dispatcherService.eventServiceInitialized.subscribe(() => this.onDispatcherServiceInitialized());
  }

  private onDispatcherServiceInitialized() {
    this.dispatcherService.onVuStateChangedSubscribe(x => this.onVuStateChanged(x));
    this.dispatcherService.vuConnection.eventConnectionChanged.subscribe((isConnected: boolean) => {
      this.checkVuState(isConnected);
    });

    this.checkVuState(this.dispatcherService.vuConnection.isConneсted);
  }

  private checkVuState(isConnected: boolean): void {
    const prevIsConnected = this.state.isConnected;
    this.state = this.state.fromConnected(isConnected);
    if (isConnected && prevIsConnected !== isConnected) {
      this.dispatcherService.vuHttp.getVuState()
        .then(state => {
          this.dispatcherService.vuStateSet(state);
        });
    }
  }

  get state(): NonOperationalState {
    if (this.iState == null) {
      this.iState = new NonOperationalState(new VuState(), true);
    }
    return this.iState;
  }

  set state(value: NonOperationalState) {
    if (this.iState === value) {
      return;
    }

    this.iState = value;
    this.log.info(`NonOperationalService. ${value}`);
    this.eventStateChanged.next(value);
  }

  private onVuStateChanged(vuState: VuState) {
    this.state = this.state.fromVuState(vuState);
  }
}
