import { Component, OnInit, Input, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core';
import { StepConfiguration } from '../../../lib/lite/step-configuration';
import { CarouselComponent as OriginalCarouselComponent } from 'ngx-carousel-lib';
import { LiteDisplayStepsService } from '../../../services/lite/lite-display-steps.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit, OnDestroy {

  @ViewChild('carousel', {static: false})
  carousel: OriginalCarouselComponent;

  initialSlide: number;
  selectedIndex: number;

  @Output()
  swipeSlideLeft: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  swipeSlideRight: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  tileClick: EventEmitter<StepConfiguration> = new EventEmitter<StepConfiguration>();

  private displayStepsServiceSubscription: Subscription;
  private carouselSlideChangeSubscription: Subscription;

  constructor(
    private liteDisplayStepsService: LiteDisplayStepsService,
  ) {
    this.selectedIndex = this.initialSlide = 1;
  }

  ngOnInit() {
    this.displayStepsServiceSubscription = this.liteDisplayStepsService.changed.subscribe(
      () => {
        setTimeout(() => {
          if (this.selectedIndex != this.liteDisplayStepsService.selectedStepIndex) {
            this.carousel.slideTo(this.liteDisplayStepsService.selectedStepIndex);
          }

          this.carousel.update();
        })
      }
    );
    this.carouselSlideChangeSubscription = this.carousel.onSlideChange.subscribe(
      () => {
        this.selectedIndex = this.carousel.carousel.activeIndex;
      }
    )
  }

  ngOnDestroy(): void {
    this.displayStepsServiceSubscription.unsubscribe();
    this.carouselSlideChangeSubscription.unsubscribe();
  }

  get steps(): StepConfiguration[] {
    return this.liteDisplayStepsService.visibleSteps;
  }

  outerImage(index: number) {
    if (
      this.selectedIndex === index
    ) {
      return '/static/images/svg/current_step_template.svg';
    }

    return '/static/images/svg/step_template.svg';
  }

  onSlideMoveEnd(event: any) {
    const activeIndex = event.carousel.activeIndex;

    if (activeIndex == this.liteDisplayStepsService.selectedStepIndex)
      return;

    if (activeIndex > this.liteDisplayStepsService.selectedStepIndex) {
      this.swipeSlideRight.emit();
    }

    if (activeIndex < this.liteDisplayStepsService.selectedStepIndex) {
      this.swipeSlideLeft.emit();
    }

    this.carousel.slideTo(this.liteDisplayStepsService.selectedStepIndex);
  }

  onTileClick(step: StepConfiguration) {
    this.tileClick.emit(step);
  }
}
