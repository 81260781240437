import { TouchTileType } from "./touch-tile-type";
import { Color } from "../color";

export class TouchTileColor {

  constructor(
    public name: TouchTileType = TouchTileType.LogoLight,
    public color: String = "#ffffff",
  ) {
  }

  toJSON(): any {
    return {
      name: this.name,
      color: Color.colorToHex(this.color),
    };
  }
  static fromOther(other: TouchTileColor): TouchTileColor {
    return Object.assign(new TouchTileColor(), other);
  }

  public toString(): string {
    return `name: '${this.name}' color:'${this.color}'`;
  }
}

