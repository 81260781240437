export const DEFAULT_TIMEOUT = -1; // Not Set

export class MachineInactivitySettings {
  customTimeout = DEFAULT_TIMEOUT;
  skipToOpenTimeoutModal = false;

  constructor(customTimeout = DEFAULT_TIMEOUT, skipToOpenTimeoutModal = false) {
    this.customTimeout = customTimeout;
    this.skipToOpenTimeoutModal = skipToOpenTimeoutModal;
  }
}

export class MachineInactivity {
  isStartTracking = false;
  machineName: string;
  machineState: string;
  customTimeout = DEFAULT_TIMEOUT;
  skipToOpenTimeoutModal = false;

  constructor(
    isStartTracking: boolean,
    machineName: string,
    machineState: string,
    customTimeout = DEFAULT_TIMEOUT,
    skipToOpenTimeoutModal = false) {
    this.isStartTracking = isStartTracking;
    this.machineName = machineName;
    this.machineState = machineState;
    this.customTimeout = customTimeout;
    this.skipToOpenTimeoutModal = skipToOpenTimeoutModal;
  }

  toString(): string {
    let m = ` machine: ${this.machineName} (${this.machineState}).`;
    m += `isStartTracking: ${this.isStartTracking}`;
    if (this.customTimeout) {
      m += ` customTimeout: ${this.customTimeout}.`;
    }
    if (this.skipToOpenTimeoutModal) {
      m += ` skipToOpenTimeoutModal: ${this.skipToOpenTimeoutModal}.`;
    }
    return m;
  }
}
