import { Injectable, Injector } from '@angular/core';

import { IDisplayGridWorkflowService } from './display-grid-workflow.interface';
import { DisplayItemTypeEnum } from '../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../lib/display/display-item/display-item';
import { DisplayGridWorkflowService } from './display-grid-workflow.service';
import { DisplayItemTree } from '../../../lib/display/display-item/display-item-tree';
import { DictionaryHelper } from '../../../lib/dictionary-helper';
import { VuCommunicationService } from '../../vu/vu-communication.service';
import { DispatcherService } from '../../dispatcher.service';
import { LoadingSpinnerService } from '../../loading-spinner/loading-spinner.service';
import { NotificationService } from '../../notification/notification.service';
import { NotificationMessageTypeEnum } from '../../../lib/notification-message/notification-message-type.enum';

@Injectable()
export class BaseGridWorkflowService implements IDisplayGridWorkflowService {

  protected displayGridWorkflowService: DisplayGridWorkflowService;
  protected vuCommunicationService: VuCommunicationService;
  protected dispatcherService: DispatcherService;
  protected loadingSpinnerService: LoadingSpinnerService;

  private hardResetDelayAction: any;

  protected context: any;
  protected displayItem: DisplayItem;

  protected notificationService: NotificationService;

  constructor(
    protected injector: Injector,
  ) {
    this.init();
  }

  init() {
    this.vuCommunicationService = this.injector.get(VuCommunicationService);
    this.dispatcherService = this.injector.get(DispatcherService);
    this.loadingSpinnerService = this.injector.get(LoadingSpinnerService);
    this.notificationService = this.injector.get(NotificationService);
  }

  get displayItemType(): DisplayItemTypeEnum {
    return null;
  }

  initialize(displayGridWorkflowService: DisplayGridWorkflowService) {
    this.displayGridWorkflowService = displayGridWorkflowService;
  }

  processAction(displayItem: DisplayItem, context: any): boolean {
    if (displayItem == null || displayItem.type !== this.displayItemType) {
      return false;
    }

    return true;
  }

  showDisplayItems(displayItems: DisplayItem[], show: boolean, context: any): void {
    if (displayItems == null) {
      this.hideAll();
      return;
    }

    for (const displayItem of displayItems) {
      if (displayItem.type === this.displayItemType) {
        if (show) {
          this.show(displayItem, context);
        } else {
          this.hide(displayItem, context);
        }
        break;
      }
    }
  }

  show(displayItem: DisplayItem, context: any): void {
    this.context = context;
    this.displayItem = displayItem;
  }

  hide(displayItem: DisplayItem, context: any): void {
    this.context = null;
    this.displayItem = null;
  }

  hideAll(): void {
    this.context = null;
    this.displayItem = null;
  }

  showDataInDisplayGrid(data: any, context: any) {
    const dictionaryHelper = new DictionaryHelper(data);
    const displayItemTree = DisplayItemTree.build(
      dictionaryHelper.getProperty('items'),
      dictionaryHelper.getProperty('items_tree'),
      dictionaryHelper.getProperty('additional_items_tree'),
      this.displayGridWorkflowService.gridVisualItemsComponent.displayConfiguration.allItems,
    );

    displayItemTree.fillProducts(this.vuCommunicationService);
    context.displayItemInfo = data;
    if (this.displayGridWorkflowService && this.displayGridWorkflowService.gridVisualItemsComponent &&
      displayItemTree.nodes && displayItemTree.nodes.length === 1) {
      this.displayGridWorkflowService.gridVisualItemsComponent.showAdditionDisplayItem(displayItemTree.nodes[0]);
    }
  }

  nextStep(displayItem: DisplayItem): void {
    if (!displayItem) {
      return;
    }

    this.showDisplayItem(displayItem.navigationItem);
  }

  showDisplayItem(displayItem: DisplayItem): void {
    if (!displayItem || displayItem.type !== DisplayItemTypeEnum.Category) {
      return;
    }

    this.displayGridWorkflowService.gridVisualItemsComponent.showAdditionDisplayItem(displayItem);
  }

  navigateToRootPage(): void {
    this.displayGridWorkflowService.gridVisualItemsComponent.navigateToRootPage();
  }

  reset(): void {
  }

  fillExternalApiRequestData(requestData: Map<string, any>, context: any) {
  }


  updateContext(context: any) {
  }

  get externalApiRequestData(): Map<string, any> {
    return this.displayGridWorkflowService.buildExternalApiRequestData();
  }

  fillDisplayItemRenderData(displayItem: DisplayItem, data: Map<string, any>, context: any) {
  }

  userActivity() {
    if (this.dispatcherService) {
      this.dispatcherService.onUserActivity();
    }

    this.displayGridWorkflowService.restartInactivityTimer();
  }

  get canDoHardReset(): boolean {
    return true;
  }

  protected delayedHardReset() {
    if (this.hardResetDelayAction) {
      if (typeof (this.hardResetDelayAction) === 'function') {
        this.hardResetDelayAction();
      }
      this.hardResetDelayAction = null;
    }
  }

  protected get hardResetProccessDisplayItem(): DisplayItem {
    return null;
  }

  hardReset(action: () => void): void {
    const displayItem = this.hardResetProccessDisplayItem;
    if (displayItem) {
      this.hardResetDelayAction = action;
      this.showDisplayItem(displayItem);
      return;
    }

    if (typeof (action) === 'function') {
      action();
    }
  }

  findDisplayItemByUniqueName(uniqueName: string): DisplayItem {
    const parentDisplayItem = this.displayGridWorkflowService.parentDisplayItem;
    if (parentDisplayItem && parentDisplayItem.children) {
      return parentDisplayItem.children.find(x => x.uniqueName === uniqueName);
    }
    return null;
  }

  navigateToUrlOrNavigationItem(): void {
    if (this.displayItem.apiUrl == null || this.displayItem.apiUrl.trim().length === 0) {
      if (this.displayItem.navigationItem) {
        this.nextStep(this.displayItem);
      }
    } else {
      this.loadingSpinnerService.show();
      this.vuCommunicationService.ExternalApiService.sendPostRequestOdooJson(this.displayItem.apiUrl, this.externalApiRequestData)
        .then((result) => {
          if (result) {
            this.context.odooContext = result.context;
          }
          this.showDataInDisplayGrid(result, this.context);
          this.loadingSpinnerService.hide();
        })
        .catch((error: any) => {
          this.loadingSpinnerService.hide();
          this.showErrorMessage(error);
        });
    }
  }

  showErrorMessage(error: any): void {
    this.notificationService.show('Communication error', NotificationMessageTypeEnum.Error);
  }
}
