import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';
import { Teaser, TeaserType } from '../../../lib/lib';
import { LoggingService } from '../../../services/logging/logging.service';
import { DispatcherService } from '../../../services/dispatcher.service';

const intervals = [2000, 150, 100, 150];

@Component({
  moduleId: module.id,
  selector: 'teaser',
  templateUrl: 'teaser.component.html',
  styleUrls: ['teaser.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TeaserComponent implements OnInit, OnDestroy {
  private timer: any = null;
  private teaserSubscription: Subscription;
  private intervalIndex = 0;
  cssClass = '';
  teaser = new Teaser();

  constructor(
    private log: LoggingService,
    private dispatcherService: DispatcherService,
  ) {
  }
  ngOnInit(): void {
    this.teaserSubscription = this.dispatcherService.onTeaserChangedSubscribe(x => this.onTeaserChangedSubscribe(x));
  }

  ngOnDestroy() {
    this.teaserSubscription.unsubscribe();
    this.stopTimer();
  }

  onTeaserChangedSubscribe(teaser: Teaser) {
    if (teaser == null) {
      this.teaser = new Teaser();
      return;
    }
    this.teaser = teaser;
    if (teaser.items.length === 0) {
      this.stopTimer();
    } else if (this.timer == null) {
      this.startTimer();
    }
  }

  startTimer() {
    let scope = this;
    this.timer = setTimeout(() => {
      scope.cssClass = scope.calculateCssClass;
      scope.startTimer();
    }, scope.getNextIterval());
  }

  get calculateCssClass(): string {
    if (this.intervalIndex % 2 === 0)
      return 'teaser-normal';

    if (this.teaser && this.teaser.items &&
      (this.teaser.items.includes(TeaserType.ReturnMoney) || this.teaser.items.includes(TeaserType.RevertCardTerminalTransaction)))
      return 'teaser-highlight-red';

    return 'teaser-highlight';
  }

  stopTimer() {
    if (this.timer != null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  getNextIterval(): number {
    if (this.intervalIndex > intervals.length - 1) {
      this.intervalIndex = 0;
    }
    return intervals[this.intervalIndex++];
  }
}
