import { Component, OnInit } from '@angular/core';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';

@Component({
  selector: 'app-logo-update-simulator',
  templateUrl: './logo-update-simulator.component.html',
  styleUrls: ['./logo-update-simulator.component.css']
})
export class LogoUpdateSimulatorComponent implements OnInit {
  logoId: string;

  constructor(public vuCommunicationService:
    VuCommunicationService, ) { }

  ngOnInit() {
  }

  onClick() {
    this._updateConfiguration();
  }

  _updateConfiguration() {
    this.vuCommunicationService.vuHttp.getVuConfiguration().then(
      result => {
        if (!result)
          return;
        if (this.logoId)
          result.customLogoId = parseInt(this.logoId) || 0;
        else
          result.customLogoId = null;
        this.vuCommunicationService.vuConnectionSimulator.vuConfigurationChanged(result);
      }
    )
  }
}
