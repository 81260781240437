import { TicketUseDirection } from './ticket-use-direction';

export class TicketUse {
  dateTime: Date;
  direction: TicketUseDirection;

  static fromOther(other: TicketUse): TicketUse {
    return Object.assign(new TicketUse(), other);
  }

  static fromJson(json: any): TicketUse {
    return new TicketUse(new Date(json.dateTime), json.direction);
  }

  static toString(ticketUseArray: TicketUse[]): string {
    if (ticketUseArray == null) { return ''; }

    let result = '';
    for (const item of ticketUseArray) {
      result += `\n ${item}`;
    }
    return result;
  }

  constructor(dateTime = new Date(), direction = TicketUseDirection.Entry) {
    this.dateTime = dateTime;
    this.direction = direction;
  }

  get dateEntry(): Date {
    return this.direction === TicketUseDirection.Entry ? this.dateTime : null;
  }

  get dateExit(): Date {
    return this.direction === TicketUseDirection.Exit ? this.dateTime : null;
  }

  toString(): string {
    return `'${this.direction}'. '${this.dateTime}'`;
  }
}
