import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DisplayItem } from '../../../lib/display/display-item/display-item';
import { PaymentSession, Money, GlobalSettings, CashType } from '../../../lib/lib';
import { Subscription } from 'rxjs';
import { CashPaymentGridWorkflowService } from '../../../services/display/grid/cash-payment/cash-payment-grid-workflow.service';

@Component({
  selector: 'app-grid-visual-item-cash-payment',
  templateUrl: './grid-visual-item-cash-payment.component.html',
  styleUrls: ['./grid-visual-item-cash-payment.component.css']
})
export class GridVisualItemCashPaymentComponent implements OnInit {
  @Input()
  displayItem: DisplayItem;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) {

  }

  ngOnInit() {
  }

}
