import { MachineBaseService } from './machine-base.service';
import { MachinePostSaleService } from './machine-post-sale.service';
import { Money, PaymentMethod, Configuration } from '../../lib/lib';
import { SaleService } from '../sale.service';
import { MessageType, Message } from '../message.service';
import { StoreHistoryService } from '../store-history.service';
import { MachineSaleCreditCardService, CreditCardTransactionResult } from './machine-sale-credit-card.service';
import { OneLineArticleSaleMode } from '../../lib/one-line-article-sale-mode';
import { MachineScreenSaverService } from './machine-screen-saver.service';
import { ModalService } from '../gui/modal/modal-service';

export class MachineSaleShopService extends MachineBaseService {
  private isStopRequested = false;
  private saleService: SaleService;
  private machinePostSaleService: MachinePostSaleService;
  private machineSaleCreditCard: MachineSaleCreditCardService;
  private storeHistoryService: StoreHistoryService;
  private machineScreenSaverService: MachineScreenSaverService;
  private modalService: ModalService;

  init(): void {
    this.saleService = this.injector.get(SaleService);

    this.machineSaleCreditCard = this.injector.get(MachineSaleCreditCardService);
    this.machineSaleCreditCard.eventSwitchedOff.subscribe(() => this.onMachineSaleCreditCardSwitchedOff());
    this.machineSaleCreditCard.eventCreditCardTransactionEnd.subscribe(
      (x: CreditCardTransactionResult) => this.onEventCreditCardTransactionEnd(x));

    this.machinePostSaleService = this.injector.get(MachinePostSaleService);
    this.machinePostSaleService.eventSwitchedOff.subscribe(() => this.onMachinePostSaleSwitchedOff());

    this.storeHistoryService = this.injector.get(StoreHistoryService);
    super.init();
    this.saleService.eventMoneyChanged.subscribe((x: Money) => this.onMoneyChanged(x));

    this.dispatcherService.onConfigurationChangedSubscribe(x => this.onConfigurationChanged(x));
    this.dispatcherService.eventUserActivity.subscribe(() => this.onUserActivity());

    this.machineScreenSaverService = this.injector.get(MachineScreenSaverService);
    this.modalService = this.injector.get(ModalService);
  }

  get machineName(): string {
    return 'Sale Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toIdle', from: ['off', 'basket', 'displayMode'], to: 'idle' },
      { name: 'toDisplayMode', from: ['idle', 'displayModePostSale'], to: 'displayMode' },
      { name: 'toBasket', from: ['off', 'idle', 'displayMode', 'postSale', 'paymentCard'], to: 'basket' },
      { name: 'toPaymentCash', from: ['basket', 'idle', 'displayMode'], to: 'paymentCash' },
      { name: 'toPaymentCard', from: ['basket', 'idle', 'displayMode'], to: 'paymentCard' },
      { name: 'toPostSale', from: ['paymentCash', 'paymentCard'], to: 'postSale' },
      { name: 'toDisplayModePostSale', from: ['displayMode', 'paymentCash', 'paymentCard'], to: 'displayModePostSale' },
    );
  }

  protected getMethods(): any {
    const scope = this;
    return super.getMethods({
      onToOff: (event: any, isHardReset = false) => {
        scope.saleService.closePaymentSession();
        scope.isStopRequested = false;
        scope.storeHistoryService.reset();
        scope.do(() => scope.router.navigate([scope.storeUrl, { displayMode: 'MainScreen' }]), 'onToIdle');
      },
      onToIdle: () => {
        if (scope.saleService.order.isPrefilledOrder) {
          scope.doAsync(() => scope.machine.toBasket(), 'onToIdle');
          return;
        }
        scope.do(() => scope.router.navigateByUrl(scope.storeUrl), 'onToIdle');
        if (scope.configurationService.showArticlesOnMainPage) {
          scope.doAsync(() => {
            if (scope.storeHistoryService.isEmpty) {
              scope.machineScreenSaverService.machineStart();
            }
          }, 'onToIdle');
        } else {
          if (this.gridMode) {
            scope.doAsync(() => {
              scope.machine.toDisplayMode();
            }, 'onToIdle');
          }
        }
      },
      onToBasket: () => {
        scope.do(() => scope.router.navigateByUrl('/basket'), 'onToBasket');
      },
      onToPaymentCash: () => {
        scope.saleService.openPaymentSession(PaymentMethod.Cash, scope.saleService.order.amountTotal);
        scope.do(() => scope.router.navigateByUrl('/payment-cash'), 'onToPaymentCash');
        scope.log.info(`MachineSaleShopService. Pay Order: ${scope.saleService.order}`);
      },
      onToPaymentCard: () => {
        scope.doAsync(() => scope.machineSaleCreditCard.machineStart(), 'onToPaymentCard');
      },
      onToPostSale: () => {
        scope.vuHttp.cashDevicesPayIn(false);
        scope.do(() => scope.machinePostSaleService.machineStart(), 'onToPostSale');
      },
      onToDisplayModePostSale: (event: any, abortPayment: boolean = false) => {
        scope.vuHttp.cashDevicesPayIn(false);
        scope.machinePostSaleService.disableNavigation = true;
        scope.saleService.paymentSession.isCancelled = abortPayment;
        scope.do(() => scope.machinePostSaleService.machineStart(), 'onToDisplayModePostSale');
      },
    });
  }

  get storeUrl(): string {
    if (this.gridMode) {
      return '/grid-visual-items/store';
    } else {
      return '/visual-items/store';
    }
  }

  get gridMode(): boolean {
    return this.configurationService && this.configurationService.configuration
      && !!this.configurationService.configuration.displayConfigurationId;
  }

  machineStart() {
    if (this.isOff) {
      this.machine.toIdle();
    }
  }

  machineStop() {
    if (this.isOff) {
      return;
    }
    this.isStopRequested = true;
    this.machineTryToStop();
  }

  machineStopCancel() {
    this.isStopRequested = false;
  }

  private machineTryToStop() {
    if (this.isStopRequested
      &&
      !this.isInState('paymentCash', 'postSale', 'paymentCard', 'displayModePostSale')
      &&
      !this.isDisplayModePaymentStarted
    ) {
      this.doAsync(() => this.machine.toOff(), 'machineTryToStop');
    }
  }

  private get isDisplayModePaymentStarted(): boolean {
    return this.isInState('displayMode')
      && !!this.saleService.paymentSession
      && this.saleService.paymentSession.isSessionOpen;
  }

  protected onAfterTransition(event: any) {
    this.machineTryToStop();
    super.onAfterTransition(event);
  }

  onMachinePostSaleSwitchedOff() {
    if (this.state === 'postSale') {
      if (this.machinePostSaleService.isBackToHomeAfterSale) {
        this.machine.toOff();
      } else {
        if (this.saleService.buyOrderInOneClick) {
          this.saleService.closePaymentSession();
          this.machine.toOff();
        } else {
          this.machine.toBasket();
        }
      }
    }

    if (this.state === 'displayModePostSale') {
      this.machine.toDisplayMode();
      this.dispatcherService.displayModePaymentComplete(!this.saleService.paymentSession.isCancelled);
    }
  }

  onMachineSaleCreditCardSwitchedOff() {
  }

  onEventCreditCardTransactionEnd(transactionResult: CreditCardTransactionResult) {
    if (this.state !== 'paymentCard') {
      return;
    }
    const scope = this;
    this.machineSaleCreditCard.switchOff(() => {
      switch (transactionResult) {
        case CreditCardTransactionResult.PaymentAborted:
        case CreditCardTransactionResult.Canceled:
          scope.machine.toBasket();
          scope.enableBackButton(true);
          break;
        case CreditCardTransactionResult.PaymentCompleted:
          scope.machine.toPostSale();
          break;
        default:
          scope.log.error(`onEventCreditCardTransactionEnd.  CreditCardTransactionResult not supported: '${transactionResult}'`);
      }
    });
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(
      MessageType.ButtonBackClicked,
      MessageType.Back,
      MessageType.VisualItemLeafSelected,
      MessageType.ToPaymentCash,
      MessageType.ToPaymentCard,
      MessageType.DisplayModeScreenUpdate,
      MessageType.ToBasket,
      MessageType.ToDisplayModePayment,
    );
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    const state = this.state;
    switch (message.messageType) {
      case MessageType.Back:
        this.machine.toOff();
        break;
      case MessageType.ButtonBackClicked:
        if (state === 'paymentCash') {
          this.saleService.paymentSession.isCancelled = true;
          this.machine.toPostSale();
        } else if (state === 'basket') {
          if (this.isExitRole) {
            this.machine.toOff();
          } else {
            if (this.configurationService.configuration.oneLineArticleSaleMode !== OneLineArticleSaleMode.Disabled) {
              this.saleService.closePaymentSession();
            }
            this.machine.toIdle();
          }
        }
        break;
      case MessageType.VisualItemLeafSelected:
        if (this.saleService.buyOrderInOneClick) {
          this.machine.toPaymentCash();
          break;
        }
        if (state === 'idle' || state === 'displayMode') {
          this.machine.toBasket();
        }
        break;
      case MessageType.ToPaymentCash:
        if (state === 'basket') {
          this.machine.toPaymentCash();
        }
        break;
      case MessageType.ToPaymentCard:
        if (state === 'basket') {
          this.machine.toPaymentCard();
        }
        break;
      case MessageType.DisplayModeScreenUpdate:
        switch (state) {
          case 'idle':
            if (!this.storeHistoryService.isEmpty) {
              this.machine.toDisplayMode();
            }
            break;
          case 'displayMode':
            if (this.storeHistoryService.isEmpty) {
              this.modalService.closeAll();
              this.machine.toIdle();
            }
            break;
        }
        break;
      case MessageType.ToBasket:
        if (state === 'idle') {
          this.machine.toBasket();
        }
        break;
      case MessageType.ToDisplayModePayment:
        if (state === 'idle' || state === 'displayMode') {
          const abortPayment = message.info as boolean;
          this.machine.toDisplayModePostSale(abortPayment);
        }
        break;
      default:
        break;
    }

    return false;
  }

  protected get timeoutTrackingStates(): string[] {
    if (this.configurationService.showArticlesOnMainPage) {
      return ['basket', 'paymentCash'];
    }
    return ['basket', 'idle', 'paymentCash'];
  }

  private onMoneyChanged(money: Money) {
    if (this.state !== 'paymentCash') {
      return;
    }

    this.dispatcherService.onUserActivity();

    const paymentSession = this.saleService.paymentSession;
    if (!paymentSession) {
      this.log.error('MachineSaleShopService. !paymentSession');
      return;
    }

    if (paymentSession.amountRemainingToPay.value <= 0) {
      this.machine.toPostSale();
      this.storeHistoryService.reset();
    }
  }

  protected onMachineTimeoutModalCancel(machineName: string) {
    if (machineName === this.machineName && (this.state === 'paymentCash')) {
      this.saleService.paymentSession.isCancelled = true;
      this.machine.toPostSale();
      return;
    }
    super.onMachineTimeoutModalCancel(machineName);
  }

  private enableBackButton(isEnabled: boolean) {
    this.dispatcherService.isBackButtonEnabled = isEnabled;
  }

  onConfigurationChanged(configuration: Configuration) {
    if (this.state === 'idle' || this.state === 'displayMode') {

      if (!configuration.displayConfigurationId) {
        this.router.navigateByUrl('/visual-items/store');
      } else {
        this.router.navigateByUrl('/grid-visual-items/store');
      }

      this.storeHistoryService.checkAndRequestVisualItemsUpdate(configuration);
    }
  }

  private onUserActivity() {
    this.machineScreenSaverService.machineStop();
    if (this.configurationService.showArticlesOnMainPage && this.storeHistoryService.isEmpty) {
      this.machineScreenSaverService.machineStart();
    }
  }
}
