import { Component, OnInit, OnDestroy } from '@angular/core';
import { DispatcherService } from '../../../services/dispatcher.service';
import { StoreHistoryService } from '../../../services/store-history.service';
import { StoreTreeState } from '../../../lib/store-tree-state';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { VisualItem, Configuration } from '../../../lib/lib';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-demo-wanzl-root',
  templateUrl: './demo-wanzl-root.component.html',
  styleUrls: ['./demo-wanzl-root.component.css']
})
export class DemoWanzlRootComponent implements OnInit, OnDestroy {

  backButtonVisible: boolean;
  categories: VisualItem[];
  private subscriptionConfigurationChanged: Subscription;

  constructor(
    private dispatcherService: DispatcherService,
    private storeHistoryService: StoreHistoryService,
    private vuCommunicationService: VuCommunicationService,
  ) {
  }

  ngOnInit() {
    this.backButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = false;
    this.dispatcherService.pageHeaderSet('Please select');

    this.getVisualItems();

    this.subscriptionConfigurationChanged = this.dispatcherService
      .onConfigurationChangedSubscribe((x: Configuration) => this.getVisualItems());
  }

  ngOnDestroy() {
    this.dispatcherService.pageHeaderSet();
    this.dispatcherService.isBackButtonVisible = this.backButtonVisible;
    this.subscriptionConfigurationChanged.unsubscribe();
  }

  getVisualItems() {
    this.vuCommunicationService.vuHttp.getVisualItems('store').then(visualItems => {
      this.categories = visualItems;
    });
  }

  onCategoryButtonClick(category: VisualItem) {
    if (!category) {
      return;
    }

    this._showSalePage(category);
  }

  onTicketInformationButtonClick() {
    this.dispatcherService.onButtonTicketClick();
  }

  private _showSalePage(category: VisualItem) {
    if (category == null) {
      return;
    }

    const storeTreeState = new StoreTreeState();
    storeTreeState.initialize(category.children);
    storeTreeState.parentItem = category;
    this.storeHistoryService.updateCurrentStoreTreeState(storeTreeState);
    this.dispatcherService.onButtonSaleShopClick();
  }
}
