import { Component, Input } from '@angular/core';
import { Money } from '../../../lib/lib';
import { IVuConnectionSimulator } from '../../../services/vu/connection/vu-connection.interfaces';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { DispatcherService } from '../../../services/dispatcher.service';
import { SaleService } from '../../../services/sale.service';

@Component({
  moduleId: module.id,
  selector: 'cash-simulator',
  templateUrl: 'cash-simulator.component.html',
  styleUrls: ['cash-simulator.component.scss']
})
export class CashSimulatorComponent {
  @Input() isPayout = false;
  @Input() isEnabled = false;
  cash: Money[] = [];
  vuSimulator: IVuConnectionSimulator;
  constructor(
    private vuCommunicationService: VuCommunicationService,
    private dispatcherService: DispatcherService,
    private saleService: SaleService,
  ) {
    this.vuSimulator = this.vuCommunicationService.vuConnectionSimulator;
    let amountToPay = saleService.paymentSession == null ? Money.empty : saleService.paymentSession.amountToPay;
    vuCommunicationService.vuHttp.getAcceptedCash(amountToPay)
      .then(x => this.cash = x.coins.concat(x.banknotes).sort(y => -y.value));
  }

  onCashClick(cash: Money) {
    this.vuSimulator.moneyChanged(this.isPayout ? cash.negate() : cash);
  }
}
