import { Injectable } from '@angular/core';

import { BaseGridWorkflowService } from '../base-grid-workflow.service';
import { DisplayItemTypeEnum } from '../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../lib/display/display-item/display-item';
import { Money, PaymentSession, PaymentMethod, Product, NonOperationalState } from '../../../../lib/lib';
import { LoggingService } from '../../../logging/logging.service';
import { SaleService } from '../../../sale.service';
import { NonOperationalService } from '../../../non-operational.service';

@Injectable()
export class CashPaymentGridWorkflowService extends BaseGridWorkflowService {

  acceptedCoins: Money[];
  acceptedBanknotes: Money[];
  price: Money;

  private log: LoggingService;
  private saleService: SaleService;
  private isBnaValidationInProgress: boolean;
  private nonOperationalService: NonOperationalService;

  init() {
    super.init();
    this.log = this.injector.get(LoggingService);
    this.saleService = this.injector.get(SaleService);
    this.saleService.eventMoneyChanged.subscribe((x: Money) => this.onMoneyChanged(x));
    this.nonOperationalService = this.injector.get(NonOperationalService);
    this.nonOperationalService.eventStateChanged.subscribe((x: NonOperationalState) => this.onEventStateChanged(x));
    this.vuCommunicationService.vuConnection.eventBnaValidationStarted.subscribe(() => this.bnaValidationStarted());
    this.vuCommunicationService.vuConnection.eventBnaValidationFinished.subscribe(() => this.bnaValidationFinished());
  }

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.CashPayment;
  }

  get minAmount(): Money {
    if (this.displayItem && this.displayItem.minAmount > 0) {
      return new Money(this.displayItem.minAmount, 'EUR');
    }
    return new Money(1, 'EUR');
  }

  get maxAmount(): Money {
    if (this.displayItem && this.displayItem.maxAmount > 0) {
      return new Money(this.displayItem.maxAmount, 'EUR');
    }
    return new Money(100, 'EUR');
  }

  get product(): Product {
    if (!this.displayItem) {
      return null;
    }
    return this.displayItem.product;
  }

  show(displayItem: DisplayItem, context: any) {
    super.show(displayItem, context);
    this._initializeCashPaymentProcess(context);
  }

  enableNextButton(enabled: boolean) {
    this.displayGridWorkflowService.changeIsDisabledState('payment_confirmation', !enabled);
  }

  enableBackButton(enabled: boolean) {
    this.displayGridWorkflowService.changeIsDisabledState('payment_cancellation', !enabled);
  }

  _initializeCashPaymentProcess(context: any) {
    this.updateNextButton();
    if (!this.saleService.remoteTransaction.isOpened) {
      this.startPaymentTransaction(context);
    }
  }

  startPaymentTransaction(context: any) {
    if (this.product && this.minAmount.value >= 0 && this.maxAmount.isPositive) {
      const rfidCardCode = this.getRfidCardCodeFormattedData(context);
      this.saleService.order.addProduct(this.product, 1, Money.empty, rfidCardCode);
      this.saleService.openPaymentSessionWithFloatingAmount(PaymentMethod.Cash, this.minAmount, this.maxAmount);
    } else {
      this.log.warning('startPaymentTransaction. Wrong input parameters. Payment session can\'t be opened.');
    }
  }

  getRfidCardCodeFormattedData(context: any): string {
    let result = '';
    if (!context) {
      return result;
    }

    result += `${context.rfidCardCode}`;

    if (context.odooContext && context.odooContext.externalContext && context.odooContext.externalContext.PersonId) {
      result += `#${context.odooContext.externalContext.PersonId}`;
    }

    return result;
  }

  get paymentSession(): PaymentSession {
    return this.saleService.paymentSession;
  }

  get doesPaidAmountFitTheLimit(): boolean {
    if (!this.paymentSession) {
      this.log.warning('doesPaidAmountFitTheLimit. Payment session is not initilazed.');
      return false;
    }

    if (!this.paymentSession.isFloatingAmount) {
      this.log.warning('doesPaidAmountFitTheLimit. Payment session is initilazed without floating amount.');
      return false;
    }

    return this._checkMinAmount() && this._checkMaxAmount();
  }

  private _checkMinAmount() {
    if (this.minAmount.isZero) {
      return true;
    }
    return this.paymentSession.paidAmountIncludingChange.value >= this.minAmount.value;
  }

  private _checkMaxAmount() {
    if (this.maxAmount.isZero) {
      return true;
    }

    return this.paymentSession.paidAmountIncludingChange.value <= this.maxAmount.value;
  }

  onMoneyChanged(money: Money) {
    this.userActivity();
    if (!this.product) {
      this.updateNextButton();
      return;
    }
    this.log.info(`CashPaymentGridWorkflowService. onMoneyChanged. '${money}'`);
    const paymentSession = this.saleService.paymentSession;
    if (paymentSession.isFloatingAmount) {
      this.saleService.order.updateOrderLineWithFloatingAmount(this.product, money);
    }
    this._updateControlStates(paymentSession);
  }

  private _updateControlStates(paymentSession: PaymentSession) {
    this.enableBackButton(!this.isBnaValidationInProgress);
    if (paymentSession.isFloatingAmount) {
      this.vuCommunicationService.vuHttp.cashDevicesPayOut(true); // simulator
      this.updateNextButton();
    } else {
      if (paymentSession.amountRemainingToPay.value <= 0) {
        this.log.debug(`CashPaymentGridWorkflowService. onMoneyChanged. Required price is paid`);
        this.vuCommunicationService.vuHttp.cashDevicesPayOut(true); // simulator
      }
    }
  }

  bnaValidationStarted() {
    this.isBnaValidationInProgress = true;
    this._updateControlStates(this.saleService.paymentSession);
  }

  onEventStateChanged(state: NonOperationalState) {
    if (this.displayItem) {
      this.updateNextButton();
    }
  }

  updateNextButton() {
    this.enableNextButton(
      !!this.product
      && this.doesPaidAmountFitTheLimit
      && !this.isBnaValidationInProgress
      && !this.nonOperationalService.state.isNonOperational
    );
  }

  bnaValidationFinished() {
    this.isBnaValidationInProgress = false;
    this._updateControlStates(this.saleService.paymentSession);
  }
}
