import { Component, ViewChild, Optional, Inject, forwardRef, Input } from '@angular/core';
import { NgModel, NG_VALIDATORS, NG_ASYNC_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ElementBase } from '../base/element-base';

@Component({
  selector: 'app-number-spinner',
  templateUrl: './number-spinner.component.html',
  styleUrls: ['./number-spinner.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberSpinnerComponent),
      multi: true
    }
  ]
})
export class NumberSpinnerComponent extends ElementBase<number>  {

  @Input()
  min: number;

  @Input()
  max: number;

  @ViewChild(NgModel, {static: false}) model: NgModel;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }

  onClickCountChangeByOne(isUp: boolean) {
    if (isUp && this.canCountUp || !isUp && this.canCountDown) {
      this.value += isUp ? 1 : -1;
    }
  }

  get canCountUp(): boolean {
    return this.value < this.max;
  }

  get canCountDown(): boolean {
    return this.value > this.min;
  }
}
