import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { VisualItem, Product, Order } from '../../../lib/lib';
import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute } from '@angular/router';
import { ThemeService } from '../../../services/theme.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { StoreTreeState } from '../../../lib/store-tree-state';
import { StoreHistoryService } from '../../../services/store-history.service';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { MessageService, Message, MessageType } from '../../../services/message.service';
import { DispatcherService } from '../../../services/dispatcher.service';
import { LanguageService } from '../../../services/language.service';
import { SaleService } from '../../../services/sale.service';


@Component({
  selector: 'app-visual-items',
  templateUrl: './visual-items.component.html',
  styleUrls: ['./visual-items.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class VisualItemsComponent implements OnInit, OnDestroy {

  items: VisualItem[][];
  private storeTreeState: StoreTreeState;
  private activatedRouteSub: any;
  private messageSubscription: Subscription;
  private internalCategoryDescription: string;
  private treeType: string;
  private rebuildOfTreeRequestedSubscription: Subscription;
  order: Order;

  private active: boolean;

  constructor(
    private vuCommunicationService: VuCommunicationService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private dispatcherService: DispatcherService,
    private themeService: ThemeService,
    protected configurationService: ConfigurationService,
    private storeHistoryService: StoreHistoryService,
    private languageService: LanguageService,
    private saleService: SaleService,
  ) {
    this.storeTreeState = new StoreTreeState();
  }

  ngOnInit(): void {
    this.active = true;
    this.setPageHeaderTitle(null);
    this.activatedRouteSub = this.activatedRoute.params.subscribe(params => {
      this.treeType = params.type;
      const lastState = this.storeHistoryService.getLastStoreTreeState();
      if (lastState === null || lastState === undefined) {
        this.buildTree(this.treeType);
      } else {
        this.restoreState();
      }
    });
    this.dispatcherService.isBackButtonEnabled = true;
    this.messageSubscription = this.messageService.getSubscription()
      .filter((x: Message) => [MessageType.ButtonBackClicked, MessageType.LanguageChanged].indexOf(x.messageType) > -1)
      .subscribe(message => this.onMessage(message));
    this.rebuildOfTreeRequestedSubscription = this.storeHistoryService.rebuildOfTreeRequested.subscribe(() => this.onConfChange());
    this.order = this.saleService.order;
  }

  onConfChange() {
    this.buildTree(this.treeType);
  }

  ngOnDestroy() {
    this.active = false;
    this.dispatcherService.pageHeaderSet();
    this.messageSubscription.unsubscribe();
    this.activatedRouteSub.unsubscribe();
    this.rebuildOfTreeRequestedSubscription.unsubscribe();
  }

  onClick(visualItem: VisualItem) {
    this.dispatcherService.onUserActivity();

    this.storeTreeState.selectedItem = visualItem;
    const children = visualItem.children;
    if (children == null) {
      if (this.configurationService.showArticlesOnMainPage) {
        this.dispatcherService.isBackButtonVisible = true;
      }
      this.messageService.sendMessage(new Message(MessageType.VisualItemLeafSelected, visualItem));
    } else {
      this.storeTreeState.nextStep(visualItem);
      this.showCurrentStep();
    }
    this.storeHistoryService.updateCurrentStoreTreeState(this.storeTreeState);
  }

  private setPageHeaderTitle(visualItem: VisualItem) {

    const titleMessage = [];
    for (const item of this.storeTreeState.rootStack) {
      if (item == null) {
        continue;
      }

      titleMessage.push(item.formattedName(this.language));
    }

    if (visualItem == null && titleMessage.length === 0) {
      this.dispatcherService.pageHeaderSet(this.getDefaultTitle());
      return;
    }

    if (visualItem != null) {
      titleMessage.push(visualItem.formattedName(this.language));
    }

    this.dispatcherService.pageHeaderLinesSet(titleMessage);
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }

  private getDefaultTitle() {
    const title = this.configurationService.defaultShopTitle;
    if (title) {
      return title;
    }

    return 'Select an item';
  }

  private setBackgroundImage(visualItem: VisualItem) {
    const categoryId = this.getCurrentCategoryId(visualItem);
    this.themeService.setBackgroundCategoryImage(categoryId);
  }

  private getCurrentCategoryId(visualItem: VisualItem): string {
    return this.themeService.all_category_id;
    // return visualItem != null ? visualItem.id.toString() : this.themeService.all_category_id;
  }

  private buildTree(type: string): void {
    this.vuCommunicationService.vuHttp.getVisualItems(type).then(visualItems => this.initialize(visualItems));
  }

  private initialize(visualItems: VisualItem[]) {
    if (!this.active) {
      return;
    }

    this.storeTreeState.initialize(visualItems);
    this.showCurrentStep();
  }

  private onMessage(message: Message) {
    if (message.messageType === MessageType.LanguageChanged) {
      this.showCurrentStep();
      return;
    }

    if (this.storeTreeState.isStackEmpty) {
      this.storeHistoryService.reset();
      this.messageService.sendMessage(new Message(MessageType.Back));
    }
    this.storeTreeState.backStep();
    this.showCurrentStep();
    if ((!this.storeTreeState.rootStack || this.storeTreeState.rootStack.length === 0)
      && this.configurationService.showArticlesOnMainPage) {
      this.messageService.sendMessage(new Message(MessageType.Back));
    }
  }

  private restoreState() {
    this.storeTreeState = this.storeHistoryService.getLastStoreTreeState();
    if (this.storeTreeState == null) {
      this.storeTreeState = new StoreTreeState();
    }
    this.showCurrentStep();
  }

  private setCategoryDescription(visualItem: VisualItem) {
    this.categoryDescription = '';

    if (visualItem != null) {
      this.categoryDescription = visualItem.formattedCategoryDescription(this.language);
    }
  }

  private showCurrentStep() {
    this.items = this.storeTreeState.items;
    const visualItem = this.storeTreeState.parentItem;
    this.setPageHeaderTitle(visualItem);
    this.setBackgroundImage(visualItem);
    this.setCategoryDescription(visualItem);
    if (this.configurationService.showArticlesOnMainPage) {
      this.dispatcherService.isBackButtonVisible = !this.storeTreeState.isStackEmpty;
    }
  }

  get categoryDescription(): string {
    return this.internalCategoryDescription;
  }

  set categoryDescription(text: string) {
    this.internalCategoryDescription = text;
  }

  rowCssClass(index: number): string {
    let cssClass = 'row';
    if (this.items && this.items.length !== index + 1) {
      cssClass += ' row-buffer-bottom';
    }
    return cssClass;
  }

  get isBasketButtonVisible(): boolean {
    return this.order != null && this.order.orderLines.length !== 0;
  }

  onBasketButtonClick($event) {
    this.dispatcherService.toBasket();
  }
}
