import { Component, OnInit, OnDestroy } from '@angular/core';
import { DispatcherService } from '../../services/dispatcher.service';
import { ScreenSaverService } from '../../services/screen-saver.service';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-screen-saver',
  templateUrl: './screen-saver.component.html',
  styleUrls: ['./screen-saver.component.css']
})
export class ScreenSaverComponent implements OnInit, OnDestroy {

  private backButtonVisible: boolean;
  private headerVisible: boolean;
  private imageIndex: number;
  private slideshowTimer: any;
  private backgroundColor: string;

  constructor(
    private dispatcherService: DispatcherService,
    private screenSaverService: ScreenSaverService,
    private themeService: ThemeService,
  ) {
  }

  ngOnInit() {
    this.backButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = false;

    this.headerVisible = this.dispatcherService.isHeaderVisible;
    this.dispatcherService.isHeaderVisible = false;

    if (this.showImages) {
      this.imageIndex = 0;
      this._showNextImage();
    }
    else {
      if (this.showMessage) {
        this.themeService.setBackgroundCategoryImage(this.themeService.all_category_id);
      } else {
        this.themeService.setBackgroundImage("none");
        this.backgroundColor = this.themeService.backgroundColor;
        this.themeService.backgroundColor = "black";
      }
    }
  }

  ngOnDestroy() {
    this.dispatcherService.isBackButtonVisible = this.backButtonVisible;
    this.dispatcherService.isHeaderVisible = this.headerVisible;
    this.themeService.backgroundColor = this.backgroundColor;

    if (this.slideshowTimer != null) {
      clearTimeout(this.slideshowTimer);
      this.slideshowTimer = null;
    }
  }

  _showNextImage() {
    if (!this.showImages)
      return;

    this.themeService.setBackgroundImageById(this.screenSaverService.configuration.imagesIds[this.imageIndex]);

    if (this.screenSaverService.configuration.imageShowTimeout === 0 || this.screenSaverService.configuration.imagesIds.length < 2)
      return;

    this.imageIndex++;
    if (this.screenSaverService.configuration.imagesIds.length <= this.imageIndex)
      this.imageIndex = 0;

    this.slideshowTimer = setTimeout(() => {
      this._showNextImage();
    }, this.screenSaverService.configuration.imageShowTimeout * 1000);
  }

  click() {
    this.screenSaverService.forceStopShow();
  }

  get showMessage(): boolean {
    return this.screenSaverService.configuration && this.screenSaverService.configuration.mode === 'text';
  }

  get showImages(): boolean {
    return this.screenSaverService.configuration
      && this.screenSaverService.configuration.mode === 'images'
      && this.screenSaverService.configuration.imagesIds
      && this.screenSaverService.configuration.imagesIds.length != 0;
  }

  get message(): string {
    return this.screenSaverService.configuration.message;
  }
}
