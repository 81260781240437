import { Component, OnInit, Input, EventEmitter, Output,  } from '@angular/core';
import { DisplayItem } from '../../../lib/display/display-item/display-item';

@Component({
  selector: 'grid-visual-item-receipt-switcher',
  templateUrl: './grid-visual-item-receipt-switcher.component.html',
  styleUrls: ['./grid-visual-item-receipt-switcher.component.css']
})
export class GridVisualItemReceiptSwitcherComponent implements OnInit {
  @Input()
  displayItem: DisplayItem;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) {

  }

  ngOnInit() {
  }

}
