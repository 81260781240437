import { Injectable, EventEmitter, Injector } from '@angular/core';
import { VuCommunicationService } from '../vu/vu-communication.service';
import { IVuConnection } from '../vu/connection/vu-connection.interfaces';
import { Subscription } from 'rxjs';

@Injectable()
export class BarcodeReaderService {
  private vuCommunicationService: VuCommunicationService;
  private vuConnection: IVuConnection;

  barcodeScanned: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected injector: Injector,
  ) {
    this.vuCommunicationService = injector.get(VuCommunicationService);
    this.vuConnection = this.vuCommunicationService.vuConnection;
    this.vuConnection.eventBarcodeRead.subscribe((barcode: string) => this.onBarcodeRead(barcode));
  }

  onBarcodeRead(barcode: string) {
    this.barcodeScanned.emit(barcode);
  }
}
