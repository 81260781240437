import { Injectable } from '@angular/core';

import { DisplayItemTypeEnum } from '../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../lib/display/display-item/display-item';
import { BaseGridWorkflowService } from '../base-grid-workflow.service';
import { GlobalSettings } from '../../../../lib/lib';

@Injectable()
export class StaticGridWorkflowService extends BaseGridWorkflowService {

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.Static;
  }

  fillDisplayItemRenderData(displayItem: DisplayItem, data: Map<string, any>, context: any) {
    if (displayItem == null || displayItem.type !== this.displayItemType || context == null ||
      context.odooContext == null || context.odooContext.externalContext == null) {
      return;
    }

    const externalContextData = context.odooContext.externalContext;
    for (const key in externalContextData) {
      if (!externalContextData.hasOwnProperty(key)) {
        continue;
      }
      const placeholderName = '@' + key;
      if (displayItem.htmlTemplate.includes(placeholderName)) {
        let placeholderValue = externalContextData[key];
        if (key.indexOf('Money') === 0) {
          placeholderValue = placeholderValue.toLocaleString(GlobalSettings.getCurrentLocale(), { minimumFractionDigits: 2 });
        }
        data.set(placeholderName, placeholderValue || '');
      }
    }
  }
}
