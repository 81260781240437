import { Injectable, EventEmitter } from "@angular/core";
import { StoreTreeState } from "../lib/store-tree-state";
import { Configuration } from "../lib/configuration";
import { SaleService } from "./sale.service";
import { GridTreeState } from "../lib/display/grid/grid-tree-state";

@Injectable()
export class StoreHistoryService {
  private storeTreeState: StoreTreeState;
  private gridTreeState: GridTreeState;
  public rebuildOfTreeRequested: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private saleService: SaleService,
  ) {
  }

  updateCurrentGridTreeState(newGridTreeState: GridTreeState) {
    this.gridTreeState = newGridTreeState;
  }

  updateCurrentStoreTreeState(newStoreTreeState: StoreTreeState) {
    this.storeTreeState = newStoreTreeState;
  }

  getLastStoreTreeState(): StoreTreeState {
    return this.storeTreeState;
  }

  getLastGridTreeState(): GridTreeState {
    return this.gridTreeState;
  }

  reset() {
    this.storeTreeState = null;
    this.gridTreeState = null;
  }

  get isEmpty(): boolean {
    return this.storeTreeState ? this.storeTreeState.isStackEmpty : true &&
      this.gridTreeState ? this.gridTreeState.isStackEmpty : true
  }

  checkAndRequestVisualItemsUpdate(configuration: Configuration) {
    if(configuration && configuration.showArticlesOnMainPage && this.isEmpty && this.saleService && this.saleService.orderInProgress) {
      this.rebuildOfTreeRequested.emit();
    }
  }
}
