import { Injectable, Injector } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { LoggingService } from './logging/logging.service';
import { ConfigurationService } from './configuration/configuration.service';
import { AssetsService } from './assets.service';

@Injectable()
export class ThemeService {
  _log: LoggingService;

  readonly _all_category_id = '1';
  private configurationService: ConfigurationService;
  private _showDemoBackground: boolean;
  private assetsService: AssetsService;

  constructor(
    private injector: Injector,
    private router: Router,
  ) {
    this.configurationService = this.injector.get(ConfigurationService);
    this.assetsService = this.injector.get(AssetsService);
    this._showDemoBackground = this.configurationService.configuration.showDemoBackground;

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        switch (val.urlAfterRedirects) {
          case '/visual-items/store':
          case '/screen-saver':
            break;
          case '/lite-mode':
            this.setBackgroundImage(null);
            this.backgroundColor = 'black';
            break;
          case '/root':
          default:
            if (this.configurationService.configuration.backgroundId) {
              this.setBackgroundImageById(this.configurationService.configuration.backgroundId + '');
            } else {
              this.setBackgroundCategoryImage(this.all_category_id);
            }
            break;
        }
      }
    });
  }

  get showDemoBackground(): boolean {
    return this._showDemoBackground;
  }

  set showDemoBackground(value: boolean) {
    this._showDemoBackground = value;
    this.setBackgroundCategoryImage(this.all_category_id);
  }

  get all_category_id(): string {
    if (this.showDemoBackground) {
      return 'demo1';
    }
    return this._all_category_id;
  }

  setBackgroundCategoryImage(categoryId) {
    this.log.info(`setBackgroundCategoryImage: ${categoryId}`);
    this.setBackgroundImage(this.assetsService.getCategoryImageUrl(categoryId));
  }

  setBackgroundImageById(imageId) {
    this.log.info(`setBackgroundImageById: ${imageId}`);
    this.setBackgroundImage(this.assetsService.getImageUrlById(imageId));
  }

  setBackgroundImage(backgroundUrl: string) {
    if (backgroundUrl == null || backgroundUrl === 'none') {
      document.body.style.backgroundImage = 'none';
    } else {
      document.body.style.backgroundImage = 'url(\'' + backgroundUrl + '\')';
    }
  }

  set backgroundColor(color: string) {
    document.body.style.backgroundColor = color;
  }

  get backgroundColor() {
    return document.body.style.backgroundColor;
  }

  get log() {
    if (this._log == null) {
      this._log = this.injector.get(LoggingService);
    }
    return this._log;
  }
}
