import { Injectable } from '@angular/core';
import { LoggingService } from './logging/logging.service';
import { DispatcherService } from './dispatcher.service';

const totalCount = 10000;
const sleep = 1500;

@Injectable()
export class TestingService {
  private count = 0;
  private isInOperation = false;
  private isToStop = false;

  constructor(
    private log: LoggingService,
    private dispatcherService: DispatcherService,
  ) {
  }

  testStart() {
    this.test();
  }

  testStop() {
    if (this.isInOperation) {
      this.isToStop = true;
    }
  }

  resetTest() {
    this.count = 0;
    this.isInOperation = false;
    this.isToStop = false;
    this.log.info('Test stopped');
    this.dispatcherService.simulatorIsTestRunningSet(false);
  }

  test(isInternal = false) {
    let scope = this;
    if (this.isInOperation && !isInternal) {
      scope.log.error('test in operation');
      return;
    }

    if (this.isToStop) {
      this.resetTest();
      return;
    }
    this.isInOperation = true;
    this.dispatcherService.simulatorIsTestRunningSet(true);
    if (scope.count++ < totalCount) {
      scope.testSimple();
      scope.log.debug(`--------->Test ${scope.count} of ${totalCount}`);
    } else {
      scope.count = 0;
      this.isInOperation = false;
    }
  }

  testSimple() {
    let scope = this;
    scope.async(0, () => scope.dispatcherService.onButtonSaleShopClick())
      .then((x) => scope.async(sleep, () => scope.dispatcherService.vuStateMaintenanceMode(true)))
      .then((x) => scope.async(sleep, () => scope.dispatcherService.vuStateMaintenanceMode(false)))
      .then((x) => scope.async(sleep, () => scope.dispatcherService.onButtonTicketClick()))
      .then((x) => scope.async(sleep, () => scope.dispatcherService.vuStateOfflineMode(true)))
      .then((x) => scope.async(sleep, () => scope.dispatcherService.vuStateOfflineMode(false)))
      .then((x) => scope.async(sleep, () => scope.dispatcherService.onButtonInfoClick()))
      .then((x) => scope.async(sleep, () => scope.dispatcherService.vuStateMaintenanceMode(true)))
      .then((x) => scope.async(sleep, () => scope.dispatcherService.vuStateMaintenanceMode(false)))
      .then((x) => scope.test(true))
      .catch(() => scope.test(true));
  }

  async(wait = 0, func: any) {
    let scope = this;
    return new Promise((resolve, reject) => {
      if (scope.isToStop) {
        reject();
      } else {
        setTimeout(() => {
          func();
          resolve(false);
        }, wait);
      }
    });
  };
}
