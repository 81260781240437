import { Component, OnInit } from '@angular/core';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';

@Component({
  selector: 'app-custom-css-simulator',
  templateUrl: './custom-css-simulator.component.html',
})
export class CustomCssSimulatorComponent implements OnInit {

  styleCss: string;

  constructor(
    private vuCommunicationService: VuCommunicationService,
  ) {
  }

  ngOnInit() {
    this.styleCss = '';
  }

  onClick() {
    this._updateVUConfiguration();
  }

  _updateVUConfiguration() {
    this.vuCommunicationService.vuHttp.getVuConfiguration().then(
      result => {
        result.customCss = this.styleCss;
        this.vuCommunicationService.vuConnectionSimulator.vuConfigurationChanged(result);
      }
    )
  }

}
