import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DisplayItem } from '../../../lib/display/display-item/display-item';
import { DisplayGridWorkflowService } from '../../../services/display/grid/display-grid-workflow.service';
import { LanguageService } from '../../../services/language.service';
import { CashRefundGridWorkflowService } from '../../../services/display/grid/cash-refund/cash-refund-grid-workflow.service';
import { Product, Money, GlobalSettings } from '../../../lib/lib';

@Component({
  selector: 'app-grid-visual-item-cash-refund',
  templateUrl: './grid-visual-item-cash-refund.component.html',
  styleUrls: ['./grid-visual-item-cash-refund.component.css']
})
export class GridVisualItemCashRefundComponent implements OnInit {
  @Input()
  displayItem: DisplayItem;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private cashRefundGridWorkflowService: CashRefundGridWorkflowService,
    private languageService: LanguageService,
    private displayGridWorkflowService: DisplayGridWorkflowService,
  ) {
  }

  ngOnInit() {
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }

  get minAmount(): Money {
    return this.displayItem && this.displayItem.minAmount ? new Money(this.displayItem.minAmount, GlobalSettings.getCurrencyCode()) : null;
  }

  get maxAmount(): Money {
    return this.displayItem && this.displayItem.maxAmount ? new Money(this.displayItem.maxAmount, GlobalSettings.getCurrencyCode()) : null;
  }

  get product(): Product {
    return this.displayItem ? this.displayItem.product : null;
  }

  onItemClick() {
    if (this.displayItem != null && this.displayItem != undefined)
      this.onClick.emit(this.displayItem);
  }

  setName(item: DisplayItem) {
    return item.name + ` ${item.sizeHorizontal - item.posX}x` + `${item.sizeVertical - item.posY}`;
  }

  get isVirtualKeyboardVisible(): boolean {
    return true;
  }

  onEnteredText(text: string) {
    this._onTextChanged(text);
  }

  onTextChanged(text: string) {
    this._onTextChanged(text);
  }

  _onTextChanged(text: string) {
    const amount = parseFloat(text);
    if (this.validateAmount(amount)) {
      this.cashRefundGridWorkflowService.cashChanged(amount, this.product);
    }
    else {
      this.cashRefundGridWorkflowService.cashChanged(0, null);
    }
  }

  validateAmount(amount: number) {
    if (this.minAmount != undefined && amount < this.minAmount.value) {
      return false;
    }
    if (this.maxAmount != undefined && amount > this.maxAmount.value) {
      return false;
    }
    return true;
  }

  renderHtmlTemplate(displayItem: DisplayItem): string {
    const redenderData = this.displayGridWorkflowService.getDisplayItemRenderData(displayItem);
    return displayItem.getformattedHtmlTemplate(this.language, redenderData);
  }
}
