import { Utilites } from './lib';

export class VuState {
  isOfflineMode = false;
  isServiceMode = false;
  isBurglaryMode = false;
  isMaintenanceMode = false;
  maintenaceModeReasons: string[] = [];
  minHardwareConfigurationViolation = '';

  get isOutOfOrder() {
    return this.isOfflineMode
      || this.isServiceMode
      || this.isBurglaryMode
      || this.isMaintenanceMode;
  }

  static fromOther(other: any): VuState {
    return Object.assign(new VuState(), other);
  }

  public toString(): string {
    return `isOutOfOrder: '${this.isOutOfOrder}':
        isMaintenanceMode: '${this.isMaintenanceMode}'.
        maintenaceModeReasons: '${this.maintenaceModeReasons}'.
        minHardwareConfigurationViolation: '${this.minHardwareConfigurationViolation}'
        isOfflineMode: '${this.isOfflineMode}'.
        isBurglaryMode: '${this.isBurglaryMode}'.
        isServiceMode: '${this.isServiceMode}'.`;
  }

  public equals(other: VuState): boolean {

    if (!Utilites.areArraysEqual(this.maintenaceModeReasons, other.maintenaceModeReasons)) {
      return false;
    }

    return this.isMaintenanceMode === other.isMaintenanceMode
      && this.isOfflineMode === other.isOfflineMode
      && this.isServiceMode === other.isServiceMode
      && this.isBurglaryMode === other.isBurglaryMode
      && this.minHardwareConfigurationViolation === other.minHardwareConfigurationViolation;
  }
}

export enum VuRole {
  Entry = 'Entry',
  Exit = 'Exit',
}

