import { Injectable, EventEmitter } from '@angular/core';
import { StepConfiguration } from '../../lib/lite/step-configuration';
import { StepConfigurationType } from '../../lib/lite/step-configuration-type';
import { Money } from '../../lib/lib';

@Injectable()
export class LiteDisplayStepsService {

  private _steps: Array<StepConfiguration> = new Array<StepConfiguration>();
  private _selectedStepIndex: number;

  constructor(
  ) {
    this._defaultValues();
  }

  changed: EventEmitter<any> = new EventEmitter<any>();

  private _defaultValues() {
    this._steps.push(new StepConfiguration(StepConfigurationType.Coins));
    this._steps.push(new StepConfiguration(StepConfigurationType.Amount, '2,50 €'));
    this._steps.push(new StepConfiguration(StepConfigurationType.Card));
    this._steps.push(new StepConfiguration(StepConfigurationType.RFID));
    this._steps.push(new StepConfiguration(StepConfigurationType.Change));
    this._steps.push(new StepConfiguration(StepConfigurationType.Print));
    this._steps.push(new StepConfiguration(StepConfigurationType.EntryLeft));
    this._steps.push(new StepConfiguration(StepConfigurationType.EntryRight));
  }

  activeStep(type: StepConfigurationType, active: boolean) {
    const step = this.findStep(type);
    if (!step)
      return;

    step.active = active;
    this.changed.emit();
  }

  findStep(type: StepConfigurationType): StepConfiguration {
    return this._steps.find(x => x.type === type);
  }

  setAmount(money: Money) {
    const step = this.findStep(StepConfigurationType.Amount);
    if (!step)
      return;

    step.text = money.toStringCurrencySign();
    this.changed.emit();
  }

  showStep(type: StepConfigurationType, show: boolean) {
    const step = this.findStep(type);
    if (!step)
      return;

    step.visible = show;
    this.changed.emit();
  }

  setStepsStates(
    selectStep: StepConfigurationType,
    activeSteps: StepConfigurationType[],
    enabledSteps: StepConfigurationType[],
    successSteps: StepConfigurationType[] = null,
    errorSteps: StepConfigurationType[] = null,
  ) {

    this._selectedStepIndex = this.visibleSteps.findIndex(x => x.type === selectStep);

    for (let i = 0; i < this._steps.length; i++) {
      const currentType = this._steps[i].type;
      this._steps[i].active = activeSteps && activeSteps.includes(currentType);
      this._steps[i].disabled = !(enabledSteps && enabledSteps.includes(currentType));
      this._steps[i].success = successSteps && successSteps.includes(currentType);
      this._steps[i].error = errorSteps && errorSteps.includes(currentType);
    }
    this.changed.emit();
  }

  selectAndActivate(type: StepConfigurationType) {
    this.setStepsStates(
      type,
      [type],
      [type],
      null
    )
  }

  selectAndSuccess(type: StepConfigurationType) {
    this.setStepsStates(
      type,
      [type],
      null,
      [type]
    )
  }

  selectAndError(type: StepConfigurationType) {
    this.setStepsStates(
      type,
      [type],
      null,
      null,
      [type]
    )
  }


  selectStep(type: StepConfigurationType) {
    this._selectedStepIndex = this.visibleSteps.findIndex(x => x.type === type);
    this.changed.emit();
  }

  get steps(): StepConfiguration[] {
    return this._steps;
  }

  get visibleSteps(): StepConfiguration[] {
    return this._steps.filter(x => x.visible);
  }

  get selectedStepIndex(): number {
    return this._selectedStepIndex;
  }

  get currentStepType(): StepConfigurationType {
    if (
      !this._steps
      || this._selectedStepIndex < 0
      || this._steps.length <= this._selectedStepIndex
    )
      return StepConfigurationType.Amount;

    const step = this._steps[this._selectedStepIndex];
    if (!step)
      return StepConfigurationType.Amount;

    return step.type;
  }

  get isPayByCoins(): boolean {
    const step = this.findStep(StepConfigurationType.Coins);
    return step ? step.active : false;
  }

  get isPayByCard(): boolean {
    const step = this.findStep(StepConfigurationType.Card);
    return step ? step.active : false;
  }

  get isPaymentActive(): boolean {
    return this.isPayByCoins || this.isPayByCard;
  }
}
