import { DisplayItem } from '../display-item/display-item';
import { Product } from '../../product';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';


export class DisplayItemTree {
  private _nodesItems: DisplayItem[] = [];
  private _allitems: DisplayItem[] = [];

  get nodes(): DisplayItem[] {
    return this._nodesItems;
  }

  get items(): DisplayItem[] {
    return this._allitems;
  }

  rebuildTree(itemsData: any, itemsTreeData: any, additionalItemsTree: any, existingDisplayItems: DisplayItem[] = null): void {
    this._allitems = DisplayItem.fromArrayJson(itemsData);
    let nodes = {};
    this._allitems.forEach(element => {
      nodes[element.id] = element;
    });
    this._nodesItems = this._fillLinks(itemsTreeData, additionalItemsTree, nodes, existingDisplayItems);
  }

  private _fillLinks(itemsTree: any, additionalItemsTree: any, nodes: any, existingDisplayItems: DisplayItem[]): DisplayItem[] {
    if (itemsTree == null || itemsTree.length == 0)
      return null;

    const result = new Array<DisplayItem>();

    itemsTree.forEach(element => {
      let item_id = element.item_id;
      if (!item_id)
        return;

      const node = nodes[item_id] as DisplayItem;
      if (!node)
        if (existingDisplayItems) {
          let existingDisplayItem = null;
          existingDisplayItems.forEach(displayItems => {
            if (displayItems && displayItems.id == item_id)
              existingDisplayItem = displayItems;
          });
          if (existingDisplayItem) {
            result.push(existingDisplayItem);
            return;
          }
        } else {
          return;
        }

      if (element.items && !node.children)
        node.children = this._fillLinks(element.items, additionalItemsTree, nodes, existingDisplayItems);

      if (node.navigationId) {
        node.navigationItem = nodes[node.navigationId] as DisplayItem;
        if (additionalItemsTree && node.navigationItem && node.navigationItem.navigationId && !node.navigationItem.navigationItem) {
          const subTree = additionalItemsTree.find(item => item.item_id == node.navigationItem.id);
          if (subTree) {
            this._fillLinks([subTree], additionalItemsTree, nodes, existingDisplayItems);
          }
        }

        if (!node.navigationItem) {
          existingDisplayItems.forEach(displayItem => {
            if (displayItem && displayItem.id == node.navigationId)
              node.navigationItem = displayItem;
          });
        }
      }

      result.push(node);
    });

    return result;
  }

  private get _productsIds(): number[] {
    const productIds = new Set<number>();
    this._allitems.forEach(element => {
      if (element.productId)
        productIds.add(element.productId);
    });

    return Array.from(productIds.values());
  }

  private _fillProducts(products: Product[]) {
    const productDict = {};
    if (products)
      products.forEach(element => {
        if (element && element.id)
          productDict[element.id] = element;
      });

    this._allitems.forEach(element => {
      element.product = element.productId ? productDict[element.productId] : null;
    });
  }

  get itemsIds(): number[] {
    return this._allitems.map(item => item.id);
  }

  fillProducts(vuCommunicationService: VuCommunicationService): Promise<any> {
    return new Promise<DisplayItemTree>(
      (resolve, reject) => {
        if (!vuCommunicationService)
          reject();

        let productsIds = this._productsIds;
        if (!productsIds || productsIds.length == 0)
          resolve();

        vuCommunicationService.vuHttp.getProductsByIds(productsIds).
          then(
            products => {
              this._fillProducts(products);
              resolve();
            }
          );
      });
  }

  static build(itemsData: any, itemsTreeData: any, additionalItemsTree: any, existingDisplayItems: DisplayItem[] = null): DisplayItemTree {
    const displayItemBuilder = new DisplayItemTree();
    displayItemBuilder.rebuildTree(itemsData, itemsTreeData, additionalItemsTree, existingDisplayItems);
    return displayItemBuilder;
  }
}
