import { Component, OnInit } from '@angular/core';
import { TicketWaitForComponent } from '../ticket-wait-for/ticket-wait-for.component';

@Component({
  selector: 'app-ticket-activation-wait-for',
  templateUrl: './ticket-activation-wait-for.component.html',
  styleUrls: ['./ticket-activation-wait-for.component.css']
})
export class TicketActivationWaitForComponent extends TicketWaitForComponent {

  get barcodeInputMethodDescription(): string {
    return 'Scan you multi ticket!';
  }

}
