import { Injectable } from '@angular/core';
import { InMemoryDataService } from '../in-memory-data.service';
import { LogisticsRequestLine } from '../../lib/logistics/logistics-request-line';
import { LogisticsRequest } from '../../lib/logistics/logistics-request';
import { Ticket } from '../../lib/lib';
import { DisplayItem } from '../../lib/display/display-item/display-item';
import { LogisticsRequestStateEnum } from '../../lib/logistics/logistics-request-state-enum';


@Injectable()
export class LogisticsRequestSimulatorService {
  memoryTickets: Ticket[] = [];
  memoryLogisticsRequests: LogisticsRequest[] = [];
  memoryLogisticsRequestsLines: LogisticsRequestLine[] = [];
  notFoundDisplayItem: DisplayItem;
  usedDisplayItem: DisplayItem;

  constructor(
    private inMemoryDataService: InMemoryDataService,
  ) { }

  generateLogisticsRequestInMemory() {
    if (this.memoryTickets.length !== 0) {
      return;
    }
    const logisticsRequests: LogisticsRequest[] = this.inMemoryDataService.createLogisticsRequest();
    let ticket: Ticket;
    if (logisticsRequests.length === 0) {
      return;
    }
    logisticsRequests.forEach((value) => {
      const logisticsRequest = value;
      logisticsRequest.ticketBarCodes = [];

      logisticsRequest.logisticsRequestLine.forEach((values) => {
        const logisticsRequestLine = values;

        ticket = this.inMemoryDataService.generateTicket(values.ticketBarCode);
        ticket.logisticsRequestId = logisticsRequest.id;
        ticket.logisticsRequestLineId = logisticsRequestLine.id;
        ticket.quantity = logisticsRequestLine.quantity;

        logisticsRequestLine.productId = ticket.productId;
        logisticsRequest.ticketBarCodes.push(ticket.code);

        this.memoryLogisticsRequestsLines.push(logisticsRequestLine);
        this.memoryTickets.push(ticket);
      })

      this.memoryLogisticsRequests.push(logisticsRequest);
    });
    this.usedDisplayItem = this.inMemoryDataService.logisticsRequestUsed();
    this.notFoundDisplayItem = this.inMemoryDataService.logisticsRequestNotFound();
  }

  private _getDisplayItemById(id: number): DisplayItem {
    for (const element of this.memoryLogisticsRequestsLines) {
      if (element.id === id && element.displayItem) {
        return element.displayItem;
      }
    }
    return this.notFoundDisplayItem;
  }

  getDisplayItem(barcode: string): DisplayItem {
    let state: string;
    let displayItem: any;
    const ticket = this._getLogisticsRequestTicketInfo(barcode);

    if (!ticket || !ticket.logisticsRequestId) {
      return this.notFoundDisplayItem;
    }

    state = this._getLogisticsRequestStateValidById(ticket.logisticsRequestId);

    if (!state || state === LogisticsRequestStateEnum.New) {
      return this.notFoundDisplayItem;
    }

    if (ticket.isTicketUsed || state === LogisticsRequestStateEnum.Closed) {
      return this.usedDisplayItem;
    }
    displayItem = this._getDisplayItemById(ticket.logisticsRequestLineId);

    return displayItem;
  }

  setState(id: number, state: string) {
    this.memoryLogisticsRequests.forEach((values) => {
      if (values.id === id) {
        values.state = LogisticsRequestStateEnum[state];
      }
    });
  }

  private _getLogisticsRequestTicketInfo(barcode: string): any {
    for (const element of this.memoryTickets) {
      if (element.code === barcode) {
        const ticketInfo = {
          logisticsRequestId: element.logisticsRequestId,
          logisticsRequestLineId: element.logisticsRequestLineId,
          isTicketUsed: (element.quantity <= element.quantityUsedTotal)
        }
        return ticketInfo;
      }
    }
    return null;
  }

  private _getLogisticsRequestStateValidById(id: number): LogisticsRequestStateEnum {
    for (const element of this.memoryLogisticsRequests) {
      if (element.id === id && element.state) {
        return element.state;
      }
    }
    return LogisticsRequestStateEnum.New;
  }

  updateLogisticsRequestTicket(barcode: string) {
    for (const element of this.memoryTickets) {
      if (element.code === barcode) {
        element.quantityUsedTotal++;
        break;
      }
    }
  }
}
