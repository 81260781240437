import { Product, Money } from "../lib";
import { TranslatePropertyHelper } from "../languages/translation-properties-helper";
import { Dictionary } from "lodash";
export class BaseVisualItem {

  get name(): string {
    return '';
  }

  get translations(): any {
    return null;
  }

  get product(): Product {
    return null;
  }

  get price() {
    const product = this.product;
    if (this.product != null && Product.isProduct(product)) {
      return product.price.toStringCurrencySign();
    }

    return '';
  }

  get isProduct() {
    const product = this.product;
    if (this.product != null && Product.isProduct(product)) {
      return true;
    }

    return false;
  }

  formattedName(language: string) {
    const product = this.product;
    const name = this.translateName(language);

    if (Product.isProduct(product)) {
      let formattedName = product.translateTicketDisplayName(language, name);
      return this._fillProductPlaceholders(this._fixLineBreak(formattedName), product);
    }
    
    return this._fixLineBreak(name);
  }

  _fillProductPlaceholders(text: string, product: Product): string {
    if (text == null || text == undefined || product == null)
      return '';

    text = text.replace('@product_name', product.name || '');
    return text.replace('@price', product.price ? product.price.toStringCurrencySign() : '');
  }

  _fillPlaceholders(text: string, placeholdersData: Map<string, any> = new Map<string, any>()): string {
    if (text == null || text == undefined || placeholdersData == null)
      return '';
      placeholdersData.forEach((value,key) => {
        text = text.replace(key, value || '');
      })
    return text;
  }

  translateName(language: string): string {
    const translatePropertyHelper = new TranslatePropertyHelper(this.translations);
    return translatePropertyHelper.getTranslationByFieldName(language, "name", this.name);
  }

  _fixLineBreak(text: string): string {
    if (text == null || text == undefined)
      return '';

    return text.replace(' / ', '<br/>');
  }
}
